import React, { useMemo } from 'react'

import palette from '@hypotenuse/common/src/atoms/Colors'
import { Button, ButtonProps, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles({
  button: {
    fontWeight: 500,
    padding: '8px',
    '&:focus': {
      backgroundColor: palette.primary[25],
      color: palette.primary[700],
      outline: `2px solid ${palette.primary[200]}`
    }
  },
  activeButton: {
    backgroundColor: palette.primary[25],
    color: palette.primary[700],
    outline: `2px solid ${palette.primary[200]}`
  }
})

export interface ToggleButtonProps extends ButtonProps {
  isActive: boolean
  onClick: () => void
}

/**
 * A toggle button that glows in palette.primary[25] when active
 * Pass an isActive to set the button to active state and an onClick function to handle clicks
 * Is a button that was first meant to be used in the onboardingModal,  which can be reused elsewhere
 */
export const ToggleButton: React.FC<ToggleButtonProps> = React.forwardRef(
  (props, ref) => {
    const classes = useStyles()
    const memoizedButton = useMemo(
      () => {
        const { isActive, onClick, children, ...rest } = props
        return (
          <Button
            variant="outlined"
            className={clsx(classes.button, {
              [classes.activeButton]: isActive
            })}
            onClick={onClick}
            {...rest}
            ref={ref}
          >
            {children}
          </Button>
        )
      },
      [classes.activeButton, classes.button, props, ref] // Memoize the button component when props or ref change
    )

    return memoizedButton
  }
)
