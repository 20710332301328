import React from 'react'

import GeneralError from './GeneralError'

function UnexpectedError() {
  return (
    <GeneralError
      textHeader="We're fixing it"
      textBody="This page is experiencing hiccups. Our engineers know about this problem and we're working to get this back to normal quickly."
      textCta="Refresh"
      handleCta={() => {
        // Hard redirect to trigger a full page reload, just to be safe
        window.location.reload()
      }}
    />
  )
}

export default UnexpectedError
