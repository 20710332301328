import React, { useCallback, useEffect, useState } from 'react'

import palette from '../../atoms/Colors'
import Stack from '../atoms/Stack'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import {
  OutlinedTextFieldProps,
  TextField,
  Theme,
  createStyles,
  makeStyles,
  useTheme
} from '@material-ui/core'

import {
  INVALID_LINK_ERR_MSG,
  INVALID_LINK_TYPE_ERR_MSG,
  isMediaLink,
  validateLinkIsValid
} from '../../utils/Functions'

import { LabelWithToolTip } from '../LabelWithToolTip'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      backgroundColor: theme.palette.background.paper,
      '& fieldset': {
        borderColor: palette.gray['300'],
        borderRadius: theme.spacing(1)
      }
    }
  })
)

interface GenericLinkFieldProps
  extends Omit<OutlinedTextFieldProps, 'variant'> {
  /**
   * Link value
   */
  link: string
  /**
   * Sets the link value
   */
  setLink: (link: string) => void
  /**
   * Whether this field is required
   */
  required?: boolean
  /**
   * Label text to display
   */
  labelText: string
  /**
   * Tooltip text to display
   */
  tooltipText?: string
  /**
   * Helper text to display
   */
  helperText?: string
  /**
   * Caption text to display
   */
  captionText?: string
  /**
   * Callback for key presses
   */
  onKeyPress?: React.KeyboardEventHandler<HTMLDivElement> | undefined
  /**
   * Extra components to display at the end of the field
   */
  endAdornment?: React.ReactNode
  /**
   * Placeholder text to display
   */
  placeholder?: string
  /**
   * Whether to show the error message
   */
  showError?: boolean
}

export const GenericLinkField = React.memo((props: GenericLinkFieldProps) => {
  const {
    link,
    setLink,
    labelText,
    required = false,
    tooltipText = '',
    helperText = '',
    captionText = '',
    onKeyPress,
    endAdornment,
    placeholder,
    showError = true,
    ...rest
  } = props
  const theme = useTheme()

  const validateLinkWithErrorMessage = (text: string) => {
    return validateLinkIsValid(text)
      ? isMediaLink(text)
        ? INVALID_LINK_TYPE_ERR_MSG
        : ''
      : INVALID_LINK_ERR_MSG
  }

  const [error, setError] = useState<string>(
    link === '' ? '' : validateLinkWithErrorMessage(link.trim())
  )
  const classes = useStyles()

  const onChange = useCallback((e) => setLink(e.target.value), [setLink])

  const validateLinkAndSetError = useCallback(() => {
    // Remove error if link field is blank
    return link === ''
      ? setError('')
      : setError(validateLinkWithErrorMessage(link.trim()))
  }, [link])

  // Ensures that when the "reset all changes" button is clicked,
  // the error message is cleared
  useEffect(() => {
    if (link === '' && error !== '') {
      setError('')
    }
  }, [error, link])

  return (
    <Stack spacing={1}>
      {labelText && (
        <Stack direction="row">
          <LabelWithToolTip
            required={required}
            labelText={labelText}
            tooltipBody={
              tooltipText && (
                <Typography variant="body2">{tooltipText}</Typography>
              )
            }
            popupProps={{
              position: 'bottom left'
            }}
          />
        </Stack>
      )}
      <TextField
        variant="outlined"
        value={link}
        fullWidth
        error={showError && error.length > 0}
        helperText={
          // Showing error message takes precedence over showing helperText (if any)
          showError ? error || helperText : helperText
        }
        onChange={onChange}
        inputProps={{
          style: { padding: theme.spacing(1.5, 1.5) }
        }}
        placeholder={placeholder ? placeholder : 'https://...'}
        id="link_input_field"
        data-testid="link_input_field"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            validateLinkAndSetError()
          }
          onKeyPress?.(e)
        }}
        onBlur={validateLinkAndSetError}
        InputProps={{
          classes: { root: classes.inputRoot },
          endAdornment: endAdornment
        }}
        {...rest}
      />
      <Typography variant="captionReg" style={{ color: palette.gray[500] }}>
        {captionText}
      </Typography>
    </Stack>
  )
})
