import React, { CSSProperties, SVGProps, useMemo } from 'react'

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: ({
    stroke,
    strokeWidth,
    height,
    width
  }: {
    stroke: CSSProperties['stroke']
    strokeWidth: CSSProperties['strokeWidth']
    height: CSSProperties['height']
    width: CSSProperties['width']
  }) => ({
    maxHeight: '100%',
    maxWidth: '100%',
    '& svg': {
      stroke: stroke,
      strokeWidth: strokeWidth,
      height: height,
      width: width,
      '& *': {
        stroke: 'inherit',
        strokeWidth: 'inherit'
      }
    }
  })
}))

export enum IconSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  inherit = 'inherit'
}
type Size = keyof typeof IconSize

/**
 * Contains the styling specific to each specific icon size
 */
export const SIZING_MAP: Record<
  Size,
  {
    strokeWidth: CSSProperties['strokeWidth']
    height: CSSProperties['height']
    width: CSSProperties['width']
  }
> = {
  small: {
    strokeWidth: 1.33,
    height: '16px',
    width: '16px'
  },
  medium: {
    strokeWidth: 1.67,
    height: '20px',
    width: '20px'
  },
  large: {
    strokeWidth: 2,
    height: '24px',
    width: '24px'
  },
  inherit: {
    strokeWidth: 'inherit',
    height: '100%',
    width: '100%'
  }
}

export interface Props {
  /**
   * The icon to be rendered. Should be a React component.
   */
  icon: React.ComponentType
  /**
   * The size of the icon.
   */
  size?: Size
  /**
   * The stroke color of the icon.
   */
  stroke?: CSSProperties['stroke']
  /**
   * The stroke width of the icon.
   */
  strokeWidth?: CSSProperties['strokeWidth']
  /**
   * The height of the icon.
   */
  height?: CSSProperties['height']
  /**
   * The width of the icon.
   */
  width?: CSSProperties['width']
  /**
   * Additional props to be passed directly to the SVG element of the icon.
   */
  iconProps?: SVGProps<SVGSVGElement>
}

/**
 * This is an Icon component that takes in SVG files from assets/common/ imported as ReactComponent.
 *
 * Basic usage example:
 *
 * ```tsx
 * import { ReactComponent as ActivityIcon } from '@hypotenuse/common/src/assets/icons/activity.svg';
 *
 * <Icon icon={ActivityIcon} />
 * <Icon icon={ActivityIcon} size="small" /> // small size icon
 * <Icon icon={ActivityIcon} stroke="red" strokeWidth={2} /> // custom stroke colour and width
 * <Icon icon={ActivityIcon} height="30px" width="30px" /> // custom height and width
 * <Icon icon={ActivityIcon} iconProps={{ 'aria-label': 'Activity Icon' }} /> // passing in additional SVG props
 * ```
 */
const Icon: React.FC<Props> = React.forwardRef<HTMLElement, Props>(
  (props: Props, ref) => {
    const {
      icon: IconComponent,
      size = IconSize.inherit,
      stroke = 'currentColor',
      strokeWidth: strokeWidthProp,
      height: heightProp,
      width: widthProp,
      iconProps
    } = props

    const { strokeWidth, height, width } = useMemo(() => {
      const {
        strokeWidth: defaultStrokeWidth,
        height: defaultHeight,
        width: defaultWidth
      } = SIZING_MAP[size]
      return {
        strokeWidth: strokeWidthProp ?? defaultStrokeWidth,
        height: heightProp ?? defaultHeight,
        width: widthProp ?? defaultWidth
      }
    }, [heightProp, size, strokeWidthProp, widthProp])

    const classes = useStyles({ stroke, strokeWidth, height, width })

    return (
      <span className={classes.root} ref={ref}>
        <IconComponent {...iconProps} />
      </span>
    )
  }
)

export default Icon
