import React, { Children } from 'react'

import { palette } from '../../atoms'
import Stack from '../atoms/Stack'
import { Divider, useTheme } from '@material-ui/core'

interface WidgetArrayProps {
  children: React.ReactNode
}

const WidgetArray: React.FC<WidgetArrayProps> = React.memo((props) => {
  const { children } = props

  const theme = useTheme()

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-evenly"
      flexWrap="wrap"
      style={{
        backgroundColor: 'white',
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: theme.shape.borderRadius,
        borderColor: palette.gray[300]
      }}
    >
      {/* TODO: switch to using CSS to do this */}
      {Children.map(children, (child, idx) => (
        <>
          {child}
          {idx !== Children.count(children) - 1 && (
            <Divider
              orientation="vertical"
              style={{
                backgroundColor: palette.gray[300]
              }}
              flexItem
            />
          )}
        </>
      ))}
    </Stack>
  )
})

export default WidgetArray
