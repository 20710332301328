export enum UserUploadedFileSource {
  summarizer = 'summarizer',
  blog = 'blog',
  blog_knowledge = 'blog_knowledge',
  // Used when uploading a file within the ChatBox component, which adds the file to the current chat
  hypo_chat = 'hypo_chat',
  // Used when uploading a file within the thread column, which creates a new thread
  hypo_doc = 'hypo_doc',
  tone_analyzer = 'tone_analyzer',
  // Used when uploading a file in the document management page
  document_manager = 'document_manager',
  // Used when uploading a file from the knowledge base component
  knowledge_base = 'knowledge_base',
  /**
   * Allow all templates to upload and select files
   */
  ask_anything = 'ask_anything',
  rewriter = 'rewriter',
  instagram = 'instagram',
  google = 'google',
  facebook = 'facebook',
  linkedin = 'linkedin',
  headline = 'headline',
  landing_page = 'landing_page',
  meta_desc = 'meta_desc',
  category_page = 'category_page',
  marketing_angles = 'marketing_angles',
  eli5 = 'eli5',
  email = 'email',
  youtube_title = 'youtube_title',
  video_intro = 'video_intro',
  bio_writer = 'bio_writer',
  press_release = 'press_release',
  product_monitoring = 'product_monitoring',

  rewrite_for_seo = 'rewrite_for_seo'
}
