import React from 'react'

import { FileRejection, useDropzone } from 'react-dropzone'

import { Typography, palette } from '../../atoms'
import Stack from '../atoms/Stack'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core'
import clsx from 'clsx'

import UploadIconSvg from '@hypotenuse/common/src/assets/upload-icon.svg'

import ReferenceFileCardItem from '../blog/ReferenceFileCardItem'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    uploadIcon: {
      borderRadius: theme.spacing(1),
      backgroundColor: 'white',
      border: `1px solid ${palette.gray[200]}`,
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
    },
    dropzoneTitle: {
      marginLeft: '6px'
    },
    dropzone: {
      // TODO: Change to dashed border when using react-dropzone
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      alignItems: 'center',
      padding: theme.spacing(2, 3),
      transition: theme.transitions.create(['border', 'background-color'], {
        duration: theme.transitions.duration.short
      }),
      border: `1px solid ${palette.gray[200]}`,
      borderRadius: theme.spacing(1.5),
      backgroundColor: palette.gray[50],
      color: palette.gray['700'],
      cursor: 'pointer',
      outline: 'none'
    },
    fileUploadDisabled: {
      opacity: 0.3
    },
    fileUploadAccept: {
      borderColor: palette.success[500]
    },
    fileUploadReject: {
      borderColor: palette.error[700]
    }
  })
)

interface Props {
  files: File[]
  onDropAccepted: (files: File[]) => Promise<void>
  onDropRejected: (rejections: FileRejection[]) => void
  onRemoveFile: () => void
}

const ImportDropZone: React.FC<Props> = React.memo((props) => {
  const { onDropAccepted, onDropRejected, files, onRemoveFile } = props

  const classes = useStyles()
  const theme = useTheme()

  const fileDropzone = useDropzone({
    disabled: files && files.length > 0,
    accept: '.csv,.xls,.xlsx',
    maxFiles: 1,
    onDropAccepted: onDropAccepted,
    onDropRejected: onDropRejected
  })

  return (
    <Stack>
      <Stack
        data-testid="importModal-csvImport-dropzone"
        alignItems="center"
        className={clsx(classes.dropzone, {
          [classes.fileUploadDisabled]: false,
          [classes.fileUploadAccept]: fileDropzone.isDragAccept,
          [classes.fileUploadReject]: fileDropzone.isDragReject
        })}
        {...fileDropzone.getRootProps()}
      >
        <input
          {...fileDropzone.getInputProps()}
          data-testid="importModal-csvImport-dropzone-input"
        />
        <img
          src={UploadIconSvg}
          alt="Hypotenuse Upload Logo"
          className={classes.uploadIcon}
        />

        <Typography
          variant="paragraph2Reg"
          className={classes.dropzoneTitle}
          style={{ color: palette.gray[600], marginTop: theme.spacing(1) }}
        >
          Click to upload or drag and drop
        </Typography>

        <Typography variant="paragraph2Reg">
          <span style={{ color: palette.primary[700], fontWeight: 700 }}>
            spreadsheet (.csv or .xlsx)
          </span>{' '}
          containing your products
        </Typography>
      </Stack>
      {files?.map((file) => (
        <ReferenceFileCardItem
          handleRemoveFile={onRemoveFile}
          fileName={file.name}
          fileSize={file.size}
        />
      ))}
    </Stack>
  )
})

export default ImportDropZone
