import { apiClient } from '@hypotenuse/common/src/utils/ApiClient'
import snackbar from '@hypotenuse/common/src/utils/Snackbar'

import {
  User,
  UserOnboardingInfoForm,
  UserUpdateForm
} from '../components/utils/Interfaces'
import { PREMIUM_LEGACY_LOGOUT_PATH } from '../utils/Constants'

export const apiLogout = async (): Promise<any> => {
  const response = await apiClient.get<void>(PREMIUM_LEGACY_LOGOUT_PATH)
  return response.data
}

export const apiFetchUser = async (): Promise<User> => {
  const response = await apiClient.get<User>(`/user/me`)
  return response.data
}

export const apiResendVerificationEmail = async (): Promise<void> => {
  const response = await apiClient.post<void>('/send-verification-email')
  return response.data
}

export const apiTryVerifyEmail = async () => {
  const response = await apiClient.post<{ verified: boolean }>('/verify-email')
  return response.data
}

export const apiRequestPhoneVerificationCode = async (phoneNumber: string) => {
  const response = await apiClient.post<void>(
    '/auth/mfa/phone/challenge',
    null,
    {
      params: {
        phone_number: phoneNumber
      }
    }
  )
  return response.data
}

export const apiCheckPhoneVerificationCode = async (
  phoneNumber: string,
  code: string
) => {
  const response = await apiClient.post<void>(
    '/auth/mfa/phone/verify-and-associate',
    null,
    {
      params: {
        phone_number: phoneNumber,
        code
      }
    }
  )
  return response.data
}

export const apiGetIsPhoneVerified = async () => {
  const response = await apiClient.get<boolean>('/auth/mfa/phone/is-verified')
  return response.data
}

export const getUserOnboardInfo = async (): Promise<UserOnboardingInfoForm> => {
  const response = await apiClient.get<UserOnboardingInfoForm>(
    '/user/onboarding_info'
  )
  return response.data
}

export const upsertUserOnboardInfoForm = async (
  userOnboardInfoForm: UserOnboardingInfoForm,
  isOnboardingFormSubmission: boolean
): Promise<UserOnboardingInfoForm> => {
  const response = await apiClient.post<UserOnboardingInfoForm>(
    '/user/onboarding_info',
    {
      user_onboard_form: userOnboardInfoForm,
      is_onboarding_form_submission: isOnboardingFormSubmission
    }
  )
  return response.data
}

export const apiUpdateUser = async (userUpdateForm: UserUpdateForm) => {
  const response = await apiClient.put(`/user/me`, userUpdateForm)
  return response.data
}

export const apiUpdateUserHasVisitedMetaDesc = async () => {
  const response = await apiClient.post(`/user/me/has-visited-meta-desc`)
  return response.data
}

export const apiGetUserCountryCode = async () => {
  const response = await apiClient.get<string | undefined>('/user/country_code')
  return response.data
}

export const apiDeleteAccount = async (): Promise<boolean> => {
  try {
    await apiClient.delete(`/user/me`)
    snackbar.show('Account deleted', { variant: 'success' })
    return true
  } catch (e) {
    snackbar.show('Failed to delete account', { variant: 'error' })
    return false
  }
}

export const apiGetIsAntiSpamConfigupdated = async (): Promise<boolean> => {
  const response = await apiClient.get<boolean>(
    '/user/anti_spam_config_updated_status'
  )
  return response.data
}
