import React, { useMemo } from 'react'

import { Typography, palette } from '../../atoms'
import Stack from '../atoms/Stack'
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import { useAsyncFn } from '../../hooks'

import { ProductStats, TrackedProductWithStats } from './interfaces'

import { SelectCheckbox } from '../SelectCheckbox'

interface ProductsTableProps {
  trackedProducts: TrackedProductWithStats[]
  productStats?: Record<string, ProductStats>
  onOptimizeRanking: () => Promise<void>
  isProductStatsLoading: boolean
}

const ProductsTable: React.FC<ProductsTableProps> = React.memo((props) => {
  const {
    trackedProducts,
    onOptimizeRanking,
    isProductStatsLoading,
    productStats
  } = props

  const theme = useTheme()

  const currentDateTime = useMemo(() => {
    const dateNow = new Date()
    return `${dateNow.getDate()} ${dateNow.toLocaleString('default', {
      month: 'short'
    })} ${dateNow.getHours()}:${dateNow
      .getMinutes()
      .toString()
      .padStart(2, '0')}`
  }, [])

  const [
    { loading: isOptimizeRankingLoading },
    handleOptimizeProducts
  ] = useAsyncFn(onOptimizeRanking, [onOptimizeRanking])

  return (
    <Stack
      borderRadius="8px"
      border={`1px solid ${palette.gray[300]}`}
      bgcolor="white"
    >
      <Stack paddingX={3} borderBottom={`1px solid ${palette.gray[200]}`}>
        <Stack
          paddingY={2.5}
          spacing={1}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack spacing={1.5} flexDirection="row" alignItems="center">
            <Typography variant="paragraph1Bold">Products</Typography>
            <Typography
              variant="paragraph2Reg"
              style={{
                color: palette.gray[500]
              }}
            >
              last updated {currentDateTime}
            </Typography>
          </Stack>
          <Button
            color="default"
            variant="outlined"
            disabled={!trackedProducts.length || isOptimizeRankingLoading}
            onClick={handleOptimizeProducts}
            endIcon={
              isOptimizeRankingLoading ? (
                <CircularProgress color="inherit" size="1rem" />
              ) : undefined
            }
          >
            Optimize ranking
          </Button>
        </Stack>
      </Stack>
      <Stack borderRadius="8px">
        <TableContainer>
          <Table size="medium">
            <TableHead>
              {trackedProducts.length > 0 && (
                <TableRow>
                  <TableCell
                    padding="checkbox"
                    style={{
                      paddingLeft: theme.spacing(3),
                      paddingRight: theme.spacing(1.5)
                    }}
                  >
                    <SelectCheckbox size={15} checkboxSelected={false} />
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="paragraph2Bold"
                      style={{
                        color: palette.gray[700]
                      }}
                    >
                      Product titles
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                      flexDirection="row"
                    >
                      <Typography
                        variant="paragraph2Bold"
                        style={{
                          color: palette.gray[700]
                        }}
                      >
                        Search rank
                      </Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {trackedProducts.map(({ id, product_title: title }) => (
                <TableRow key={id}>
                  <TableCell
                    padding="checkbox"
                    onClick={(event) => {
                      event.preventDefault()
                      event.stopPropagation()
                      // toggleProductSelection(product.id)
                    }}
                    style={{
                      paddingLeft: theme.spacing(3),
                      paddingRight: theme.spacing(1.5)
                    }}
                  >
                    <SelectCheckbox size={15} checkboxSelected={false} />
                  </TableCell>

                  <TableCell>{title}</TableCell>
                  <TableCell>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                      flexDirection="row"
                    >
                      <Typography
                        variant="paragraph2Reg"
                        style={
                          productStats &&
                          productStats[id] &&
                          (productStats[id].rank ?? 0) > 0
                            ? { color: palette.green[500], fontWeight: 700 }
                            : {
                                color: palette.red[500],
                                fontWeight: 700
                              }
                        }
                      >
                        {isProductStatsLoading ? (
                          <Skeleton variant="text" width={50} />
                        ) : productStats &&
                          productStats[id] &&
                          productStats[id].rank ? (
                          '#' + productStats[id].rank
                        ) : (
                          'N/A'
                        )}
                      </Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {trackedProducts.length === 0 && (
          <Stack paddingTop={3} paddingBottom={3} alignItems="center">
            <Typography
              variant="paragraph1Reg"
              style={{ color: palette.gray[500] }}
            >
              Add products to start tracking
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
})

export default ProductsTable
