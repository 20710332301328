import React, { useCallback, useEffect, useRef, useState } from 'react'

import * as amplitude from '@amplitude/analytics-browser'
import * as Sentry from '@sentry/browser'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import ReactGA from 'react-ga'

import { handleTrackPageVisit } from '../api/Analytics'

export interface TrackEventParams {
  isError: boolean
}

export interface AnalyticsInterface {
  setUsername: (username?: string) => void
  setUserPlanType: (username?: string) => void
  /**
   * Interface derived from Google Analytics SDK
   * @param {string} category Broad category of the event
   * @param {string} event Specific event
   * @param {TrackEventParams} params Additional info about the even that affects how the event is handled/tracked
   */
  trackEvent: (
    category: string,
    event: string,
    params?: TrackEventParams
  ) => Promise<void>
  trackPageView: (path: string) => void
}

export const AnalyticsContext = React.createContext<
  AnalyticsInterface | undefined
>(undefined)

export interface AnalyticsProviderProps {
  disableTracking?: boolean
  username?: string
  logRocketAppId: string
  logRocketExcludedUsernamePatterns?: RegExp[]
  googleAnalyticsTrackingCode: string
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = (props) => {
  const {
    children,
    username: initialUsername,
    disableTracking,
    logRocketAppId,
    logRocketExcludedUsernamePatterns,
    googleAnalyticsTrackingCode
  } = props

  const [username, setUsername] = useState<string | undefined>(initialUsername)
  const [planType, setUserPlanType] = useState<string | undefined>(undefined)
  const [
    isIdentifiedAnalyticsInitialized,
    setIsIdentifiedAnalyticsInitialized
  ] = useState<boolean>(false)
  // This ref is used to alert us about potentially missing LogRocket sessions
  const logRocketInitRef = useRef<boolean>(false)

  /**
   * Set the active user for analytics services
   */
  const identify = useCallback(
    async (username: string, planType: string): Promise<void> => {
      if (disableTracking) return

      try {
        LogRocket.identify(username, { name: username, planType: planType })
        ReactGA.set({ userId: username })
        amplitude.setUserId(username)
        Sentry.setUser({
          username,
          email: username,
          id: username,
          ip_address: '{{auto}}'
        })
      } catch (error) {
        Sentry.captureException(error, { level: 'error' })
      }
    },
    [disableTracking]
  )
  /**
   * Check whether a given user is excluded from LogRocket tracking
   */
  const isUsernameExcludedFromLogRocketTracking = useCallback(
    (username: string): boolean => {
      return (
        logRocketExcludedUsernamePatterns
          ?.map((pattern) => pattern.test(username))
          ?.some((v) => v) || false
      )
    },
    [logRocketExcludedUsernamePatterns]
  )
  /**
   * Initialize analytics services anonymously i.e. without attaching to a user
   */
  const initializeAnalyticsAnonymous = useCallback(async (): Promise<void> => {
    if (disableTracking) return

    try {
      // Google Analytics (GA) for platform analytics
      ReactGA.initialize(googleAnalyticsTrackingCode)
    } catch (error) {
      if (error) {
        Sentry.captureException(error, { level: 'error' })
      }
    }
  }, [disableTracking, googleAnalyticsTrackingCode])
  /**
   * Initialize analytics services that require user identification
   */
  const initializeAnalyticsWithUser = useCallback(
    async (username: string, planType: string): Promise<void> => {
      if (disableTracking) return

      // TODO: Implement anonymous LogRocket tracking when user is not available (how would we know?)
      // Initialize LogRocket & Identify user.
      // We try to catch any missing LogRocket sessions by first checking if there was any error thrown from the init
      // methods, or whether the methods were even run properly.
      if (!isUsernameExcludedFromLogRocketTracking(username)) {
        LogRocket.init(logRocketAppId)
        setupLogRocketReact(LogRocket)
        logRocketInitRef.current = true
      }

      // Attach user to analytics services after they've been initialized
      await identify(username, planType)
    },
    [
      disableTracking,
      identify,
      isUsernameExcludedFromLogRocketTracking,
      logRocketAppId
    ]
  )
  const checkIfLogRocketInitAndThrowSentryError = useCallback(async (): Promise<void> => {
    if (!logRocketInitRef.current) {
      Sentry.captureMessage(
        'A tracking function was called without being initialised',
        { level: 'error' }
      )
    }
  }, [])

  useEffect(() => {
    setUsername(initialUsername)
  }, [initialUsername])
  useEffect(() => {
    // Initialize anonymous analytics services immediately on load
    void initializeAnalyticsAnonymous()
  }, [initializeAnalyticsAnonymous])
  useEffect(() => {
    if (!username) return

    if (!planType) return

    // If analytics services are not already initialized, do so and attach the current user
    if (!isIdentifiedAnalyticsInitialized) {
      initializeAnalyticsWithUser(username, planType).then(() => {
        setIsIdentifiedAnalyticsInitialized(true)
      })
    }
    // Otherwise, just attach the current user to analytics services
    else {
      void identify(username, planType)
    }
  }, [
    identify,
    initializeAnalyticsWithUser,
    isIdentifiedAnalyticsInitialized,
    username,
    planType
  ])

  const trackEvent = useCallback(
    async (
      category: string,
      event: string,
      params?: TrackEventParams
    ): Promise<void> => {
      if (disableTracking) return

      if (!(username && isUsernameExcludedFromLogRocketTracking(username))) {
        await checkIfLogRocketInitAndThrowSentryError()
        LogRocket.track(event)
      }
      if (params?.isError) Sentry.captureMessage(event)

      ReactGA.event({ category: category, action: event })
    },
    [
      username,
      disableTracking,
      isUsernameExcludedFromLogRocketTracking,
      checkIfLogRocketInitAndThrowSentryError
    ]
  )
  const trackPageView = useCallback(
    async (path: string): Promise<void> => {
      // TODO: Use router/history(?) to automatically track route changes
      if (disableTracking) return

      await handleTrackPageVisit(path)
      ReactGA.pageview(path)
    },
    [disableTracking]
  )

  return (
    <AnalyticsContext.Provider
      value={{ setUsername, setUserPlanType, trackEvent, trackPageView }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}

export const useAnalytics = () => {
  const context = React.useContext(AnalyticsContext)
  if (context === undefined) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider')
  }
  return context
}

// region Backward compatibility
/**
 * This section provides backward compatibility with the static `Analytics` class interface.
 * Prefer using the `useAnalytics` hook directly where possible.
 */

interface ConfiguratorProps {
  setUseAnalyticsRef: (analytics: AnalyticsInterface) => void
}

const InnerAnalyticsConfigurator: React.FC<ConfiguratorProps> = (
  props: ConfiguratorProps
) => {
  props.setUseAnalyticsRef(useAnalytics())
  return null
}

let useAnalyticsRef: AnalyticsInterface | undefined
const setUseAnalyticsRef = (useAnalyticsRefProp: AnalyticsInterface) => {
  useAnalyticsRef = useAnalyticsRefProp
}

/**
 * This component exposes the functionality of the `useAnalytics` hook to be usable outside React function components.
 * @example
 *   <AnalyticsProvider value={...}>
 *     <AnalyticsConfigurator/>
 *     <App/>
 *   </AnalyticsProvider>
 *
 *   Elsewhere, use `Analytics` to access the props returned by
 */
export const AnalyticsConfigurator = () => (
  <InnerAnalyticsConfigurator setUseAnalyticsRef={setUseAnalyticsRef} />
)

const notConfiguredError = new Error(
  'Attempted to call a method of the static Analytics instance, but either AnalyticsProvider or ' +
    'AnalyticsConfigurator is not present in the component hierarchy.'
)
/**
 * Convenience wrapper to expose the contents of the `useAnalytics` hook to be usable outside React function components.
 * @deprecated This wrapper is present for backward compatibility and its use in new components should be limited.
 *  Prefer using the hook directly so that React can correctly handle re-rendering on state/prop updates.
 */
export const Analytics = {
  trackEvent: (category: string, event: string, params?: TrackEventParams) => {
    if (useAnalyticsRef === undefined) {
      throw notConfiguredError
    }
    return useAnalyticsRef.trackEvent(category, event, params)
  },
  trackPageView: (path: string) => {
    if (useAnalyticsRef === undefined) {
      throw notConfiguredError
    }
    return useAnalyticsRef.trackPageView(path)
  }
}
// endregion
