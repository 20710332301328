import { useMemo } from 'react'

import { createStyles, makeStyles } from '@material-ui/core/styles'

/**
 * A styling hook that returns a pair of classNames that can be used to
 * show a child element when a parent element is hovered.
 *
 * Note that each usage of this hook creates a new set of classNames, so
 * you can call the hook multiple times in the same component for different
 * parent/child element combinations.
 */
export const useShowChildOnHover = () => {
  const useStyles = useMemo(
    () =>
      makeStyles((theme) =>
        createStyles({
          parent: {
            // Class for parent elements that should show their children on hover
            '& $child': {
              transition: theme.transitions.create(['opacity', 'visibility'], {
                duration: 200
              }),
              opacity: '0',
              visibility: 'hidden'
            },
            '&:hover $child': {
              opacity: '1',
              visibility: 'visible'
            }
          },
          child: {
            // Class for children that should only be visible on hover
          }
        })
      ),
    []
  )
  return useStyles()
}
