import React, { useState } from 'react'

import useSWR from 'swr'

import palette from '@hypotenuse/common/src/atoms/Colors'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import Spacer from '@hypotenuse/common/src/components/atoms/Spacer'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import { Divider, Link, Paper, Theme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import clsx from 'clsx'

import { CONTACT_US_LINK } from '@hypotenuse/common/src/utils/Constants'
import { PlanTierTheme } from '@hypotenuse/common/src/utils/Interfaces'

import { fetchPremiumPlanTier } from '../../../../api/PlanTier'

import { CONTENT_CARDS_BOX_SHADOWS } from '../../../../utils/Constants'
import { PlanBillingPeriod, PlanType } from '../../../utils/Interfaces'
import type { PremiumPlanTier } from '../../../utils/Interfaces'

import { PlanDescriptionField } from './components/PlanDescriptionField'
import PlanDetailsList from './components/PlanDetailsList'
import { PlanPrice } from './components/PlanPricingRow'
import { PlanSelectorSlider } from './components/PlanSelectorSlider'
import { PricingPlanCardCTAButton } from './components/PricingPlanCardCTAButton'
import { PricingPlanHeader } from './components/PricingPlanHeader'

export interface MultiPricingPlanCardProps {
  planTiers: PremiumPlanTier[]
  icon?: React.ReactNode
  activePlanId: string
  userCountryCode: string
  isCustom?: boolean
  onSelect: (id: string) => () => Promise<void>
  onCancel: () => Promise<void>
  loading?: boolean
  isCancelAtPeriodEnd?: boolean
  isPromotionCountryCode?: boolean
  /**
   * Whether the plan is a legacy plan.
   */
  isLegacyPlan?: boolean
  isPaused?: boolean
  /**
   * Element ID for used for tracking purposes, used by enterprise plan card.
   * If not provided, the tracking ID will be the plan ID.
   */
  trackingId?: string
  /**
   * Overrides the cta name
   */
  ctaName?: string
  shouldShowPriceForEnterprisePlan?: boolean
  /**
   * Time to next invoice for the plan
   */
  timeToNextInvoice?: string
  /**
   * The upcoming invoice date
   */
  upcomingInvoiceDate?: string
  /**
   * Whether the upcoming invoice is loading
   */
  isLoadingUpcomingInvoice?: boolean
  /**
   * Whether the user is trialing the plan
   */
  currTrialingPlan: string
  /**
   * Whether the user is fetching stripe billing info
   */
  isFetchingStripeBillingInfo?: boolean
  /**
   * The time to the billing period ends
   */
  timeToBillingPeriodEnds?: string
  /**
   * The current billing period end string
   */
  currentBillingPeriodEndString?: string
}

const useStyles = makeStyles((theme: Theme) => {
  const darkThemeColor = theme.palette.text.primary
  return createStyles({
    darkTheme: {
      color: palette.gray['200'],
      backgroundColor: darkThemeColor
    },
    darkBackground: {
      backgroundColor: darkThemeColor
    },
    greyBackground: {
      backgroundColor: palette.gray['300']
    },
    lightBox: {
      color: darkThemeColor,
      borderColor: palette.gray['300'],
      backgroundColor: theme.palette.getContrastText(darkThemeColor)
    },
    activeTheme: {
      backgroundColor: palette.primary[600]
    },
    activeBox: {
      color: darkThemeColor,
      borderColor: palette.primary[600],
      borderWidth: '2px',
      backgroundColor: theme.palette.getContrastText(darkThemeColor)
    }
  })
})

/**
 * Returns the parent tier, i.e., the tier that should be displayed first when the page is loaded.
 * Reduces the list by using the given comparison predicate.
 *
 * @param planTiers The list of tiers to pick from.
 * @param comparisonPredicate The predicate used to compare two plan tiers and pick one from.
 * @returns The parent plan tier.
 */
const defaultTierOrdering = (
  planTiers: PremiumPlanTier[],
  comparisonPredicate: (
    previousValue: PremiumPlanTier,
    currentValue: PremiumPlanTier
  ) => PremiumPlanTier
): PremiumPlanTier =>
  planTiers.length > 1
    ? planTiers.reduce(comparisonPredicate, planTiers[0])
    : planTiers[0]

export default function MultiPricingPlanCard(props: MultiPricingPlanCardProps) {
  const {
    planTiers,
    icon,
    activePlanId,
    userCountryCode,
    isCustom,
    onSelect,
    onCancel,
    loading,
    isCancelAtPeriodEnd,
    isPromotionCountryCode,
    isLegacyPlan = false,
    isPaused = false,
    trackingId,
    ctaName,
    shouldShowPriceForEnterprisePlan,
    timeToNextInvoice,
    isLoadingUpcomingInvoice,
    currTrialingPlan,
    isFetchingStripeBillingInfo,
    timeToBillingPeriodEnds,
    currentBillingPeriodEndString,
    upcomingInvoiceDate
  } = props

  const defaultPlanTier = defaultTierOrdering(
    planTiers,
    (previous: PremiumPlanTier, curr: PremiumPlanTier) =>
      activePlanId === curr.plan_id ? curr : previous
  )

  const [currentPlanTier, setCurrentPlanTier] = useState<PremiumPlanTier>(
    defaultPlanTier
  )

  const {
    data: orgPlanTierDetails,
    isLoading: isLoadingOrgPlanTierDetails
  } = useSWR(['orgPlanTierDetails'], fetchPremiumPlanTier)

  const isFreePlan = currentPlanTier.plan_type === PlanType.free
  const isActive = currentPlanTier.plan_id === activePlanId
  const isTrialablePlan = currentPlanTier.is_trialable_plan
  const trialDuration = currentPlanTier.trial_duration
  const hasTrialedPlan = orgPlanTierDetails?.previously_trialed_plan_ids?.includes(
    planTiers[0].plan_id
  )
  const contactUsLink = currentPlanTier.contact_us_link ?? CONTACT_US_LINK

  const planPrice =
    currentPlanTier.plan_price_mapping?.[userCountryCode] &&
    isPromotionCountryCode
      ? currentPlanTier.plan_price_mapping[userCountryCode]?.price
      : currentPlanTier.plan_price

  const isUnlimitedPlan = currentPlanTier.plan_type === PlanType.unlimited
  const isUnlimitedDifferentPlan =
    currentPlanTier.plan_type === PlanType.unlimited &&
    currentPlanTier.forced_plan_type === PlanType.starter

  const planCurrency =
    currentPlanTier.plan_price_mapping?.[userCountryCode] &&
    isPromotionCountryCode
      ? currentPlanTier.plan_price_mapping[
          userCountryCode
        ]?.currency.toLowerCase()
      : currentPlanTier.currency.toLowerCase()

  const classes = useStyles()

  /**
   * Determines if the default theme is dark.
   */
  const isDefaultDarkTheme = !!(
    currentPlanTier.theme_override === PlanTierTheme.dark ||
    (isCustom && currentPlanTier.theme_override !== PlanTierTheme.light)
  )

  /**
   * When the theme is active, we want to show the active theme color,
   * unless the plan is a custom plan and the default intended theme is dark.
   */
  const isActiveTheme = isActive && !(isCustom && isDefaultDarkTheme)
  const isDarkTheme = isDefaultDarkTheme && !isActiveTheme

  return (
    <Stack
      component={Paper}
      borderRadius={10}
      overflow="hidden"
      height="100%"
      border={1}
      style={{ boxShadow: CONTENT_CARDS_BOX_SHADOWS }}
      className={clsx(
        isActiveTheme
          ? classes.activeBox
          : isDarkTheme
          ? classes.darkTheme
          : classes.lightBox
      )}
    >
      <PricingPlanHeader
        planName={currentPlanTier.plan_display_name}
        isActive={isActive}
        icon={icon}
        isCustom={isCustom}
      />
      <Divider
        flexItem
        orientation={'horizontal'}
        style={{ minHeight: isActive && !isCustom ? '2px' : '1px' }}
        classes={{
          root: isActiveTheme
            ? classes.activeTheme
            : isDarkTheme
            ? classes.darkBackground
            : classes.greyBackground
        }}
      />
      <Stack spacing={2} p={2} pb={3} flex={1}>
        <PlanDescriptionField
          desc={currentPlanTier.plan_description}
          isDarkTheme={isDarkTheme}
        />
        <PlanPrice
          price={planPrice}
          priceDisplayString={currentPlanTier.plan_display_price}
          pricePrefix={currentPlanTier.plan_price_prefix}
          currency={planCurrency}
          isCustom={isCustom && !currentPlanTier.plan_display_price}
          isYearly={
            currentPlanTier.plan_billing_period === PlanBillingPeriod.yearly
          }
          isActive={isActive}
          isPaused={isPaused}
          isCancelAtPeriodEnd={isCancelAtPeriodEnd}
          isUnlimitedPlan={isUnlimitedPlan}
          isUnlimitedDifferentPlan={isUnlimitedDifferentPlan}
          lightText={isDarkTheme}
          shouldShowPriceForEnterprisePlan={shouldShowPriceForEnterprisePlan}
        />
        {!isCustom && (
          <PlanSelectorSlider
            currentPlanTier={currentPlanTier}
            setCurrentPlanTier={setCurrentPlanTier}
            planTiers={planTiers}
            lightText={isDarkTheme}
            isUnlimitedPlan={isUnlimitedPlan}
          />
        )}
        <PlanDetailsList
          benefits={currentPlanTier.plan_incentives}
          lightText={isDarkTheme}
          isUnlimitedPlan={isUnlimitedPlan}
        />
        {/* CTA Button must be at the very bottom of the card */}
        <Spacer />
        {/* We don't want to show the CTA button if the plan is either:
            - A free plan
            - A selected legacy plan which will cancel at end of period */}
        {isUnlimitedPlan && (
          <Link
            href="https://www.hypotenuse.ai/terms-of-service"
            underline="always"
          >
            <Typography
              color="textPrimary"
              variant="subtitle2"
              style={{ textAlign: 'left' }}
            >
              * subject to fair use policy
            </Typography>
          </Link>
        )}
        {!isFreePlan && (!isLegacyPlan || !isActive || !isCancelAtPeriodEnd) && (
          <PricingPlanCardCTAButton
            isActive={isActive}
            isCancelAtPeriodEnd={isCancelAtPeriodEnd}
            isPaused={isPaused}
            isCustom={isCustom}
            loading={loading}
            onCancel={onCancel}
            onSelect={onSelect(currentPlanTier.plan_id)}
            trackingId={trackingId ?? currentPlanTier.plan_id}
            planId={currentPlanTier.plan_id}
            cancellableByUser={currentPlanTier.cancellable_by_user ?? true} // Default to true if not provided
            isDarkTheme={isDarkTheme}
            ctaName={ctaName}
            contactUsLink={contactUsLink}
            isTrialablePlan={isTrialablePlan}
            hasTrialedPlan={hasTrialedPlan}
            trialDuration={trialDuration}
          />
        )}
        {isTrialablePlan && !isLoadingOrgPlanTierDetails ? (
          isLoadingUpcomingInvoice || isFetchingStripeBillingInfo ? (
            <Stack flexDirection={'column'}>
              <Skeleton animation="wave" width="100%" />
              <Skeleton animation="wave" width="100%" />
              <Skeleton animation="wave" width="100%" />
            </Stack>
          ) : currentPlanTier.plan_id === currTrialingPlan ? (
            <Typography
              variant="captionReg"
              style={{ color: palette.gray['500'], textAlign: 'center' }}
            >
              Your trial ends{' '}
              {orgPlanTierDetails?.cancel_at_period_end ? (
                <>
                  <span style={{ fontWeight: 'bold' }}>
                    {timeToBillingPeriodEnds}, {currentBillingPeriodEndString}.
                  </span>{' '}
                  After that, you will no longer have access to Blog Pro plan
                  and your credit card will not be charged.
                </>
              ) : (
                <>
                  <span style={{ fontWeight: 'bold' }}>
                    {timeToNextInvoice}, {upcomingInvoiceDate}.
                  </span>{' '}
                  After that, your subscription will start and your credit card
                  will be charged automatically.
                </>
              )}
            </Typography>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </Stack>
    </Stack>
  )
}
