import { apiClient } from '@hypotenuse/common/src/utils/ApiClient'

import { StripeBillingInfo } from '../components/utils/Interfaces'

export const fetchUserBillingInfo = (): Promise<StripeBillingInfo> => {
  return apiClient.get('/billing_info').then((resp) => resp.data)
}

export const handleCreateStripeCustomerPortal = (): Promise<string> => {
  return apiClient
    .post('/create_stripe_customer_portal_session')
    .then((resp) => {
      return resp.data.portal_url
    })
}

export const handleCreateStripeCheckoutSession = (
  plan_id: string
): Promise<string> => {
  return apiClient
    .post(`/create_stripe_checkout_session/${plan_id}`)
    .then((resp) => {
      return resp.data.session_id
    })
}

export const apiGetStripeInvoicePaymentLink = (
  invoice_id: string
): Promise<string> => {
  return apiClient
    .get('/stripe-invoice-payment-link', {
      params: { invoice_id }
    })
    .then((resp) => {
      return resp.data
    })
}
