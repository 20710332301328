import React from 'react'

/**
 * This is what's returned when you call `userToolCardContext()`
 */
export interface IToolCardContext {
  sectionId?: string
}

const ToolCardContext = React.createContext<IToolCardContext | undefined>(
  undefined
)

export const ToolCardProvider = ToolCardContext.Provider

export const useToolCardContext = (): IToolCardContext | undefined => {
  // Allow returning undefined because we might not always have a
  // context provider, such as in tests
  const context = React.useContext(ToolCardContext)
  return context
}
