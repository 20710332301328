import React, { useCallback, useMemo, useState } from 'react'

import { useTheme } from '@mui/material/styles'
import { useHistory, useLocation } from 'react-router-dom'
import { ModalHeader } from 'semantic-ui-react'

import palette from '@hypotenuse/common/src/atoms/Colors'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import {
  Button,
  CircularProgress,
  MenuItem,
  TextField
} from '@material-ui/core'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { ReactComponent as EllipseIcon } from '@hypotenuse/common/src/assets/ellipse-status.svg'
import { ReactComponent as ArrowRight } from '@hypotenuse/common/src/assets/icons/arrow-right.svg'
import PattyOnboarding from '@hypotenuse/common/src/assets/patty-onboarding.svg'
import { ReactComponent as StarSvg } from '@hypotenuse/common/src/assets/star-06.svg'
import BaseModal, {
  ModalActions,
  ModalContent,
  PrimaryButton
} from '@hypotenuse/common/src/components/BaseModal'
import { GenericLinkField } from '@hypotenuse/common/src/components/link_field'
import {
  apiCreateContentRecommendation,
  apiGetRecommendedTools
} from '@hypotenuse/common/src/components/liveFeed/api'
import {
  TopicRecommendationCollectionType,
  TopicRecommendationTriggerSource
} from '@hypotenuse/common/src/components/liveFeed/types'
import { useAsyncFn } from '@hypotenuse/common/src/hooks'
import { useContentRecommendation } from '@hypotenuse/common/src/hooks/useContentRecommendation'
import { useLocalStorage } from '@hypotenuse/common/src/hooks/useLocalStorage'
import { ContentGenerationType } from '@hypotenuse/common/src/interfaces/ContentGeneration'
import {
  AppFeatures,
  OnboardingPageAt,
  SniperLinkFeaturesToUrlSlugMap,
  UserCompanySize,
  UserCompanySizeLabel,
  UserHeardAboutUsChannel,
  UserHeardAboutUsChannelLabel,
  UserRole,
  UserRoleLabel,
  UserWork,
  UserWorkLabel,
  UserWorkLabelOnboardingOptions
} from '@hypotenuse/common/src/utils/Interfaces'

import { upsertUserOnboardInfoForm } from '../../../api/User'

import { useOnboardingContext } from '../../../utils/context/OnboardingContext'

import { ToggleButton } from './ToggleButton'

interface OnboardingModalProps {
  open: boolean
}

const channels = [
  {
    id: UserHeardAboutUsChannel.search_engine,
    display: UserHeardAboutUsChannelLabel.search_engine
  },
  {
    id: UserHeardAboutUsChannel.linkedin,
    display: UserHeardAboutUsChannelLabel.linkedin
  },
  {
    id: UserHeardAboutUsChannel.social_media,
    display: UserHeardAboutUsChannelLabel.social_media
  },
  {
    id: UserHeardAboutUsChannel.colleague_or_friend,
    display: UserHeardAboutUsChannelLabel.colleague_or_friend
  },
  {
    id: UserHeardAboutUsChannel.others,
    display: UserHeardAboutUsChannelLabel.others
  }
]

/**
 * What the user is working on
 */
const userRole = [
  {
    id: UserRole.ecommerce_product,
    display: `🛒 ${UserRoleLabel.ecommerce_product}`
  },
  {
    id: UserRole.blog,
    display: `📝 ${UserRoleLabel.blog}`
  },
  { id: UserRole.marketing, display: `📈 ${UserRoleLabel.marketing}` },
  {
    id: UserRole.general_writing,
    display: `✍️ ${UserRoleLabel.general_writing}`
  },
  { id: UserRole.school, display: `🎓 ${UserRoleLabel.school}` },
  { id: UserRole.others, display: `🔎 ${UserRoleLabel.others}` }
]

const employeeCount = [
  {
    id: UserCompanySize.one_to_nine,
    display: UserCompanySizeLabel.one_to_nine
  },
  {
    id: UserCompanySize.ten_to_forty_nine,
    display: UserCompanySizeLabel.ten_to_forty_nine
  },
  {
    id: UserCompanySize.fifty_to_two_hundred_forty_nine,
    display: UserCompanySizeLabel.fifty_to_two_hundred_forty_nine
  },
  {
    id: UserCompanySize.two_hundred_fifty_to_one_thousand,
    display: UserCompanySizeLabel.two_hundred_fifty_to_one_thousand
  },
  {
    id: UserCompanySize.one_thousand_plus,
    display: UserCompanySizeLabel.one_thousand_plus
  }
]

/**
 * UserWork refers to the user's company type
 */
const userWorkOptions = Object.entries(UserWorkLabelOnboardingOptions).map(
  ([key, value]) => ({
    id: UserWork[key as keyof typeof UserWork],
    display: value
  })
)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiSelect-select': {
        backgroundColor: 'transparent'
      }
    },
    modalContentLeftHalf: {
      width: '60%',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    modalContentRightHalf: {
      width: '40%',
      height: '660px',
      backgroundColor: palette.primary[25],
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    modalHeader: {
      padding: theme.spacing(4.5, 3, 1.5, 3)
    },
    modalBody: {
      padding: theme.spacing(1.5, 3, 1.5, 3)
    },
    modalAction: {
      padding: theme.spacing(1.5, 3, 4.5, 3)
    },
    disabledArrow: {
      '& path': {
        stroke: palette.gray[400]
      }
    },
    transparentButton: {
      padding: '10px 6px',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    ellipseBlue: {
      height: '10px',
      width: '10px',
      '& circle': {
        fill: palette.primary[500]
      }
    },
    ellipseGrey: {
      height: '10px',
      width: '10px',
      '& circle': {
        fill: palette.gray[300]
      }
    }
  })
)

interface CompanyUrlFieldProps {
  companyUrl: string
  setCompanyUrl: (value: string) => void
}
const CompanyUrlField: React.FC<CompanyUrlFieldProps> = React.memo((props) => {
  const { companyUrl, setCompanyUrl } = props
  return (
    <GenericLinkField
      link={companyUrl}
      setLink={setCompanyUrl}
      labelText=""
      placeholder="https://"
      showError={false}
      inputProps={{
        maxLength: 100
      }}
    />
  )
})

export const OnboardingModal = (props: OnboardingModalProps) => {
  const { open } = props
  const location = useLocation()
  const { pathname } = location
  const classes = useStyles()
  const [heardAboutUsChannels, setHeardAboutUsChannels] = useState<
    UserHeardAboutUsChannel | undefined
  >()
  const [freeFormInputChannels, setFreeFormInputChannels] = useState<
    string | undefined
  >()
  const [freeFormUserWork, setFreeFormUserWork] = useState<string | undefined>()
  const [selectedUserRoles, setSelectedUserRoles] = useState<string[]>([])
  const [freeFormUserRole, setFreeFormUserRole] = useState<string | undefined>()
  const [companyType, setCompanyType] = useState<string | undefined>()
  const [howManyEmployees, setHowManyEmployees] = useState<string | undefined>()
  const [companyUrl, setCompanyUrl] = useState<string | undefined>()
  const requireWrittenInputForChannel =
    heardAboutUsChannels === UserHeardAboutUsChannel.others
  const requireWrittenInputForCompanyType = companyType === UserWork.others
  const requireWrittenInputForUserRole = selectedUserRoles.includes(
    UserRole.others
  )

  /**
   * This is used to determine if the user should skip
   * the company-related questions and move to page 3.
   * For now, this applies to education only.
   */
  const shouldSkipCompanySection = useMemo(
    () => selectedUserRoles.includes(UserRole.school),
    [selectedUserRoles]
  )

  const isUserRoleFilled = useMemo(
    () =>
      selectedUserRoles.length !== 0 &&
      (!requireWrittenInputForUserRole || !!freeFormUserRole),
    [selectedUserRoles, requireWrittenInputForUserRole, freeFormUserRole]
  )

  const isCompanyTypeFilled = useMemo(
    () =>
      companyType && (!requireWrittenInputForCompanyType || !!freeFormUserWork),
    [requireWrittenInputForCompanyType, freeFormUserWork, companyType]
  )

  const isHeardAboutUsChannelsFilled = useMemo(
    () =>
      !!heardAboutUsChannels &&
      (!requireWrittenInputForChannel || !!freeFormInputChannels),
    [heardAboutUsChannels, requireWrittenInputForChannel, freeFormInputChannels]
  )

  /**
   * This function is used to check if the Company URL is valid
   * Company URL is optional, so we return true if it is empty
   */

  /**
   * This is used to determine if the form is submittable
   * Two cases:
   * 1. If the user is a not under a company (education), we skip the company-related questions
   * 2. If the user may be attached to a company, we require all fields (including company-related) to be filled
   */
  const isFormSubmittable = useMemo(() => {
    if (shouldSkipCompanySection) {
      return isUserRoleFilled && isHeardAboutUsChannelsFilled
    } else {
      return (
        isUserRoleFilled &&
        isCompanyTypeFilled &&
        !!howManyEmployees &&
        isHeardAboutUsChannelsFilled
      )
    }
  }, [
    isUserRoleFilled,
    isCompanyTypeFilled,
    howManyEmployees,
    isHeardAboutUsChannelsFilled,
    shouldSkipCompanySection
  ])

  const isFirstPageCompleted = useMemo(() => isUserRoleFilled, [
    isUserRoleFilled
  ])

  const { onboarding_info, onOnboardingStepSubmit } = useOnboardingContext()
  const theme = useTheme()
  const history = useHistory()

  const [redirectURL, setRedirectURL] = useLocalStorage('redirectURL', '')

  const [
    { loading: isOnboardingSubmitLoading },
    onSubmitStep
  ] = useAsyncFn(onOnboardingStepSubmit, [onOnboardingStepSubmit])

  const getIsUserRoleValueSelected = useCallback(
    (userRoleValue: string) =>
      selectedUserRoles?.includes(userRoleValue) ?? false,
    [selectedUserRoles]
  )

  /**
   * This function is used to handle the multi-select for user work.
   * When the user selects 3 options, the next selection will deselect the first option
   */
  const handleMultiSelectUserRole = useCallback(
    (userRoleValue: string) => () => {
      setSelectedUserRoles((prev) => {
        const isValuePresent = prev?.includes(userRoleValue)
        if (isValuePresent) {
          // deselect the value
          return prev.filter((work) => work !== userRoleValue)
        } else {
          // We only allow up to 3 options to be selected
          if (prev?.length === 3) {
            // remove the first element and add the new value
            return [...prev?.slice(1), userRoleValue]
          }
          return [...(prev ?? []), userRoleValue]
        }
      })
    },
    []
  )

  const { onSuggestContentIdeas } = useContentRecommendation()

  const createRecommendation = useCallback(
    async (
      topicRecommendationCollectionType: TopicRecommendationCollectionType,
      url?: string
    ) => {
      const tools = await apiGetRecommendedTools()
      if (url) {
        await onSuggestContentIdeas(
          url,
          tools,
          TopicRecommendationTriggerSource.onboarding,
          topicRecommendationCollectionType
        )
      }
    },
    [onSuggestContentIdeas]
  )

  const selectedUserRolesButtons = useMemo(
    () =>
      userRole.map((userRole) => {
        const userRoleId = userRole.id
        const isActive = getIsUserRoleValueSelected(userRoleId)
        const key = `${userRoleId}-${isActive}`
        return (
          <ToggleButton
            key={key}
            isActive={isActive}
            onClick={handleMultiSelectUserRole(userRoleId)}
            children={userRole.display}
            style={{ justifyContent: 'flex-start' }}
          />
        )
      }),
    [handleMultiSelectUserRole, getIsUserRoleValueSelected]
  )

  const companyTypeButtons = useMemo(
    () =>
      userWorkOptions.map((userWorkOptions) => {
        const userWorkValue = userWorkOptions.id
        return (
          <ToggleButton
            isActive={companyType === userWorkValue}
            onClick={() => setCompanyType(userWorkValue)}
            children={userWorkOptions.display}
          />
        )
      }),
    [setCompanyType, companyType]
  )

  const employeeCountButtons = useMemo(
    () =>
      employeeCount.map((employeeCount) => {
        const employeeCountValue = employeeCount.id
        return (
          <ToggleButton
            isActive={employeeCountValue === howManyEmployees}
            onClick={() => {
              setHowManyEmployees(employeeCountValue)
            }}
            children={employeeCount.display}
          />
        )
      }),
    [howManyEmployees]
  )

  const heardAboutUsChannelsButtons = useMemo(
    () =>
      channels.map((channel) => (
        <MenuItem key={channel.id} value={channel.id}>
          {channel.display}
        </MenuItem>
      )),
    []
  )

  const [currentPage, setCurrentPage] = useState<number>(1)

  const firstPageFormData = useMemo(
    () => ({
      user_role: selectedUserRoles,
      user_role_others: selectedUserRoles.includes(UserRole.others)
        ? freeFormUserRole
        : undefined,
      user_role_options: userRole.map((item) => UserRoleLabel[item.id])
    }),
    [selectedUserRoles, freeFormUserRole]
  )

  const secondPageFormData = useMemo(
    () => ({
      user_work_others:
        companyType === UserWork.others ? freeFormUserWork : undefined,
      user_company_size: howManyEmployees,
      user_work: companyType,
      user_website: companyUrl || undefined,
      user_work_options: userWorkOptions.map((item) => UserWorkLabel[item.id]),
      user_heard_about_us_channel:
        heardAboutUsChannels === UserHeardAboutUsChannel.others
          ? freeFormInputChannels
          : heardAboutUsChannels
    }),
    [
      companyType,
      freeFormUserWork,
      howManyEmployees,
      companyUrl,
      freeFormInputChannels,
      heardAboutUsChannels
    ]
  )

  const handleNextPage = useCallback(() => {
    if (currentPage === 1) {
      upsertUserOnboardInfoForm(
        {
          ...firstPageFormData,
          page_at: OnboardingPageAt.second_page
        },
        false
      )
    } else if (currentPage === 2) {
      upsertUserOnboardInfoForm(
        {
          ...firstPageFormData,
          ...secondPageFormData,
          page_at: OnboardingPageAt.third_page
        },
        false
      )
    }
    setCurrentPage(currentPage + 1)
  }, [currentPage, firstPageFormData, secondPageFormData])

  /**
   * This function is used to skip to page 3
   * from page 1 when the user selects Education.
   * This is because the user does not need to fill in company-related questions
   */
  const handleNavigateToPage = useCallback(
    (pageNumber: number) => () => {
      if (pageNumber === 2) {
        if (!isFirstPageCompleted) {
          return
        }
      }
      setCurrentPage(pageNumber)
    },
    [isFirstPageCompleted]
  )

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault()
      const onboardingSubmissionForm = {
        has_completed: true,
        ...firstPageFormData,
        ...secondPageFormData,
        path_name: pathname
      }
      createRecommendation(
        TopicRecommendationCollectionType.content_feed,
        companyUrl
      )
      apiCreateContentRecommendation(
        companyUrl ?? '',
        [ContentGenerationType.blog_article],
        TopicRecommendationTriggerSource.onboarding,
        TopicRecommendationCollectionType.blog_content_feed
      )
      onSubmitStep(onboardingSubmissionForm, true)
      if (redirectURL) {
        setRedirectURL('')
        const activeFeature = onboarding_info?.selected_features
          ? Object.entries(onboarding_info.selected_features).find(
              ([feature, value]) =>
                value && SniperLinkFeaturesToUrlSlugMap[feature as AppFeatures]
            )?.[0]
          : undefined
        if (!activeFeature) {
          history.push(redirectURL)
        }
      }
    },
    [
      createRecommendation,
      setRedirectURL,
      onSubmitStep,
      firstPageFormData,
      secondPageFormData,
      pathname,
      redirectURL,
      companyUrl,
      onboarding_info,
      history
    ]
  )

  const pageStatusIcons = useMemo(
    () =>
      Array.from({ length: 2 }, (_, index) => {
        const pageNumber = index + 1
        const isActive = pageNumber <= currentPage
        return (
          <EllipseIcon
            className={isActive ? classes.ellipseBlue : classes.ellipseGrey}
            key={pageNumber}
            onClick={handleNavigateToPage(pageNumber)}
          />
        )
      }),
    [
      classes.ellipseBlue,
      classes.ellipseGrey,
      currentPage,
      handleNavigateToPage
    ]
  )

  const pageOne = useMemo(() => {
    const handleValidateAndNextPage = () => {
      if (isFirstPageCompleted) {
        handleNextPage()
      }
    }
    return (
      <Stack
        direction="column"
        className={classes.modalContentLeftHalf}
        spacing={1.75}
      >
        <ModalHeader className={classes.modalHeader}>
          <Stack spacing={1.5}>
            <Stack direction="row" spacing={0.75}>
              {pageStatusIcons}
            </Stack>
            <Stack spacing={1}>
              <Typography
                variant="heading2"
                style={{ color: palette.gray[900] }}
              >
                Welcome! Let's personalize your experience 🚀
              </Typography>
            </Stack>
          </Stack>
        </ModalHeader>
        <ModalContent className={classes.modalBody}>
          <Stack spacing={3}>
            <Stack spacing={0.75}>
              <Typography
                variant="paragraph1Bold"
                style={{ color: palette.gray[700] }}
              >
                What do you hope to achieve with Hypotenuse AI?
              </Typography>
              <Typography
                variant="paragraph1Reg"
                style={{ fontSize: 14, color: palette.gray[500] }}
              >
                Select more than one if applicable
              </Typography>
            </Stack>
            <Stack spacing={1}>
              {selectedUserRolesButtons}
              {requireWrittenInputForUserRole && (
                <TextField
                  placeholder="Enter your purpose"
                  variant="outlined"
                  value={freeFormUserRole}
                  onChange={(e) => setFreeFormUserRole(e.target.value)}
                  size="small"
                  inputProps={{
                    style: {
                      padding: `${theme.spacing(1.4)} ${theme.spacing(1.75)}`
                    },
                    maxLength: 100
                  }}
                />
              )}
            </Stack>
          </Stack>
        </ModalContent>
        <ModalActions className={classes.modalAction}>
          <Button
            onClick={handleValidateAndNextPage}
            className={classes.transparentButton}
            disabled={!isFirstPageCompleted}
          >
            <Stack
              direction="row"
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="paragraph1Bold"
                style={{
                  color: isFirstPageCompleted
                    ? palette.gray[700]
                    : palette.gray[400]
                }}
              >
                Next
              </Typography>
              <ArrowRight
                style={{ height: '20px', width: '20px' }}
                className={clsx({
                  [classes.disabledArrow]: !isFirstPageCompleted
                })}
              />
            </Stack>
          </Button>
        </ModalActions>
      </Stack>
    )
  }, [
    selectedUserRolesButtons,
    theme,
    freeFormUserRole,
    requireWrittenInputForUserRole,
    classes.modalAction,
    classes.modalBody,
    classes.modalContentLeftHalf,
    classes.modalHeader,
    classes.transparentButton,
    classes.disabledArrow,
    pageStatusIcons,
    isFirstPageCompleted,
    handleNextPage
  ])

  const pageTwo = useMemo(() => {
    return (
      <Stack
        direction="column"
        className={classes.modalContentLeftHalf}
        spacing={1.75}
      >
        <ModalHeader className={classes.modalHeader}>
          <Stack spacing={1.5}>
            <Stack direction="row" spacing={0.75}>
              {pageStatusIcons}
            </Stack>
            <Stack spacing={1}>
              <Typography
                variant="heading2"
                style={{ color: palette.gray[900] }}
              >
                Set up your profile for Hypo AI to optimize your content 🧠
              </Typography>
              <Typography
                variant="paragraph1Reg"
                style={{ fontSize: 14, color: palette.gray[500] }}
              >
                Don’t worry, you can always change your options later
              </Typography>
            </Stack>
          </Stack>
        </ModalHeader>
        <ModalContent className={classes.modalBody}>
          <Stack spacing={3}>
            <Stack spacing={2}>
              <Typography
                variant="paragraph1Bold"
                style={{ color: palette.gray[700] }}
              >
                What best describes your company?
              </Typography>
              <Stack
                spacing={1}
                flexDirection={'row'}
                alignItems={'center'}
                flexWrap={'wrap'}
              >
                {companyTypeButtons}
                {requireWrittenInputForCompanyType && (
                  <TextField
                    placeholder="Enter your company type"
                    variant="outlined"
                    value={freeFormUserWork}
                    onChange={(e) => setFreeFormUserWork(e.target.value)}
                    size="small"
                    inputProps={{
                      style: {
                        padding: `${theme.spacing(1.4)} ${theme.spacing(1.75)}`
                      },
                      maxLength: 100
                    }}
                  />
                )}
              </Stack>
            </Stack>
            <Stack spacing={2}>
              <Typography
                variant="paragraph1Bold"
                style={{ color: palette.gray[700] }}
              >
                How many employees are in your company?
              </Typography>
              <Stack
                spacing={1}
                flexDirection={'row'}
                alignItems={'center'}
                flexWrap={'wrap'}
              >
                {employeeCountButtons}
              </Stack>
            </Stack>
            <Stack spacing={1}>
              <Typography
                variant="paragraph1Bold"
                style={{ color: palette.gray[700] }}
              >
                How did you hear about us?
              </Typography>
              <TextField
                select
                variant="outlined"
                label="From..."
                onChange={(e) =>
                  setHeardAboutUsChannels(
                    e.target.value as UserHeardAboutUsChannel
                  )
                }
                value={heardAboutUsChannels}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left'
                    },
                    getContentAnchorEl: null
                  }
                }}
                size="small"
              >
                {heardAboutUsChannelsButtons}
              </TextField>
              {requireWrittenInputForChannel && (
                <TextField
                  placeholder="From where?"
                  variant="outlined"
                  value={freeFormInputChannels}
                  onChange={(e) => setFreeFormInputChannels(e.target.value)}
                  size="small"
                  inputProps={{
                    style: {
                      padding: `${theme.spacing(1.4)} ${theme.spacing(1.75)}`
                    },
                    maxLength: 100
                  }}
                />
              )}
            </Stack>
            <Stack spacing={2}>
              <Stack spacing={0.75}>
                <Typography
                  variant="paragraph1Bold"
                  style={{ color: palette.gray[700] }}
                >
                  Your website URL (optional)
                </Typography>
                <Typography
                  variant="paragraph1Reg"
                  style={{ fontSize: 14, color: palette.gray[500] }}
                >
                  Our AI will optimize content based on your company
                </Typography>
              </Stack>
              <CompanyUrlField
                companyUrl={companyUrl || ''}
                setCompanyUrl={setCompanyUrl}
              />
            </Stack>
          </Stack>
        </ModalContent>
        <ModalActions className={classes.modalAction}>
          <PrimaryButton
            style={{
              width: '100%',
              fontWeight: 600,
              padding: `${theme.spacing(1.25)} ${theme.spacing(1.75)}`
            }}
            onClick={handleSubmit}
            disabled={!isFormSubmittable || isOnboardingSubmitLoading}
          >
            {isOnboardingSubmitLoading ? (
              <CircularProgress color="inherit" size={'1.5rem'} />
            ) : (
              <>
                <StarSvg style={{ marginRight: theme.spacing(0.8) }} />
                Start creating
              </>
            )}
          </PrimaryButton>
        </ModalActions>
      </Stack>
    )
  }, [
    employeeCountButtons,
    companyTypeButtons,
    freeFormUserWork,
    theme,
    requireWrittenInputForCompanyType,
    companyUrl,
    classes.modalAction,
    classes.modalBody,
    classes.modalContentLeftHalf,
    classes.modalHeader,
    pageStatusIcons,
    freeFormInputChannels,
    heardAboutUsChannels,
    heardAboutUsChannelsButtons,
    requireWrittenInputForChannel,
    handleSubmit,
    isFormSubmittable,
    isOnboardingSubmitLoading
  ])

  const pages = [pageOne, pageTwo]

  return (
    <BaseModal
      open={open}
      spacing={0}
      padding={0}
      maxWidth={false}
      PaperProps={{ style: { maxWidth: '820px' } }}
    >
      <Stack flexDirection={'row'}>
        {pages[currentPage - 1]}
        <ModalContent className={classes.modalContentRightHalf}>
          <img
            src={PattyOnboarding}
            style={{ margin: 'auto 0', objectFit: 'contain' }}
            alt="patty in a superhero pose"
          />
        </ModalContent>
      </Stack>
    </BaseModal>
  )
}
