import React, { HTMLProps } from 'react'

import palette from '../atoms/Colors'
import { Box, createStyles, makeStyles, useTheme } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = (backgroundColor?: string) =>
  makeStyles(() => {
    return createStyles({
      tabPanelContainer: {
        backgroundColor: backgroundColor ? backgroundColor : 'white',
        flexGrow: 1
      },
      tabPanelBorder: {
        border: `1px solid ${palette.gray[200]}`
      }
    })
  })

interface GenericTabPanelProps extends HTMLProps<HTMLDivElement> {
  /**
   * Unique ID of the tab panel (and corresponding tab)
   */
  tabId: string
  /**
   * Unique ID of the currently selected tab
   */
  currentTabId: string
  /**
   * Background color of the tab panel
   */
  backgroundColor?: string
  /**
   * Determines whether the tab panel has a border
   */
  hasBorder?: boolean
  /**
   * Determines whether to disable shadow on the tab panel
   */
  disableShadow?: boolean
  /**
   * Props to be passed to the div element
   */
  divProps?: React.HTMLProps<HTMLDivElement>
}

const GenericTabPanel: React.FC<GenericTabPanelProps> = React.memo((props) => {
  const {
    children,
    tabId,
    currentTabId,
    backgroundColor,
    hasBorder = false,
    disableShadow = false,
    ...other
  } = props
  const classes = useStyles(backgroundColor)()
  const theme = useTheme()

  return (
    <div
      role="tabpanel"
      hidden={tabId !== currentTabId}
      id={`tab-panel-${tabId}`}
      aria-labelledby={`tab-panel-${tabId}`}
      className={clsx(classes.tabPanelContainer, {
        [classes.tabPanelBorder]: hasBorder
      })}
      {...other}
    >
      {tabId === currentTabId && (
        <Box
          boxShadow={disableShadow ? 'none' : theme.shadows[1]}
          borderRadius={0}
          style={{ height: '100%' }}
        >
          {children}
        </Box>
      )}
    </div>
  )
})

export default GenericTabPanel
