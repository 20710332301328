import React, { useState } from 'react'

import useSWR from 'swr'

import Stack from '@hypotenuse/common/src/components/atoms/Stack'

import { apiFetchPlanTierListObject } from '@hypotenuse/common/src/api/PlanTier'
import { useUserContext } from '@hypotenuse/common/src/utils/context/UserContext'

import EcommerceCTAHeader from '../main/ecommerce/EcommerceCTAHeader'
import {
  EcommerceCardCTAModal,
  EcommerceContentData
} from '../main/ecommerce/EcommerceCardCTAModal'
import {
  AssetWorkflowsFeatureGroup,
  ContentWorkflowsFeatureGroup,
  TrackingFeatureGroup
} from '../main/ecommerce/EcommerceFeaturesGroup'

const EcommercePage: React.FC = () => {
  const [
    isShowingEcommerceCardCTAModal,
    setIsShowingEcommerceCardCTAModal
  ] = useState(false)

  const [
    ecommerceContentData,
    setEcommerceContentData
  ] = useState<EcommerceContentData>({
    title: '',
    featureBullets: [],
    elementIdCTA: ''
  })

  const { user } = useUserContext()
  const userId = user.username
  const planTierListObject = useSWR(
    'planTierListObject',
    apiFetchPlanTierListObject
  ).data
  const planListId = planTierListObject?.plan_list_id || ''

  return (
    <>
      <EcommerceCardCTAModal
        open={isShowingEcommerceCardCTAModal}
        setIsShowingEcommerceCardCTAModal={setIsShowingEcommerceCardCTAModal}
        userId={userId}
        planListId={planListId}
        ecommerceContentData={ecommerceContentData}
      />
      <Stack>
        <EcommerceCTAHeader userId={userId} planListId={planListId} />
        <Stack direction="column" spacing={1.5} marginBottom={3}>
          <ContentWorkflowsFeatureGroup
            setIsShowingEcommerceCardCTAModal={
              setIsShowingEcommerceCardCTAModal
            }
            setEcommerceContentData={setEcommerceContentData}
          />
          <AssetWorkflowsFeatureGroup
            setIsShowingEcommerceCardCTAModal={
              setIsShowingEcommerceCardCTAModal
            }
            setEcommerceContentData={setEcommerceContentData}
          />
          <TrackingFeatureGroup
            setIsShowingEcommerceCardCTAModal={
              setIsShowingEcommerceCardCTAModal
            }
            setEcommerceContentData={setEcommerceContentData}
          />
        </Stack>
      </Stack>
    </>
  )
}

export default EcommercePage
