import { useCallback, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { apiCreateBlog } from '@hypotenuse/common/src/api/Blog'
import { useLocalStorage } from '@hypotenuse/common/src/hooks/useLocalStorage'
import { SniperLinkFeaturesToUrlSlugMap } from '@hypotenuse/common/src/utils/Interfaces'
import snackbar from '@hypotenuse/common/src/utils/Snackbar'

import { useOnboardingContext } from '../../utils/context/OnboardingContext'
import { AppFeatures } from '../utils/Interfaces'

export default function OnboardingRedirect() {
  const history = useHistory()
  const { onboarding_info } = useOnboardingContext()
  const [redirectURL, setRedirectURL] = useLocalStorage('redirectURL', '')

  const snipedFeatures = onboarding_info?.selected_features
  const [sniperFeaturesUrl, setSniperFeaturesUrl] = useState<
    string | undefined
  >()

  const createSniperLinkUrl = useCallback(async () => {
    if (snipedFeatures) {
      const activeFeature = Object.entries(snipedFeatures).find(
        ([feature, value]) =>
          value && SniperLinkFeaturesToUrlSlugMap[feature as AppFeatures]
      )
      if (!activeFeature) return '/home'
      const feature = activeFeature[0]
      if (feature === AppFeatures.blog_feature) {
        try {
          const blog = await apiCreateBlog()
          setSniperFeaturesUrl(`/blog-post?blogId=${blog.blogId}`)
        } catch (err) {
          snackbar.show(
            'Failed to create a new blog. Directing you to blogs!',
            { variant: 'error' }
          )
          history.push('/documents')
        }
      } else {
        setSniperFeaturesUrl(
          SniperLinkFeaturesToUrlSlugMap[feature as AppFeatures]
        )
      }
    }
  }, [history, snipedFeatures])

  const handleVerification = useCallback(async () => {
    await createSniperLinkUrl()
    if (sniperFeaturesUrl) {
      history.push(sniperFeaturesUrl)
    } else {
      history.push('/home')
    }
  }, [createSniperLinkUrl, history, sniperFeaturesUrl])

  const redirectUser = useCallback(() => {
    if (onboarding_info?.has_completed) {
      setRedirectURL('')
      if (!sniperFeaturesUrl) {
        history.push(redirectURL || '/home')
      }
    }
  }, [
    history,
    onboarding_info?.has_completed,
    redirectURL,
    setRedirectURL,
    sniperFeaturesUrl
  ])

  useEffect(() => {
    redirectUser()
  }, [redirectUser])

  useEffect(() => {
    handleVerification()
  }, [handleVerification])

  return null
}
