import React, { useMemo } from 'react'

import { Typography, palette } from '../../atoms'
import Stack from '../atoms/Stack'
import {
  IconButton,
  MenuItem,
  Select,
  createStyles,
  makeStyles,
  useTheme
} from '@material-ui/core'
import clsx from 'clsx'

import { ReactComponent as PlusIcon } from '@hypotenuse/common/src/assets/plus.svg'

import { ECommercePlatforms, ProductSet } from './interfaces'

import amazonIcon from '../../assets/amazon-icon.svg'
import { ReactComponent as EditIcon } from '../../assets/edit-05.svg'
import walmartIcon from '../../assets/walmart-icon.svg'

import { useProductSetModalStore } from './store'

const useStyles = makeStyles(() => {
  return createStyles({
    iconButtonNoHover: {
      '&:hover': {
        backgroundColor: 'unset'
      }
    }
  })
})

export interface Option {
  name: string
  icon: string
  platform: string
  value: string
  showName?: boolean
}

export const MenuOptionDisplay: React.FC<Option> = React.memo((props) => {
  const { name, icon, platform, showName = true } = props

  return (
    // width based on figma design
    <Stack spacing={0.5} mr={2} width="348px">
      <Stack spacing={1} direction="row">
        <img
          src={icon}
          alt={`${platform} icon`}
          style={{
            width: '20px',
            height: '20px'
          }}
        />
        <Typography
          variant="paragraph2Reg"
          style={{
            textTransform: 'capitalize'
          }}
        >
          {platform}
        </Typography>
      </Stack>
      {showName && <Typography variant="paragraph1Bold">{name}</Typography>}
    </Stack>
  )
})

interface Props {
  productSets: ProductSet[]
  selectedProductSetId?: string
  setSelectedProductSetId: (productSetId: string) => void
}

const ProductSetSelectionDropdown: React.FC<Props> = React.memo((props) => {
  const { productSets, selectedProductSetId, setSelectedProductSetId } = props

  const showAddEditModal = useProductSetModalStore((store) => store.show)

  const theme = useTheme()
  const classes = useStyles()

  const options: Option[] = useMemo(() => {
    return productSets.map((productSet) => ({
      name: productSet.name,
      icon:
        productSet.platform === ECommercePlatforms.WALMART
          ? walmartIcon
          : amazonIcon,
      platform: productSet.platform,
      value: productSet.id
    }))
  }, [productSets])

  const addProductSetOptionComponent = useMemo(() => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        mr={2}
        width="348px"
      >
        <PlusIcon
          style={{
            width: theme.spacing(3),
            height: theme.spacing(3),
            stroke: palette.gray[500],
            strokeWidth: '1.3px'
          }}
        />
        <Typography
          variant="paragraph1Bold"
          style={{
            color: palette.gray[500]
          }}
        >
          Add new product set
        </Typography>
      </Stack>
    )
  }, [theme])

  return (
    <Select
      autoWidth
      value={selectedProductSetId || 'empty-state'}
      variant="outlined"
      margin="dense"
      style={{
        backgroundColor: palette.white
      }}
      onChange={(e) => {
        if (e.target.value === 'add') {
          showAddEditModal()
          return
        }
        setSelectedProductSetId(e.target.value as string)
      }}
      renderValue={(value) => {
        const selectedOption = options.find((option) => option.value === value)
        if (!selectedOption) {
          return addProductSetOptionComponent
        }
        return (
          <MenuOptionDisplay
            name={selectedOption.name}
            icon={selectedOption.icon}
            platform={selectedOption.platform}
            value={selectedOption.value}
          />
        )
      }}
    >
      {options.map((option) => (
        <MenuItem value={option.value}>
          <Stack
            direction="row"
            style={{
              width: '348px' // value based on figma design
            }}
          >
            <MenuOptionDisplay
              name={option.name}
              icon={option.icon}
              platform={option.platform}
              value={option.value}
            />
            <IconButton
              size="small"
              className={clsx(classes.iconButtonNoHover)}
              onClick={(e) => {
                e.stopPropagation()
                const productSet = productSets.find(
                  (productSet) => productSet.id === option.value
                )
                showAddEditModal(productSet, true)
              }}
            >
              <EditIcon
                style={{
                  stroke: palette.gray[400],
                  strokeWidth: '1.3px',
                  width: theme.spacing(2),
                  height: theme.spacing(2)
                }}
              />
            </IconButton>
          </Stack>
        </MenuItem>
      ))}
      <MenuItem value="add">{addProductSetOptionComponent}</MenuItem>
    </Select>
  )
})

export default ProductSetSelectionDropdown
