import { apiClient } from '../utils/ApiClient'
import {
  CountryDetail,
  GlobalAppData,
  TranslationConfig,
  TranslationConfigUpdateForm,
  TranslationLanguages
} from '../utils/Interfaces'

export const getGlobalAppData = async (): Promise<GlobalAppData> => {
  const resp = await apiClient.get('/global_app_data')
  return resp.data
}
export const apiGetCountryList = async (): Promise<CountryDetail[]> => {
  const resp = await apiClient.get('/country-list')
  return resp.data
}
export const fetchAvailableTranslationLanguages = async (): Promise<TranslationLanguages> => {
  const resp = await apiClient.get<TranslationLanguages>(
    '/translation_languages'
  )
  return resp.data
}
export const fetchTranslationConfig = () => {
  return apiClient
    .get<TranslationConfig>('/translation_config')
    .then((resp) => resp.data)
}
export const handleTranslationConfigChange = (
  updateForm: TranslationConfigUpdateForm
) => {
  return apiClient.put<void>('/translation_config', null, {
    params: updateForm
  })
}
