import React, { useCallback } from 'react'

import { X } from '@styled-icons/feather/X'
import { KeyedMutator } from 'swr'

import { Typography } from '../../atoms'
import Stack from '../atoms/Stack'
import { Box, IconButton } from '@material-ui/core'

import {
  apiAddKeywordsToProductSet,
  apiUpdateProductSetById
} from '../../api/Ecommerce'

import { ProductSetInResponse } from './interfaces'

import BaseModal, {
  ModalActions,
  ModalContent,
  ModalTitle,
  PrimaryButton
} from '../BaseModal'
import MuiFormInputTagsList from '../MuiFormInputTagsList'

interface Props {
  open: boolean
  onClose: () => void
  productSetId: string
  platform: string
  selectedKeywordId?: string
  mutateProductSet?: KeyedMutator<ProductSetInResponse>
}

const KeywordsInputModal = React.memo((props: Props) => {
  const { open, onClose } = props

  const { productSetId, platform, selectedKeywordId, mutateProductSet } = props

  const [keywordInput, setKeywordInput] = React.useState('')
  const [currentKeywords, setCurrentKeywords] = React.useState<string[]>([])

  const handleSaveKeywords = useCallback(async () => {
    const keywords = await apiAddKeywordsToProductSet(
      productSetId,
      currentKeywords,
      platform
    )
    if (!selectedKeywordId) {
      await apiUpdateProductSetById({
        productSetId: productSetId,
        selectedKeywordId: keywords[0].id
      })
    }
    setCurrentKeywords([])
    mutateProductSet &&
      mutateProductSet(
        (prev) => {
          if (!prev) return prev
          return {
            ...prev,
            keywords: keywords
          }
        },
        { revalidate: false }
      )
    onClose()
  }, [
    productSetId,
    currentKeywords,
    platform,
    selectedKeywordId,
    mutateProductSet,
    onClose
  ])

  return (
    <BaseModal open={open} onClose={onClose}>
      <ModalTitle>
        <Stack justifyContent="space-between" flexDirection="row">
          <Typography variant="paragraph1Bold">Add keyword</Typography>
          <IconButton size="small" onClick={onClose}>
            <X size={20} strokeWidth={2} />
          </IconButton>
        </Stack>
      </ModalTitle>
      <ModalContent>
        <Box width="100%">
          <MuiFormInputTagsList
            id="product-tracking-base-modal-input"
            testId="product-tracking-base-modal-input"
            inputLabel=""
            inputPlaceholder={'best ice cream recipe'}
            newValue={keywordInput}
            setNewValue={(value) => {
              setKeywordInput(value)
            }}
            tagValues={currentKeywords}
            setTagValues={setCurrentKeywords}
            customKeyPress=","
            formInputStyle={{
              backgroundColor: 'white'
            }}
            keywordContainerStyle={{
              height: '64px',
              overflow: 'auto'
            }}
            textFieldSize="small"
          />
        </Box>
      </ModalContent>
      <ModalActions>
        <PrimaryButton onClick={handleSaveKeywords}>Save</PrimaryButton>
      </ModalActions>
    </BaseModal>
  )
})

export default KeywordsInputModal
