import React, { useCallback } from 'react'

import { ModalActions, ModalContent, ModalHeader } from 'semantic-ui-react'

import { Typography, palette } from '@hypotenuse/common/src/atoms'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import { IconButton, Theme, createStyles, makeStyles } from '@material-ui/core'

import { handleContactUs } from '@hypotenuse/common/src/analytics/utils'
import { ReactComponent as CheckTapered } from '@hypotenuse/common/src/assets/check_benefit.svg'
import { ReactComponent as CloseIcon } from '@hypotenuse/common/src/assets/x-close.svg'
import BaseModal from '@hypotenuse/common/src/components/BaseModal'
import EnhancedButton from '@hypotenuse/common/src/components/EnhancedButton'

import theme from '../../../utils/Theme'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalHeader: {
      padding: theme.spacing(3),
      borderBottom: `1px solid ${palette.gray[300]}`,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    modalContent: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    modalFooter: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(3),
      paddingTop: theme.spacing(2),
      borderTop: `1px solid ${palette.gray[300]}`
    },
    checkIconPremium: {
      '& path': {
        stroke: palette.blue[600],
        strokeLinecap: 'round',
        strokeLinejoin: 'round'
      }
    }
  })
)

interface EcommercePremiumKeyPointProps {
  keyPoint: string
}

const EcommercePremiumKeyPoint = React.memo(
  (props: EcommercePremiumKeyPointProps) => {
    const { keyPoint } = props
    const classes = useStyles()
    return (
      <Stack spacing={1} flexDirection="row" alignItems="center">
        <Stack
          style={{
            width: '16px',
            height: '16px',
            background: palette.blue[50],
            borderRadius: '15px',
            flexShrink: 0
          }}
          justifyContent="center"
          alignItems="center"
        >
          <CheckTapered
            className={classes.checkIconPremium}
            style={{
              width: 8,
              height: 8
            }}
          />
        </Stack>
        <Typography
          style={{
            color: palette.gray[700],
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px'
          }}
        >
          {keyPoint}
        </Typography>
      </Stack>
    )
  }
)
export interface EcommerceContentData {
  title: string
  featureBullets: string[]
  elementIdCTA: string
}

interface EcommerceCardCTAModalProps {
  open: boolean
  setIsShowingEcommerceCardCTAModal: (value: boolean) => void
  userId: string
  planListId: string
  ecommerceContentData: EcommerceContentData
}

export const EcommerceCardCTAModal = (props: EcommerceCardCTAModalProps) => {
  const {
    open,
    setIsShowingEcommerceCardCTAModal,
    userId,
    planListId,
    ecommerceContentData
  } = props

  const { title, featureBullets, elementIdCTA } = ecommerceContentData

  const classes = useStyles()

  const onModalClose = useCallback(() => {
    setIsShowingEcommerceCardCTAModal(false)
  }, [setIsShowingEcommerceCardCTAModal])

  return (
    <BaseModal
      open={open}
      onClose={onModalClose}
      maxWidth={false}
      PaperProps={{ style: { maxWidth: '660px' } }}
      style={{ borderRadius: theme.spacing(1.5) }}
      padding={0}
      spacing={0}
    >
      <ModalHeader
        className={classes.modalHeader}
        justifyContent="space-between"
      >
        <Typography
          variant="paragraph1Bold"
          style={{ color: palette.gray[900] }}
        >
          {title}
        </Typography>
        <IconButton size="small" onClick={onModalClose}>
          <CloseIcon />
        </IconButton>
      </ModalHeader>
      <ModalContent className={classes.modalContent}>
        <Stack direction="column" paddingY={3} paddingX={2} spacing={1}>
          {featureBullets.map((featureBullet) => (
            <EcommercePremiumKeyPoint
              keyPoint={featureBullet}
              key={featureBullet}
            />
          ))}
        </Stack>
      </ModalContent>
      <ModalActions className={classes.modalFooter}>
        <EnhancedButton
          variant="contained"
          color="primary"
          style={{
            paddingTop: theme.spacing(1.25),
            paddingBottom: theme.spacing(1.25),
            paddingLeft: theme.spacing(2.25),
            paddingRight: theme.spacing(2.25)
          }}
          onClick={() => {
            handleContactUs({
              pathname: window.location.pathname,
              elementId: elementIdCTA,
              userId: userId,
              planListId: planListId
            })
          }}
        >
          Book a demo
        </EnhancedButton>
      </ModalActions>
    </BaseModal>
  )
}
