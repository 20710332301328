import React, { useCallback, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import { CircularProgress, Container } from '@material-ui/core'

import { useUserContext } from '@hypotenuse/common/src/utils/context/UserContext'

import { useOnboardingContext } from '../../utils/context/OnboardingContext'

import OnboardFormTemplate from '../main/onboarding/OnboardFormTemplate'
import { PhoneVerificationForm } from '../main/onboarding/steps'

/**
 * PhoneVerificationProps interface defines the props accepted by the PhoneVerification component.
 * @param {boolean} isPhoneNumberVerifiedLoading - Indicates whether phone number verification is in progress.
 * @param {boolean | undefined} isPhoneNumberVerified - Indicates whether the phone number is verified.
 * @param {(newValue: boolean, revalidate: boolean) => void} setIsPhoneNumberVerified - Function to set the phone number verification status.
 */

interface PhoneVerificationProps {
  isPhoneNumberVerifiedLoading: boolean
  isPhoneNumberVerified: boolean | undefined
  setIsPhoneNumberVerified: (newValue: boolean, revalidate: boolean) => void
}

export const PhoneVerification = React.memo(
  ({
    isPhoneNumberVerifiedLoading,
    isPhoneNumberVerified,
    setIsPhoneNumberVerified
  }: PhoneVerificationProps) => {
    const history = useHistory()
    const { onboarding_info } = useOnboardingContext()
    const { user } = useUserContext()
    const hasCompletedOnboarding = onboarding_info?.has_completed

    useEffect(() => {
      if (!user.requires_phone_verification || isPhoneNumberVerified) {
        hasCompletedOnboarding
          ? history.push('/home')
          : history.push('/onboard')
      }
    }, [
      hasCompletedOnboarding,
      history,
      isPhoneNumberVerified,
      user.requires_phone_verification
    ])

    const onPhoneVerificationSuccess = useCallback(async () => {
      // immediately set the phone verification to complete. This is done
      // because we need to do this before the next step is submitted in order to proceed
      setIsPhoneNumberVerified(true, false)
      history.push('/onboard')
    }, [history, setIsPhoneNumberVerified])
    return (
      <Container
        maxWidth={false}
        disableGutters={true}
        style={{ height: '100%' }}
      >
        {isPhoneNumberVerifiedLoading ? (
          <Stack justifyContent="center" alignItems="center" height="100%">
            <CircularProgress size="3rem" color="primary" />
          </Stack>
        ) : (
          <OnboardFormTemplate
            disableNext={false}
            title={'Verify your phone number'}
            subTitle={
              'We will only use your phone number to verify your account.'
            }
            loading={false}
            // 'back' button disabled for all steps
            // 'next' button disabled on phone verification because it auto-proceeds on successful verification
            // role selection doesn't use a 'next' button to proceed
            hideNextButton={true}
          >
            <PhoneVerificationForm
              isPhoneNumberVerified={isPhoneNumberVerified}
              // When phone number is successfully verified, immediately proceed
              onVerificationSuccess={onPhoneVerificationSuccess}
            />
          </OnboardFormTemplate>
        )}
      </Container>
    )
  }
)
