import { IconSize } from '../Icon'

export enum ButtonColor {
  primary = 'primary',
  red = 'red',
  gray = 'gray'
}
export type Color = keyof typeof ButtonColor

export enum ButtonSize {
  xxs = 'xxs',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg'
}
export type Size = keyof typeof ButtonSize

/**
 * Map of button size to icon size
 */
export const ICON_SIZE_MAP: Record<ButtonSize, IconSize> = {
  [ButtonSize.xxs]: IconSize.small,
  [ButtonSize.xs]: IconSize.small,
  [ButtonSize.sm]: IconSize.medium,
  [ButtonSize.md]: IconSize.medium,
  [ButtonSize.lg]: IconSize.medium
}
