import { apiClient } from '@hypotenuse/common/src/utils/ApiClient'

import { ContentGenerationType } from '../../interfaces/ContentGeneration'

import {
  CrawlJobInfo,
  ExistingContent,
  SortCriteria,
  TopicRecommendation,
  TopicRecommendationCollection,
  TopicRecommendationCollectionType,
  TopicRecommendationTriggerSource,
  TopicRecommenderSettings
} from './types'

export const apiGetTopicRecommendation = async (
  topicId: string
): Promise<TopicRecommendation> => {
  return apiClient
    .get<TopicRecommendation>(`/topic-recommendation/topic/${topicId}`)
    .then((resp) => resp.data)
}

export const apiGetTopicRecommendationCrawlJobs = async (): Promise<
  Map<string, CrawlJobInfo>
> => {
  return apiClient
    .get<Map<string, CrawlJobInfo>>(`/topic-recommendation/crawl-jobs`)
    .then((resp) => resp.data)
}

export const apiGetRecommendedTopics = async (): Promise<TopicRecommendationCollection> => {
  return apiClient
    .get<TopicRecommendationCollection>('/topic-recommendation/live-feed')
    .then((resp) => resp.data)
}

export const apiCreateTopicRecommendations = async (
  sortCriteria: SortCriteria
): Promise<TopicRecommendationCollection> => {
  return apiClient
    .post<TopicRecommendationCollection>('/topic-recommendation/live-feed', {
      sort_criteria: sortCriteria
    })
    .then((resp) => resp.data)
}

export const apiGetTopicRecommenderSettings = async (): Promise<TopicRecommenderSettings> => {
  return apiClient
    .get<TopicRecommenderSettings>('/topic-recommendation/settings')
    .then((resp) => resp.data)
}

export const apiGetSuggestedTopics = async (): Promise<TopicRecommendationCollection> => {
  return apiClient
    .get<TopicRecommendationCollection>('/topic-recommendation/suggestion-feed')
    .then((resp) => resp.data)
}

export const apiCreateSuggestedTopicRecommendations = async (): Promise<TopicRecommendationCollection> => {
  return apiClient
    .post<TopicRecommendationCollection>(
      '/topic-recommendation/suggestion-feed'
    )
    .then((resp) => resp.data)
}

export const apiGetRecommendedTools = async (): Promise<
  ContentGenerationType[]
> => {
  return apiClient
    .post<ContentGenerationType[]>('/topic-recommendation/tools-recommendation')
    .then((resp) => resp.data)
}

export interface TopicRecommendationResponse {
  recommendations: TopicRecommendation[]
  is_url_used_for_recommendation: boolean
}

export const apiCreateContentRecommendation = async (
  url: string,
  contentGenerationTools: ContentGenerationType[],
  generationTriggerSource: TopicRecommendationTriggerSource,
  topicRecommendationCollectionType?: TopicRecommendationCollectionType
): Promise<TopicRecommendationResponse> => {
  return apiClient
    .post<TopicRecommendationResponse>(
      '/topic-recommendation/content-recommendation',
      {
        url,
        content_generation_tools: contentGenerationTools,
        topic_recommendation_collection_type: topicRecommendationCollectionType,
        generation_trigger_source: generationTriggerSource
      }
    )
    .then((resp) => resp.data)
}

export const apiGetContentRecommendation = async (
  topicRecommendationCollectionType?: TopicRecommendationCollectionType
): Promise<TopicRecommendation[]> => {
  return apiClient
    .get<TopicRecommendation[]>(
      '/topic-recommendation/content-recommendation',
      {
        params: {
          topic_recommendation_collection_type: topicRecommendationCollectionType
        }
      }
    )
    .then((resp) => resp.data)
}

export const apiGetExistingContent = async (): Promise<ExistingContent[]> => {
  return apiClient
    .get<ExistingContent[]>('/topic-recommendation/existing-content')
    .then((resp) => resp.data)
}

export const apiDeleteRecommendation = async (id: string) => {
  return apiClient
    .delete(`/topic-recommendation/topic/${id}`)
    .then((resp) => resp.data)
}
