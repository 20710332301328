import React from 'react'

import { useLocation } from 'react-router-dom'

import { Typography, palette } from '@hypotenuse/common/src/atoms'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import { useTheme } from '@material-ui/core'

import { handleContactUs } from '@hypotenuse/common/src/analytics/utils'

interface EcommerceCTAHeaderProps {
  userId: string
  planListId: string
}

const EcommerceCTAHeader: React.FC<EcommerceCTAHeaderProps> = React.memo(
  (props) => {
    const { userId, planListId } = props
    const location = useLocation()
    const theme = useTheme()

    return (
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        margin={theme.spacing(4.5, 0)}
      >
        <Stack spacing={2} paddingX={3} width="100%">
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1.5}
          >
            <Typography
              style={{
                fontSize: '42px',
                lineHeight: '24px'
              }}
            >
              🛒
            </Typography>
            <Typography variant="heading2" style={{ color: palette.black }}>
              Solutions for Ecommerce Enterprise
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography
              variant="paragraph1Reg"
              style={{ color: palette.black, textAlign: 'center' }}
            >
              Scale your content creation and increase speed to site with our
              suite of ecommerce features. <br />
              <span
                onClick={() => {
                  handleContactUs({
                    pathname: location.pathname,
                    elementId: 'ecomlandingpage',
                    userId: userId,
                    planListId: planListId
                  })
                }}
                style={{
                  color: palette.blue[700],
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
              >
                Chat with us to learn more!
              </span>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    )
  }
)

export default EcommerceCTAHeader
