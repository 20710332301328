import { apiClient } from '@hypotenuse/common/src/utils/ApiClient'

import {
  WebflowCollection,
  WebflowCollectionItem,
  WebflowCollectionsInResponse,
  WebflowConfig,
  WebflowExportInRequest,
  WebflowIntegration
} from './interfaces'

/**
 * Get Webflow config.
 */
export const apiGetWebflowConfig = async (): Promise<WebflowConfig> => {
  const response = await apiClient.get<WebflowConfig>(
    '/external-integrations/webflow/config'
  )
  return response.data
}

/**
 * Get Webflow integrations.
 */
export const apiGetWebflowIntegrations = async (): Promise<
  WebflowIntegration[]
> => {
  const response = await apiClient.get<WebflowIntegration[]>(
    '/external-integrations/webflow/integrations'
  )
  return response.data
}

/**
 * Delete a Webflow integration.
 */
export const apiDeleteWebflowIntegration = async (
  integrationId: string
): Promise<void> => {
  await apiClient.delete(
    `/external-integrations/webflow/integrations/${integrationId}`
  )
}

export const apiListWebflowCollections = async (
  integrationId: string,
  siteId: string
): Promise<WebflowCollectionsInResponse> => {
  const response = await apiClient.get(
    `/external-integrations/webflow/integrations/${integrationId}/collections`,
    {
      params: {
        site_id: siteId
      }
    }
  )
  return response.data
}

export const apiGetWebflowCollection = async (
  integrationId: string,
  collectionId: string
): Promise<WebflowCollection> => {
  const response = await apiClient.get(
    `/external-integrations/webflow/integrations/${integrationId}/collections/${collectionId}`
  )
  return response.data
}

export const apiGetWebflowConnection = async (
  integrationId: string,
  collectionId: string
): Promise<WebflowCollection> => {
  const response = await apiClient.get(
    `/external-integrations/webflow/integrations/${integrationId}/collections/${collectionId}`
  )
  return response.data
}

export const apiExportToWebflow = async (
  body: WebflowExportInRequest
): Promise<WebflowCollectionItem> => {
  const response = await apiClient.post<WebflowCollectionItem>(
    `/external-integrations/webflow/integrations/${body.integrationId}/collections/${body.collectionId}/export`,
    {
      document_id: body.documentId,
      document_type: body.documentType,
      is_archived: body.isArchived,
      is_draft: body.isDraft,
      field_data: body.fieldData
    }
  )
  return response.data
}
