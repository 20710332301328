import React, { useCallback, useMemo, useState } from 'react'

import useSWR from 'swr'

import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import { Box, useTheme } from '@material-ui/core'

import {
  apiCreateProductSet,
  apiDeleteProductSet,
  apiGetProductResearchResultByProductSetId,
  apiGetProductSetById,
  apiGetProductSetsByOrg,
  apiUpdateProductSetById
} from '@hypotenuse/common/src/api/Ecommerce'
import { ReactComponent as PlusIcon } from '@hypotenuse/common/src/assets/plus.svg'
import GenericTabs, {
  TabType
} from '@hypotenuse/common/src/components/GenericTabs'
import {
  KeywordsInputModal,
  ProductSetAddEditModal,
  ProductSetSelectionDropdown
} from '@hypotenuse/common/src/components/digitalShelfAnalytics'
import DigitalShelfAnalyticsWidgetsArray from '@hypotenuse/common/src/components/digitalShelfAnalytics/DigitalShelfAnalyticsWidgetsArray'
import ProductsTable from '@hypotenuse/common/src/components/digitalShelfAnalytics/ProductsTable'
import {
  ECommercePlatforms,
  ProductSet,
  ProductSetInResponse,
  ProductSetsStats,
  ProductStats
} from '@hypotenuse/common/src/components/digitalShelfAnalytics/interfaces'
import { useIsMobile } from '@hypotenuse/common/src/hooks/UseIsMobile'
import snackbar from '@hypotenuse/common/src/utils/Snackbar'

import { apiConvertToCatalogue } from '../../api/Catalogue'

import { uuid4 } from '@sentry/utils'

const ADD_KEYWORDS_TAB_ID = 'add-keywords'

const DigitalShelfAnalyticsPage = React.memo(() => {
  const isMobile = useIsMobile()
  const theme = useTheme()

  const [selectedProductSetId, setSelectedProductSetId] = useState<
    string | undefined
  >(undefined)

  const {
    data: productSets,
    isLoading: loadingProductSets,
    mutate: mutateProductSets
  } = useSWR<ProductSet[]>(
    '/ecommerce/product-tracking/product-set',
    async () => {
      const productSets = await apiGetProductSetsByOrg()
      if (productSets.length) {
        setSelectedProductSetId(productSets[0].id)
      }
      return productSets
    }
  )

  const {
    data: selectedProductSet,
    mutate: mutateSelectedProductSet
  } = useSWR<ProductSetInResponse>(
    `/ecommerce/product-tracking/product-set/${selectedProductSetId}`,
    selectedProductSetId
      ? async () => {
          const productSet = await apiGetProductSetById(selectedProductSetId)
          return productSet
        }
      : null
  )

  const {
    data: productResearchResult,
    isLoading: isProductResearchResultLoading
  } = useSWR<ProductSetsStats>(
    [
      `/ecommerce/product-tracking/product-set/${selectedProductSetId}/product-research-result`,
      selectedProductSet?.platform,
      selectedProductSet?.selected_keyword_id
    ],
    selectedProductSet && selectedProductSet.selected_keyword_id
      ? async () => {
          const productResearchResult = await apiGetProductResearchResultByProductSetId(
            selectedProductSet.id,
            selectedProductSet.selected_keyword_id ?? '',
            selectedProductSet.platform
          )
          const productStats: Record<string, ProductStats> = {}
          productResearchResult.product_stats.forEach((stat) => {
            productStats[stat.product_id] = {
              rank: stat.rank,
              content_quality: stat.content_quality
            }
          })
          const stats: ProductSetsStats = {
            first_page_share_of_search:
              productResearchResult.first_page_share_of_search,
            top_three_share_of_search:
              productResearchResult.top_three_share_of_search,
            top_ten_share_of_search:
              productResearchResult.top_ten_share_of_search,
            productStats: productStats
          }
          return stats
        }
      : null
  )

  const handleOptimizeRanking = useCallback(async () => {
    if (!selectedProductSet) {
      return
    }
    const catalogueId = await apiConvertToCatalogue(
      `converted_${selectedProductSet.name}_${uuid4()}`,
      selectedProductSet.products
        ?.filter((_val) => {
          const isPass =
            productResearchResult?.productStats?.[_val.id]?.rank !== null
          return !isPass
        })
        .map(({ external_product_id }) => external_product_id),
      selectedProductSet?.keywords
        ?.filter(({ id }) => id === selectedProductSet.selected_keyword_id)
        .map(({ keyword }) => keyword) ?? [],
      selectedProductSet.platform
    )
    snackbar.show('Catalogs created successfully', { variant: 'success' })
    window.open(`/catalog?catId=${catalogueId}`, '_blank')
  }, [productResearchResult?.productStats, selectedProductSet])

  const [isKeywordsInputModalOpen, setIsKeywordsInputModalOpen] = useState(
    false
  )

  const tabs = useMemo(() => {
    const keywords: TabType[] =
      selectedProductSet?.keywords?.map((keyword) => ({
        id: keyword.id,
        name: keyword.keyword,
        content: ''
      })) ?? []

    keywords.push({
      id: ADD_KEYWORDS_TAB_ID,
      name: (
        <Stack direction="row" alignItems="center">
          <PlusIcon
            style={{
              width: theme.spacing(3),
              height: theme.spacing(3)
            }}
          />
          <Typography variant="paragraph1Bold">Add new keywords</Typography>
        </Stack>
      ),
      content: ''
    })

    return keywords
  }, [selectedProductSet?.keywords, theme])

  const handleChangeKeywordsTab = useCallback(
    async (tabId: string) => {
      if (!selectedProductSet) {
        return
      }
      if (tabId === ADD_KEYWORDS_TAB_ID) {
        setIsKeywordsInputModalOpen(true)
        return
      }
      mutateSelectedProductSet(
        {
          ...selectedProductSet,
          selected_keyword_id: tabId
        },
        { revalidate: false }
      )
      apiUpdateProductSetById({
        productSetId: selectedProductSet.id,
        selectedKeywordId: tabId
      })
    },
    [mutateSelectedProductSet, selectedProductSet]
  )

  const onAddProductSet = useCallback(
    async (
      name: string,
      platform: ECommercePlatforms,
      uploadedFileId?: string
    ) => {
      try {
        await apiCreateProductSet({
          name,
          platform,
          uploadedFileId
        })
        snackbar.show('Product set created successfully', {
          variant: 'success'
        })
        mutateProductSets()
      } catch (e) {
        snackbar.show(
          'Failed to create a product set. Please try again or contact customer support.',
          { variant: 'error' }
        )
      }
    },
    [mutateProductSets]
  )

  const onEditProductSet = useCallback(
    async (
      name?: string,
      platform?: ECommercePlatforms,
      uploadedFileId?: string
    ) => {
      if (!selectedProductSet) {
        return
      }
      try {
        await apiUpdateProductSetById({
          productSetId: selectedProductSet.id ?? '',
          name,
          platform,
          uploadedFileId
        })
        mutateSelectedProductSet()
        mutateProductSets()
        snackbar.show('Product set updated successfully', {
          variant: 'success'
        })
      } catch (e) {
        snackbar.show(
          'Failed to update a product set. Please try again or contact customer support.',
          { variant: 'error' }
        )
      }
    },
    [mutateProductSets, mutateSelectedProductSet, selectedProductSet]
  )

  const onDeleteProductSet = useCallback(
    async (productSetId: string) => {
      try {
        await apiDeleteProductSet(productSetId)
        mutateProductSets()
        snackbar.show('Product set deleted successfully', {
          variant: 'success'
        })
      } catch (e) {
        snackbar.show(
          'Failed to delete a product set. Please try again or contact customer support.',
          { variant: 'error' }
        )
      }
    },
    [mutateProductSets]
  )

  return !loadingProductSets ? (
    <Stack width="100%" flexDirection="column">
      <span id="product-tracking">
        <Stack
          p={isMobile ? 3 : 6}
          paddingTop={6}
          paddingBottom={6}
          textAlign="left"
          flexGrow={1}
          spacing={4}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="heading2">Digital shelf analytics</Typography>
            <ProductSetSelectionDropdown
              productSets={productSets ?? []}
              selectedProductSetId={selectedProductSetId}
              setSelectedProductSetId={setSelectedProductSetId}
            />
          </Stack>
          {selectedProductSet && (
            <>
              <Box>
                <GenericTabs
                  tabs={tabs}
                  currentTabId={selectedProductSet.selected_keyword_id ?? ''}
                  onChange={handleChangeKeywordsTab}
                  hasBorder
                />
              </Box>
              <DigitalShelfAnalyticsWidgetsArray
                firstPageSearchResult={
                  productResearchResult?.first_page_share_of_search
                }
                topThreeSearchResult={
                  productResearchResult?.top_three_share_of_search
                }
                topTenSearchResult={
                  productResearchResult?.top_ten_share_of_search
                }
                isLoading={isProductResearchResultLoading}
              />
              <ProductsTable
                trackedProducts={selectedProductSet.products}
                productStats={productResearchResult?.productStats}
                onOptimizeRanking={handleOptimizeRanking}
                isProductStatsLoading={isProductResearchResultLoading}
              />
              <KeywordsInputModal
                open={isKeywordsInputModalOpen}
                onClose={() => setIsKeywordsInputModalOpen(false)}
                productSetId={selectedProductSet.id}
                platform={selectedProductSet.platform}
                selectedKeywordId={selectedProductSet.selected_keyword_id}
                mutateProductSet={mutateSelectedProductSet}
              />
            </>
          )}
        </Stack>
      </span>
      <ProductSetAddEditModal
        onDeleteProductSet={onDeleteProductSet}
        onEditProductSet={onEditProductSet}
        onAddProductSet={onAddProductSet}
      />
    </Stack>
  ) : null // TODO: add empty state
})

export default DigitalShelfAnalyticsPage
