import { SIZES as WindowSize } from '../HOC/WithPaginationByWindowSize'
import { DocumentType } from '../components/documents/types'

import { AppFeatures } from './Interfaces'

/**
 * Constants common to all apps
 */

// Environment STAGE
export const STAGE = process.env.REACT_APP_STAGE
export const APP_VERSION = process.env.REACT_APP_APP_VERSION || '0.1.0'

// ===== LOCALSTORAGE KEYS =====
//TODO: Move these keys used for local storage here into a localStorageKeys object for better code quality
export const HAS_SEEN_PAPERCUPS = 'hasSeenPapercups'
export const TEAM_INVITE_ID = 'teamInviteId'
export const IS_HIDE_EMPTY_METADATA_ROWS = 'isHideEmptyMetadataRows'
export const PREMIUM_HAS_CONTACTED_US = 'premiumHasContactedUs'
export const IMPORT_CONTACT_US = 'importContactUs'
export const IS_FIRST_MARK_AS_DONE = 'isFirstMarkAsDone'
export const PREMIUM_DONE_TUTORIAL_BEFORE = 'premiumDoneTutorialBefore'
export const PREMIUM_SHOW_BANNER_NAV = 'premiumShowBannerNav'
export const HAS_SUBMITTED_SOCIAL_MEDIA_POST_LINK =
  'hasSubmittedSocialMediaPostLink'
export const IMAGE_GENERATION_LOADING_TIME = 'imageGenerationLoadingTime'
export const IS_HYPOCHAT_ENHANCED_QUALITY = 'isHypochatEnhancedQuality'
export const IS_HYPOCHAT_REAL_TIME_WEB_DATA = 'isHypochatRealTimeWebData'
export const HYPOCHAT_LAST_VIEWED_CHAT_THREAD = 'hypochatLastViewedThread'
export const IS_HYPOCHAT_CLOSED = 'isHypochatClosed'
export const ADTEXT_PING_DOT_LIST = 'adTextPingDotList'
export const HAS_USER_DISMISSED_BULK_GEN_NUDGE = 'hasUserDismissedBulkGenNudge'
export const HAS_USER_DISMISSED_BULK_IMPORT_NUDGE =
  'hasUserDismissedBulkImportNudge'

// ===== PAPERCUPS =====
export const PAPERCUPS_POPUP_DELAY = 180000

// ===== Server =====
// TODO: Server URL should be configured on a per-app basis.
//  Ideally we would refactor the apiClient builder to accept this
//  as a parameter and specify it in each individual app's config.
export const SERVER = process.env.REACT_APP_SERVER || ''
export const OPENTELEMETRY_SERVER =
  process.env.REACT_APP_OPENTELEMETRY_SERVER ||
  'http://localhost:8000/collector'

// ===== Links to contact us page =====
export const CONTACT_US_LINK = 'https://www.hypotenuse.ai/contact-us-product'

export const BLOG_PRO_CONTACT_US_LINK =
  'https://www.hypotenuse.ai/contact-us-blog-pro'

// ===== Colors =====
export const BLOG_PREFIX_COLOR = '#4062C2'
// changes to this should be reflected in the mark styling in /platform/src/index.css
export const COMMAND_SELECTION_COLOR = '#CBE1FE'

// ===== Semantic UI Popup Overflow Prevention
// Needed when parent has overflow property: https://github.com/Semantic-Org/Semantic-UI-React/issues/3725
export const SEMANTIC_POPUP_OVERFLOW_PREVENTION_PROPS = {
  preventOverflow: {
    boundariesElement: 'offsetParent'
  }
}

// ===== Text to Image Generation constants =====
export const TEXT_TO_IMAGE_PROMPT_MAX_CHARS = 800
export const DEFAULT_NUM_OUTPUTS_FOR_TEXT_TO_IMAGE = 1

// ===== Blog constants =====

export const BLOG_CONTENT_BRIEF_TUTORIAL_BANNER_TEXT = `💡 Give Hypo AI details and clear instructions to help it create
amazing content. The more specific you are, the better the output
will be!`

export const BLOG_KNOWLEDGE_TUTORIAL_BANNER_TEXT = `💡 Let’s give Hypo AI some extra background knowledge to make the content 
more accurate and tailored to you.`
export const BRAND_BACKGROUND_MAX_LIMIT = 1500
export const TOPIC_CHAR_MAX_LIMIT = 3000
/**
 * Max limit of keywords a blog can have
 */
export const KEYWORDS_MAX_LIMIT = 5
/**
 * Max character limit of all keywords
 */
export const TOTAL_KEYWORDS_CHAR_MAX_LIMIT = 200
export const TOTAL_SEO_KEYWORDS_CHAR_MAX_LIMIT = 60
export const TITLE_CHAR_MAX_LIMIT = 200
export const TARGET_AUDIENCE_MAX_CHARS = 1500
export const PRODUCT_DETAILS_MAX_CHARS = 1500
/**
 * Max number of characters allowed per a H2 in a blog outline
 */
export const OUTLINE_CHAR_MAX_LIMIT = 200
/**
 * Max total number of characters allowed in a blog outline
 */
export const OUTLINE_MAX_TOTAL_CHARACTER_COUNT = 10000
export const NON_SELECTED_INDEX = -1
export const ORIGINAL_TITLE_INDEX = -2
export const RELATED_KEYWORDS_PASSING_SCORE = 0.7
/**
 * Minimum expected number of paragraph outlines for a blog post.
 */
export const MIN_EXPECTED_OUTLINES = 5
/**
 * Maximum number of body paragraphs allowed per blog post.
 * Does not include the Intro and Conclusion.
 */
export const BLOG_MAX_OUTLINES = 18
/**
 * Max character count for existing blog outline
 */
export const EXISTING_BLOG_OUTLINE_MAX_CHAR_COUNT = 6000
/**
 * Max character count for existing content in SEO rewriter
 */
export const EXISTING_CONTENT_MAX_CHAR_COUNT_FOR_SEO_REWRITER = 3000
/**
 * Max number of talking points (H3s) that can be added under each outline heading (H2)
 */
export const MAX_OUTLINE_DETAILS = 10
export const OUTLINE_DETAILS_MAX_LIMIT = 200

/**
 * Max number of files/links that can be uploaded as references for blog
 */
export const DEFAULT_MAX_NUM_OF_BLOG_REFERENCES = 3

/**
 * Max number of files that can be uploaded as references for any content type that uses GenericReferenceFileSelectionTab
 */
export const DEFAULT_MAX_NUM_OF_REFERENCES: number = 1

// ===== Limits for Advertising Copy =====
/**
 * Number of characters soft limit for google ads headlines
 */
export const GOOGLE_HEADLINE_CHAR_MAX_LIMIT = 30
/**
 * Number of characters soft limit for google ads descriptions
 */
export const GOOGLE_DESCRIPTION_CHAR_MAX_LIMIT = 90
/**
 * Number of characters soft limit for linkedin post ads descriptions
 */
export const LINKEDIN_POST_CHAR_MAX_LIMIT = 3000
/**
 * Default ad tone
 */
export const DEFAULT_AD_TONE = 'Conversational'

/**
 * The URL at which blog previews are hosted.
 */
export const BLOG_PREVIEW_BASE_PATH =
  process.env.REACT_APP_BLOG_PREVIEW_BASE_PATH

/**
 * The URL at which generated image previews are hosted.
 */
export const GENERATED_IMAGE_PREVIEW_BASE_PATH =
  process.env.REACT_APP_GENERATED_IMAGE_PREVIEW_BASE_PATH

// ===== Limits for Dashboard Incentives =====

export const DEFAULT_REFERRAL_CREDITS = 50
export const DEFAULT_LINK_SHARING_CREDITS = 30

// ===== Months =====
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

// ===== HypoChat Page =====
export const DEFAULT_HYPOCHAT_THREAD_DISPLAY_NAME = 'New Chat'
export const DEFAULT_MAX_HYPOCHAT_THREAD_DISPLAY_NAME_LENGTH = 100
export const HYPOCHAT_CHAT_THREAD_DRAWER_WIDTH_DESKTOP = 280
export const HYPOCHAT_CHAT_THREAD_DRAWER_WIDTH_MOBILE = 200

// ===== Sizes =====
export enum SIZES {
  small = 'sm',
  medium = 'md',
  large = 'lg'
}

export const DEBOUNCED_TIMING = 330

// ====== Text ======
export const CUSTOM_TONE_OPTION = 'Describe a tone'
export const BRAND_TONE_OPTION = "Your brand's tone"

// ====== Feature Flags ======
// TODO: All feature flags moving forward should use GlobalFeatureFlags that can be enabled in DB.

// Other frontend constants
export const DESKTOP_DRAWER_WIDTH_OPEN = 256
export const DESKTOP_DRAWER_WIDTH_CLOSE = 56
export const MOBILE_DRAWER_WIDTH_OPEN = 280
export const MOBILE_DRAWER_WIDTH_CLOSE = 0
// In case we want to not close drawer on default
// export const pathsWithClosedDrawer = [
//   'blog-post',
//   'advertising/',
//   'catalog',
//   'image-generation'
// ]
export const SHOW_REFERRAL_SIDEBAR_LINK = false
export const ICON_SIZE = '20px'
export const ICON_STROKE = '2px'

export const COMPOSE_MIN_WORDS = 5

// This is the max width of the generic editor used across the platform
export const EDITOR_MAX_WIDTH = '700px'

// This is the list of country codes that are eligible for the promotion
// We are excluding Columbia at the moment since the price is > 1M and Stripe cannot support more than 6 digits
//export const PROMOTION_COUNTRY_CODES = ['IN', 'MX', 'CO', 'BR', 'PK', 'PH']
export const PROMOTION_COUNTRY_CODES = ['IN', 'MX', 'BR', 'PK', 'PH']

// File upload constants
export const MAX_FILE_SIZE = 32_000_000 // 32 MB

// In the event that 3 features are not selected, fill the rest with fallback features, in this order
// In the event that 'other' is selected, its treated as a non-existant feature, so we do not count it towards
// our feature selected count. 'others' only serves to provide analytics and feedback and is not an actual feature
export const FALLBACK_CHECKLIST_APP_FEATURES = [
  AppFeatures.blog_feature,
  AppFeatures.hypodoc,
  AppFeatures.summarise
]

// IMDA related
export const IMDA_BLOG_MAX_OUTLINES = 40
export const IMDA_MAX_OUTLINE_DETAILS = 20
export const IMDA_TARGET_AUDIENCE_OPTIONS = [
  'Corporates',
  'Political/International G2G Partners',
  'Aspiring talent',
  'Professionals',
  'Seniors',
  'Public',
  'Parents',
  'IMDA (employees)'
]
export const IMDA_WRITER_BLOG_OUTLINE = 'blog_outline|imda'
export const IMDA_LINKEDIN_TALKING_POINTS_MAX_CHARACTERS = 3000

export const BUTTON_BOX_SHADOW =
  'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 20%) 0px 1px 3px -1px'

// ====== Papa custom plan users related =======
export const PAPA_PLAN_FACEBOOK_TEMPLATE_CARD_SUBTITLE =
  'Craft powerful and compelling posts that speak to your target market.'
export const PAPA_PLAN_FACEBOOK_TEMPLATE_CARD_TITLE = 'PAP Facebook post'

// ====== Defaults for # of outputs per generate for different generation types ======
export const DEFAULT_NUM_OUTPUTS_FOR_TEMPLATES = 1
export const DEFAULT_NUM_OUTPUTS_FOR_BLOG_TITLES = 4
export const DEFAULT_NUM_OUTPUTS_FOR_BLOG_OUTLINES = 1

// ====== Product description related constants ======
/**
 * Maximum number of generations before prompting the user with the bulk gen modal
 */
export const PRODUCT_DESCRIPTION_NUM_OF_GENERATES_BEFORE_BULK_GEN_NUDGE = 5
/**
 * Maximum number of product additions before prompting the user with the bulk import modal
 */
export const PRODUCT_DESCRIPTION_NUM_OF_ADDITIONS_BEFORE_BULK_IMPORT_NUDGE = 5
/**
 * Maximum number of words allowed for product description generation
 */
export const PRODUCT_DESCRIPTION_MAX_GENERATION_LENGTH = 500
/**
 * Minimum number of words required for product description generation
 */
export const PRODUCT_DESCRIPTION_MIN_GENERATION_LENGTH = 10
export const PRODUCT_DESCRIPTION_DEFAULT_GENERATION_LENGTH = 150

// ====== Document related constants, for the /documents page ======

/**
 * The document types that are supported by the document overview page.
 *
 * @remarks A general document type is used instead of chat document type
 * for chat documents. Hence chat document type is not included in the list.
 */
export const DOCUMENT_OVERVIEW_PAGE_TYPES: DocumentType[] = [
  DocumentType.blog,
  DocumentType.general,
  DocumentType.instagram,
  DocumentType.google,
  DocumentType.linkedin,
  DocumentType.facebook,
  DocumentType.headline,
  DocumentType.rewriter,
  DocumentType.category_page,
  DocumentType.landing_page,
  DocumentType.eli5,
  DocumentType.email,
  DocumentType.video_intro,
  DocumentType.meta_desc,
  DocumentType.marketing_angles,
  DocumentType.press_release,
  DocumentType.summarise,
  DocumentType.youtube_title,
  DocumentType.bio_writer,
  DocumentType.ask_anything,
  DocumentType.file,
  DocumentType.folder
]
/**
 * Document types that can be moved around (in and out of folders) on the document overview page.
 */
export const MOVABLE_DOCUMENT_TYPES: DocumentType[] = [
  ...DOCUMENT_OVERVIEW_PAGE_TYPES
]

/**
 * The number of items to display per page, depending on the window size.
 * This must be defined outside of the component to keep a stable reference,
 * otherwise the paginator will re-render constantly.
 */
export const DEVICE_SIZE_TO_ITEM_COUNT_MAP = {
  [WindowSize.XL]: 30,
  [WindowSize.LG]: 30,
  [WindowSize.MD]: 20,
  [WindowSize.SM]: 10
}

// TODO: vary this across different device sizes.
// this requires us to update the endpoint to take
// the number of items per page
export const DEVICE_SIZE_TO_ASSET_COUNT_MAP = {
  [WindowSize.XL]: 24,
  [WindowSize.LG]: 24,
  [WindowSize.MD]: 24,
  [WindowSize.SM]: 24
}

export const US_COUNTRY_ISO_CODE = 'US'
export const US_COUNTRY_NAME = 'United States'

export const BUBBLE_MENU_WIDTH = 500
export const BUBBLE_MENU_OFFSET = 55
// ====== Product descriptions related constants ======
export const NUMBER_OF_PRODUCT_DESCRIPTIONS_TO_SCRAPE = 10
export const NUMBER_OF_RELATED_KEYWORDS_TO_RETURN = 20

export const SEO_REGION_SERVICE_CONFIG_KEY = 'seo_region'
