import axios from 'axios'

import { handleDates } from '@hypotenuse/common/src/utils/Functions'

import { SERVER } from './Constants'

export const apiClient = axios.create({
  baseURL: SERVER,
  // Send browser cookies to the server
  withCredentials: true
})

/**
 * This interceptor casts valid date strings into the `Date` class.
 */
apiClient.interceptors.response.use((originalResponse) => {
  handleDates(originalResponse.data)
  return originalResponse
})
