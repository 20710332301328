import React, { CSSProperties, ReactNode } from 'react'

import { ExclamationCircle } from '@styled-icons/bootstrap/ExclamationCircle'
import { cloneDeep } from 'lodash'
import { StrictPopupProps } from 'semantic-ui-react'

import palette from '../atoms/Colors'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import { Box, Grid, InputAdornment, TextField } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme
} from '@material-ui/core/styles'
import clsx from 'clsx'

import { SIZES } from '../utils/Constants'

import { ReactComponent as EnterButton } from '../../../common/src/assets/enter-icon.svg'

import { LabelWithToolTip } from './LabelWithToolTip'
import LimitIndicator from './LimitIndicator'
import { LightTooltipWithPadding } from './StyledTooltips'
import TagList from './TagList'

// ==== Component Utility Functions ====
/**
 * Handling of trimming logic and checking of duplicates for adding keywords
 * This is a duplicate component from FormInputTagsList (however, this is built with MUI)
 * Note that the LabelWithToolTip component used is still a semantic-ui component
 * Preferably change it to using the MUI's Tooltip component
 */

/**
 * Defines a regex expression for splitting tags by multiple delimiters.
 * Current supported delimiters include the comma (',') and the pipe ('|') operators.
 */
const DELIMITERS = /(\|,)/

/**
 * Handles the addition of a detail tag.
 *
 * Each detail tag can be separated by the delimiters, as specified in this file (see: `DELIMITERS`).
 * The tag(s) are added to a list of currently-existing tags and the tags are ensured to be distinct.
 *
 * @param newTag The new tag to be added.
 * @param setNewTag Function to update the user input to the new processed tag.
 * @param updateTags Function to update the list of tags.
 * @param prevTags List of previously-added tags, if any.
 * @param splitByDelimiters True if each tag is to be split by the delimiters, false otherwise.
 *                          Default behaviour is to treat each `newTag` as one tag;
 *                          this means that multiple tags are to be processed prior to addition.
 *                          Currently, only `LongFormKeywords` allows tag processing on Enter.
 */
export const handleAddTag = (
  newTag: string,
  setNewTag: (tag: string) => void,
  updateTags: (tags: string[]) => void,
  prevTags?: string[],
  splitByDelimiters?: boolean
) => {
  // Ignore if new tag is empty
  if (!newTag) return

  const newTags = splitByDelimiters ? newTag.split(DELIMITERS) : [newTag]
  const updatedTags: string[] = cloneDeep(prevTags) || []
  newTags.forEach((tag: string) => {
    tag = tag.trim()
    if (tag.length > 0 && !updatedTags.includes(tag)) updatedTags.push(tag)
  })
  updateTags(updatedTags)
  setNewTag('')
}

/**
 * Handles the addition of a detail tag asynchronously.
 *
 * Each detail tag can be separated by the delimiters, as specified in this file (see: `DELIMITERS`).
 * The tag(s) are added to a list of currently-existing tags and the tags are ensured to be distinct.
 *
 * @param newTag The new tag to be added.
 * @param setNewTag Function to update the user input to the new processed tag.
 * @param updateTags Asynchronous function to update the list of tags.
 * @param prevTags List of previously-added tags, if any.
 * @param splitByDelimiters True if each tag is to be split by the delimiters, false otherwise.
 *                          Default behaviour is to treat each `newTag` as one tag;
 *                          this means that multiple tags are to be processed prior to addition.
 *                          Currently, only `LongFormKeywords` allows tag processing on Enter.
 */
export const handleAddTagAsync = async (
  newTag: string,
  setNewTag: (tag: string) => void,
  updateTags: (tags: string[]) => Promise<void>,
  prevTags?: string[],
  splitByDelimiters?: boolean
) => {
  // Ignore if new tag is empty
  if (!newTag) return

  const newTags = splitByDelimiters ? newTag.split(DELIMITERS) : [newTag]
  const updatedTags: string[] = prevTags || []
  newTags.forEach((tag: string) => {
    tag = tag.trim()
    if (tag.length > 0 && !updatedTags.includes(tag)) updatedTags.push(tag)
  })
  await updateTags(updatedTags)
  setNewTag('')
}

const handleDeleteTag = (
  tagToDelete: string,
  updateTags: (tags: string[]) => void,
  allTags: string[]
) => {
  updateTags(allTags.filter((t) => t !== tagToDelete))
}

interface MuiFormInputTagsListProps {
  id: string
  testId: string
  inputLabel: string | React.ReactNode
  maxLength?: number
  labelSize?: SIZES
  isLabelRequired?: boolean
  inputPlaceholder?: string
  // input text field props
  newValue: string
  setNewValue: (value: string) => void
  disabled?: boolean
  // tag list display props
  tagValues?: string[]
  setTagValues: (taglist: string[]) => void
  generatedTagValues?: string[]
  handleDeleteGeneratedTagValue?: (tag: string) => void
  popupBody?: ReactNode
  popupPosition?: StrictPopupProps['position']
  disablePopup?: boolean
  customKeyPress?: string // custom key to add tags
  splitByDelimiters?: boolean
  labelHelperText?: string
  formInputStyle?: CSSProperties
  keywordContainerStyle?: CSSProperties
  formInputClassName?: string
  textFieldSize?: 'small' | 'medium'
  maxKeywords?: number
  maxTotalKeywordsChar?: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tagsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: `${theme.spacing(1)}px`
    },
    keywordContainer: {
      marginTop: theme.spacing(1)
    },
    textfield: {
      marginTop: theme.spacing(1),
      borderRadius: theme.spacing(1),
      width: '100%',
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: 'rgba(169, 161, 253, 0.5)'
        }
      }
    },
    inputRoot: {
      '& fieldset': {
        borderColor: palette.gray['300'],
        borderRadius: theme.spacing(1)
      }
    }
  })
)

export function MuiFormInputTagsList(
  props: React.PropsWithChildren<MuiFormInputTagsListProps>
) {
  const {
    id,
    newValue,
    setNewValue,
    disabled,
    maxLength,
    testId,
    tagValues,
    setTagValues,
    generatedTagValues,
    handleDeleteGeneratedTagValue,
    inputLabel,
    labelSize,
    labelHelperText,
    isLabelRequired,
    popupBody,
    popupPosition,
    inputPlaceholder,
    disablePopup,
    customKeyPress,
    splitByDelimiters,
    formInputStyle,
    textFieldSize,
    keywordContainerStyle,
    formInputClassName,
    maxKeywords,
    maxTotalKeywordsChar
  } = props
  const classes = useStyles()

  const theme = useTheme()

  const combinedKeywords = tagValues?.join('') + newValue.trim()

  const isTextFieldError =
    (!!maxKeywords &&
      !!tagValues &&
      tagValues?.length + (newValue.trim().length > 0 ? 1 : 0) > maxKeywords) ||
    (!!maxTotalKeywordsChar &&
      !!combinedKeywords &&
      combinedKeywords.length > maxTotalKeywordsChar)

  return (
    <Box display="flex" flexDirection="column" my={2}>
      <Grid xs={12} item>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <LabelWithToolTip
            labelText={inputLabel}
            required={isLabelRequired}
            subLabelText={labelHelperText}
            size={labelSize}
            tooltipBody={popupBody}
            popupProps={{
              position: popupPosition,
              disabled: disablePopup
            }}
          />
          {maxKeywords && tagValues && tagValues.length > 0 && (
            <LimitIndicator
              charMaxLimit={maxKeywords}
              valueLength={tagValues.length}
            />
          )}
        </Box>
        <Stack direction="row" spacing={2} marginTop={theme.spacing(0.01)}>
          <TextField
            style={formInputStyle}
            error={isTextFieldError}
            id={id}
            data-testid={id}
            placeholder={inputPlaceholder}
            minRows={1}
            size={textFieldSize}
            variant={'outlined'}
            value={newValue}
            onChange={(e: React.ChangeEvent<{ value: any }>) =>
              e.target.value !== customKeyPress
                ? setNewValue(e.target.value)
                : undefined
            }
            onKeyPress={(event: React.KeyboardEvent) => {
              if (event.key === 'Enter' || event.key === customKeyPress) {
                handleAddTag(
                  newValue,
                  setNewValue,
                  setTagValues,
                  tagValues,
                  splitByDelimiters
                )
              }
            }}
            className={clsx(classes.textfield, formInputClassName)}
            disabled={disabled}
            InputProps={{
              classes: { root: classes.inputRoot },
              endAdornment: (
                <InputAdornment position="end">
                  {isTextFieldError && (
                    <LightTooltipWithPadding
                      title={`You can only have up to ${maxKeywords} keywords`.concat(
                        maxTotalKeywordsChar
                          ? ` and they should not exceed ${maxTotalKeywordsChar} characters.`
                          : '.'
                      )}
                      arrow
                    >
                      <span>
                        <ExclamationCircle
                          size={15}
                          color={palette.error[500]}
                        />
                      </span>
                    </LightTooltipWithPadding>
                  )}
                  <IconButton
                    data-testid={`${id}_add_button`}
                    style={{ cursor: 'pointer', paddingRight: 0 }}
                    onClick={() =>
                      handleAddTag(
                        newValue,
                        setNewValue,
                        setTagValues,
                        tagValues,
                        splitByDelimiters
                      )
                    }
                  >
                    <EnterButton />
                  </IconButton>
                </InputAdornment>
              )
            }}
            inputProps={{
              maxLength: maxLength
            }}
          />
        </Stack>
      </Grid>
      {maxKeywords &&
        tagValues &&
        tagValues?.length + (newValue.trim().length > 0 ? 1 : 0) >
          maxKeywords && (
          <Typography
            style={{ color: palette.error[500], marginTop: 8 }}
            variant="body2"
          >
            You can only have up to {maxKeywords} keywords.
          </Typography>
        )}
      {maxTotalKeywordsChar &&
        combinedKeywords &&
        combinedKeywords.length > maxTotalKeywordsChar && (
          <Typography
            style={{ color: palette.error[500], marginTop: 8 }}
            variant="body2"
          >
            Your keywords should not exceed {maxTotalKeywordsChar} characters.
          </Typography>
        )}
      {((tagValues && tagValues.length > 0) ||
        (generatedTagValues && generatedTagValues.length > 0)) && (
        <Grid
          className={classes.keywordContainer}
          xs={12}
          item
          style={keywordContainerStyle}
        >
          <div
            className={classes.tagsContainer}
            data-testid={`${testId}-tagsContainer`}
          >
            <TagList
              tags={
                tagValues ? tagValues.map((keyword: string) => keyword) : []
              }
              onDelete={(tagToDelete: string) =>
                tagValues &&
                handleDeleteTag(tagToDelete, setTagValues, tagValues)
              }
              disabled={disabled}
            />
            {generatedTagValues && (
              <TagList
                tags={generatedTagValues ?? []}
                onDelete={handleDeleteGeneratedTagValue}
                disabled={disabled}
              />
            )}
          </div>
        </Grid>
      )}
    </Box>
  )
}

export default MuiFormInputTagsList
