import React from 'react'

import { IconButton, Stack } from '@mui/material'

import { Typography, palette } from '../../atoms'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core'

import { useShowChildOnHover } from '../../hooks/useShowChildOnHover'

import { parseBytesToHumanReadable } from '../../utils/Functions'

import { ReactComponent as AttachmentSvg } from '../../../../common/src/assets/attachments.svg'
import { ReactComponent as TrashSvg } from '../../../../common/src/assets/trash-03.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    referenceFileItem: {
      border: `1px solid ${palette.gray[200]}`,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1.5),
      color: palette.gray['900']
    },
    iconButtonRoot: {
      '&:hover': {
        backgroundColor: palette.gray[100],
        borderRadius: theme.spacing(0.5)
      },

      marginRight: theme.spacing(0.25),
      padding: theme.spacing(0.5)
    }
  })
)

interface ReferenceFileCardItemProps {
  handleRemoveFile: () => void
  fileName: string
  fileSize: number
}

const ReferenceFileCardItem: React.FC<ReferenceFileCardItemProps> = React.memo(
  ({ handleRemoveFile, fileName, fileSize }) => {
    const classes = useStyles()
    const theme = useTheme()
    const { parent: hoverParent, child: hoverChild } = useShowChildOnHover()

    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className={`${classes.referenceFileItem} ${hoverParent}`}
      >
        <Stack direction="row" alignItems="center">
          <AttachmentSvg style={{ marginRight: theme.spacing(1) }} />
          <Typography
            variant="captionReg"
            style={{ marginRight: theme.spacing(1.5) }}
          >
            {fileName}
          </Typography>
          <Typography style={{ color: palette.gray[500] }} variant="captionReg">
            {parseBytesToHumanReadable(fileSize)}
          </Typography>
        </Stack>

        <IconButton
          size="small"
          onClick={handleRemoveFile}
          className={hoverChild}
          classes={{ root: classes.iconButtonRoot }}
        >
          <TrashSvg />
        </IconButton>
      </Stack>
    )
  }
)

export default ReferenceFileCardItem
