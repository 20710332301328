import { UserUploadedFileSource } from '../interfaces/Files'

import { apiClient } from '../utils/ApiClient'

import { DocumentQuery } from '../components/documents/types'
import type {
  IFileParsingStatus,
  IFileUpload
} from '../components/file_upload/types'

/**
 * Gets the documents of files uploaded by the user
 */
export const apiGetUploadedFiles = async (query: DocumentQuery) => {
  const response = await apiClient.post<IFileUpload[]>(
    `/file/uploaded-files`,
    query
  )
  return response.data
}

/**
 * @param source Where the file was uploaded from (e.g. Summarizer, Blog, Tone Analyzer, etc.)
 */
export const apiUploadFile = async ({
  file,
  source,
  saveAsDoc = false,
  folderId
}: {
  file: File
  source: UserUploadedFileSource
  saveAsDoc?: boolean
  folderId?: string
}) => {
  const formData = new FormData()
  formData.append('uploaded_file', file)
  formData.append('uploaded_from', source)
  formData.append('is_creating_doc', saveAsDoc.toString())

  if (folderId) {
    formData.append('folder_id', folderId)
  }

  const response = await apiClient.post<IFileUpload>(`/file`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })

  return response.data
}

export const apiGetFileDetails = async (fileId: string) => {
  const response = await apiClient.get<IFileUpload>(`/file/${fileId}`)
  return response.data
}

/*
 * This endpoint will parse a file to decouple parsing the file from calling the LM generation endpoint (mainly for performance optimization)
 * This endpoint will parse the file into markdown and save it to the database
 * @param fileId
 */
export const apiParseFile: (
  fileId: string
) => Promise<IFileParsingStatus> = async (fileId: string) => {
  const response = await apiClient.post(`/file/${fileId}/parse`)
  return response.data
}

/**
 * @param shouldIndexToVectorDB - Whether or not to index the file in the vector database
 */
export const apiSummarizeFile = async (
  fileId: string,
  shouldIndexToVectorDB: boolean
) => {
  const response = await apiClient.post<string>(`/file/${fileId}/summary`, {
    should_index_to_vector_db: shouldIndexToVectorDB
  })
  return response.data
}

export const apiConvertHtmlToPdf = async (html: string, title: string) => {
  const response = await apiClient.post<Blob>(
    `/document/export_to_pdf`,
    {
      content: html,
      title
    },
    {
      responseType: 'blob'
    }
  )
  return response.data
}

export const apiPostConvertHtmlToDocx = async (html: string, title: string) => {
  const response = await apiClient.post<Blob>(
    `/document/export_to_docx`,
    {
      content: html,
      title
    },
    {
      responseType: 'blob'
    }
  )
  return response.data
}

export const apiPostConvertHtmlToMarkdown = async (
  html: string,
  title: string
) => {
  const response = await apiClient.post<Blob>(
    `/document/export_to_markdown`,
    {
      content: html,
      title
    },
    {
      responseType: 'blob'
    }
  )
  return response.data
}
