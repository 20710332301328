import React from 'react'

import { Checkmark } from '@styled-icons/evaicons-solid/Checkmark'

import palette from '../atoms/Colors'
import { Box, BoxProps, useTheme } from '@material-ui/core'

export interface SelectCheckboxProps extends BoxProps {
  size: number
  checkboxSelected: boolean | undefined
  disabled?: boolean
}

export const SelectCheckbox = (props: SelectCheckboxProps) => {
  const { size, checkboxSelected, style, disabled = false, ...rest } = props
  const theme = useTheme()
  return (
    <Box
      borderRadius={5}
      border={`1px solid ${
        checkboxSelected ? palette.primary[500] : palette.gray[300]
      }`}
      width="min-content"
      bgcolor={
        disabled
          ? palette.gray[200]
          : checkboxSelected
          ? palette.primary[50]
          : theme.palette.background.paper
      }
      style={{
        cursor: 'pointer',
        ...style
      }}
      {...rest}
    >
      <Checkmark
        size={size}
        style={{
          display: 'block',
          visibility: checkboxSelected ? 'visible' : 'hidden'
        }}
        color={disabled ? palette.gray[600] : palette.primary[500]}
      />
    </Box>
  )
}
