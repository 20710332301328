import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import { ECommercePlatforms, ProductSet } from './interfaces'

interface ProductSetModalState {
  id?: string
  /**
   * The name of the product set that's currently active.
   */
  name?: string
  /**
   * The platform of the product set that's currently active.
   */
  platform?: ECommercePlatforms
  /**
   * The files that are currently active.
   */
  files: File[]
  /**
   * The IDs of the uploaded files.
   */
  uploadedFileIds: string[]
  /**
   * Whether the modal is open.
   */
  open: boolean
  /**
   * Whether the modal is in edit mode.
   */
  editMode: boolean
}

interface ProductSetModalActions {
  show: (productSet?: ProductSet, edit?: boolean) => void
  close: () => void
  setName: (name: string) => void
  setPlatform: (platform: ECommercePlatforms) => void
  setFiles: (files: File[]) => void
  setUploadedFileIds: (uploadedFileIds: string[]) => void
}

type ProductSetModalStore = ProductSetModalState & ProductSetModalActions

/**
 * Zustand store for the product set modal.
 */
export const useProductSetModalStore = create(
  immer<ProductSetModalStore>((set) => ({
    open: false,
    editMode: false,
    files: [],
    uploadedFileIds: [],
    show: (productSet, edit) => {
      set({
        id: productSet?.id,
        name: productSet?.name || 'New product set',
        platform:
          (productSet?.platform as ECommercePlatforms) ||
          ECommercePlatforms.WALMART,
        open: true,
        editMode: edit || productSet !== undefined,
        files: [],
        uploadedFileIds: []
      })
    },
    close: () => {
      set({
        id: undefined,
        open: false,
        name: undefined,
        platform: undefined,
        editMode: false,
        files: [],
        uploadedFileIds: []
      })
    },
    setName: (name) => {
      set({ name })
    },
    setPlatform: (platform) => {
      set({ platform })
    },
    setFiles: (files) => {
      set({ files })
    },
    setUploadedFileIds: (uploadedFileIds) => {
      set({ uploadedFileIds })
    }
  }))
)
