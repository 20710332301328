import React, { useState } from 'react'

import * as Sentry from '@sentry/react'
import axios from 'axios'
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { Link } from 'react-router-dom'

import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import TextButton from '@hypotenuse/common/src/components/atoms/TextButton'
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Fade,
  InputAdornment,
  Link as MuiLink,
  TextField
} from '@material-ui/core'
import { OutlinedTextFieldProps } from '@material-ui/core/TextField/TextField'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'

import { useAsyncFn, useInterval } from '@hypotenuse/common/src/hooks'
import { useLocalStorage } from '@hypotenuse/common/src/hooks/useLocalStorage'
import snackbar from '@hypotenuse/common/src/utils/Snackbar'
import { useUserContext } from '@hypotenuse/common/src/utils/context/UserContext'

import {
  apiCheckPhoneVerificationCode,
  apiRequestPhoneVerificationCode
} from '../../../../api/User'

import { LOGOUT_PAGE_PATH } from '../../../../utils/Constants'

enum VerificationStep {
  RequestCode,
  VerifyCode
}

const VERIFICATION_REQUEST_COOLDOWN_TIME = 60 // Minimum time between two verification requests in seconds

export interface PhoneVerificationFormProps {
  /**
   * Whether the user's phone number is verified
   */
  isPhoneNumberVerified?: boolean
  /**
   * Callback called when phone verification is successful
   */
  onVerificationSuccess: () => Promise<void>
}

/**
 * A phone number verification form intended to be used as part of the multi-step onboarding process
 */
const PhoneVerificationForm: React.FC<PhoneVerificationFormProps> = (props) => {
  const { isPhoneNumberVerified, onVerificationSuccess } = props
  const { user } = useUserContext()
  const loader = <CircularProgress size={25} color="inherit" />

  // Tracks which step (request code or verify code) the user is in
  const [verificationStep, setVerificationStep] = useState<VerificationStep>(
    VerificationStep.RequestCode
  )

  const [phoneNumber, setPhoneNumber] = React.useState<string>('')
  const [verificationCode, setVerificationCode] = React.useState<string>('')

  const isPhoneNumberPossible = isPossiblePhoneNumber(phoneNumber) // Just check if number is the right length
  const isPhoneNumberValid = isValidPhoneNumber(phoneNumber) // Full validation
  // Code must be a 6-digit number - we use a regex to test this
  const isVerificationCodeValid = /^\d{6}$/.test(verificationCode)

  // Rate-limit verification requests using LocalStorage (once every 60 seconds)
  const [
    lastVerificationRequestTimestamp,
    setLastVerificationRequestTimestamp
  ] = useLocalStorage<number | undefined>(
    'phoneNumberVerificationRequestTimestamp',
    undefined
  )
  const secondsSinceLastVerificationRequest =
    useInterval(
      () =>
        Math.ceil(
          (Date.now() - (lastVerificationRequestTimestamp ?? 0)) / 1000
        ),
      1000
    ) ?? VERIFICATION_REQUEST_COOLDOWN_TIME
  const isVerificationRequestOnCooldown =
    lastVerificationRequestTimestamp !== undefined &&
    secondsSinceLastVerificationRequest < VERIFICATION_REQUEST_COOLDOWN_TIME
  const secondsUntilVerificationRequestAllowed = Math.max(
    VERIFICATION_REQUEST_COOLDOWN_TIME - secondsSinceLastVerificationRequest,
    0
  )

  const _handleRequestVerificationCode = async () => {
    // TODO: These check should be handled by react-hook-form
    if (!phoneNumber) {
      snackbar.show('Please enter a phone number', { variant: 'info' })
      return
    }
    if (!isPhoneNumberValid) {
      snackbar.show('Please enter a valid phone number', { variant: 'warning' })
      return
    }
    if (isVerificationRequestOnCooldown) {
      snackbar.show('Wait a few seconds before requesting another code', {
        variant: 'warning'
      })
      return
    }
    try {
      await apiRequestPhoneVerificationCode(phoneNumber)
      // On a successful request to get a verification code, update when the last request was made
      setLastVerificationRequestTimestamp(Date.now())
      setVerificationStep(VerificationStep.VerifyCode)
    } catch (err) {
      let errorMessage =
        'Oops! Something broke when requesting a verification code.'
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 429) {
          // Server-side rate-limit exceeded
          errorMessage =
            "You've requested a code too many times. Please contact us to get verified."
        } else {
          // If the user is going to be shown a generic error message, log the error to Sentry
          if (!err.response?.data?.detail) {
            Sentry.captureException(err, { level: 'error' })
          }
          errorMessage = err.response?.data?.detail || errorMessage
        }
      }
      // TODO: Handle remaining failure cases:
      //  - Number already taken: set error and show helperText under field
      //  - Number invalid according to server:
      //  - Number not allowed: set error and show helperText, ask to contact us
      //  - Unknown error: just show a snackbar
      snackbar.show(errorMessage, { variant: 'error' })
    }
  }
  const [
    {
      loading: requestVerificationCodeLoading,
      error: requestVerificationCodeError
    },
    handleRequestVerificationCode
  ] = useAsyncFn(_handleRequestVerificationCode, [
    _handleRequestVerificationCode
  ])
  // TODO: Use hook-form for validation instead of bare functions
  const requestCodeForm = (
    <Stack spacing={2}>
      <PhoneInput
        // We cast empty string to undefined (and vice-versa) because the library suggests we do so
        // https://www.npmjs.com/package/react-phone-number-input
        value={phoneNumber || undefined}
        onChange={(value) => {
          setPhoneNumber(value ?? '')
        }}
        international
        limitMaxLength
        addInternationalOption={false}
        defaultCountry="US"
        inputComponent={PhoneNumberTextField}
        numberInputProps={{
          InputProps: {
            endAdornment: isPhoneNumberPossible ? (
              <InputAdornment position="end">
                {isPhoneNumberValid ? (
                  <CheckIcon color="secondary" />
                ) : (
                  <ClearIcon color="error" />
                )}
              </InputAdornment>
            ) : undefined
          },
          error: !!requestVerificationCodeError
        }}
        // countrySelectComponent={CountrySelectDropdown}
      />
      {/* TODO: Refactor countdown as a standalone component? */}
      {isVerificationRequestOnCooldown &&
        secondsUntilVerificationRequestAllowed > 0 && (
          <Typography variant="body2" color="textSecondary">
            You can request another code in{' '}
            {secondsUntilVerificationRequestAllowed} seconds.
          </Typography>
        )}
      <Button
        variant="contained"
        color="primary"
        onClick={handleRequestVerificationCode}
        disabled={
          !isPhoneNumberValid ||
          isVerificationRequestOnCooldown ||
          requestVerificationCodeLoading
        }
      >
        {requestVerificationCodeLoading ? loader : 'Send code'}
      </Button>
      {isPhoneNumberValid ? (
        <TextButton
          color="textSecondary"
          variant="body2"
          onClick={() => setVerificationStep(VerificationStep.VerifyCode)}
          underline="always"
        >
          Already have a code?
        </TextButton>
      ) : (
        !phoneNumber && (
          <Typography variant="body2" color="textSecondary">
            Already have a code? Enter your number again.
          </Typography>
        )
      )}
      <Typography variant="body2" color="textSecondary">
        You're logged in as{' '}
        <strong style={{ whiteSpace: 'nowrap' }}>{user.username}</strong>
        <br />
        Not the right account?{' '}
        <MuiLink component={Link} to={LOGOUT_PAGE_PATH}>
          <u style={{ whiteSpace: 'nowrap' }}>Click here to logout</u>
        </MuiLink>
      </Typography>
    </Stack>
  )

  const _handleCheckVerificationCode = async () => {
    // TODO: These check should be handled by react-hook-form
    if (!phoneNumber) {
      snackbar.show('Please enter your phone number', { variant: 'info' })
      return
    }
    if (!isPhoneNumberValid) {
      // TODO: Handle validation errors?
      snackbar.show('Please enter a valid phone number.', { variant: 'info' })
      return
    }
    if (!isVerificationCodeValid) {
      snackbar.show('Please enter a valid verification code.', {
        variant: 'info'
      })
      return
    }
    try {
      await apiCheckPhoneVerificationCode(phoneNumber, verificationCode)
      await onVerificationSuccess()
      // TODO: Replace with full-screen item
      snackbar.show('Verification successful!', {
        variant: 'success'
      })
    } catch (err) {
      // Handle the various failure cases:
      //  - Server-defined error: Show error snackbar with server's error message.
      //  - All other errors: generic error snackbar
      let errorMessage =
        'Oops! Something broke while checking your verification code.'
      if (axios.isAxiosError(err)) {
        if (err.response?.data?.detail) {
          // Show error message from server
          errorMessage = err.response?.data?.detail
        }
      }
      snackbar.show(errorMessage, { variant: 'error' })
      throw err
    }
  }
  const [
    {
      loading: checkVerificationCodeLoading,
      error: checkVerificationCodeError
    },
    handleCheckVerificationCode
  ] = useAsyncFn(_handleCheckVerificationCode, [_handleCheckVerificationCode])
  // TODO: Use hook-form to ensure code validity before enabling button or handler function
  const verifyCodeForm = (
    <Stack spacing={2}>
      <Typography>
        Enter the verification code sent to <b>{phoneNumber}</b>
      </Typography>
      <TextField
        size="small"
        variant="outlined"
        label="6-Digit Code"
        placeholder="123456"
        value={verificationCode}
        onChange={({ target: { value } }) => {
          setVerificationCode(value)
        }}
        error={!!checkVerificationCodeError}
      />
      <Button
        variant="contained"
        color={isPhoneNumberVerified ? 'default' : 'primary'}
        onClick={handleCheckVerificationCode}
        disabled={
          !isVerificationCodeValid ||
          !isPhoneNumberValid ||
          isPhoneNumberVerified ||
          checkVerificationCodeLoading
        }
      >
        {isPhoneNumberVerified ? (
          <Stack direction="row" spacing={1}>
            Verified <CheckIcon />
          </Stack>
        ) : checkVerificationCodeLoading ? (
          loader
        ) : (
          'Verify'
        )}
      </Button>
      <TextButton
        color="textSecondary"
        variant="body2"
        onClick={() => setVerificationStep(VerificationStep.RequestCode)}
        underline="always"
      >
        Didn't get a code? Click here to try again
      </TextButton>
    </Stack>
  )

  return (
    <Box minWidth={250} maxWidth={400}>
      <Collapse
        in={verificationStep === VerificationStep.RequestCode}
        timeout={500}
      >
        <Fade
          in={verificationStep === VerificationStep.RequestCode}
          timeout={300}
        >
          {requestCodeForm}
        </Fade>
      </Collapse>
      <Collapse
        in={verificationStep === VerificationStep.VerifyCode}
        timeout={500}
      >
        <Fade
          in={verificationStep === VerificationStep.VerifyCode}
          timeout={300}
        >
          {verifyCodeForm}
        </Fade>
      </Collapse>
    </Box>
  )
}

// TODO: Create a standalone PhoneNumberInput component
export interface PhoneNumberTextFieldProps
  extends Omit<OutlinedTextFieldProps, 'variant'> {}

export const PhoneNumberTextField: React.FC<PhoneNumberTextFieldProps> = React.forwardRef(
  (props, ref) => {
    const { ...restProps } = props
    return <TextField variant="outlined" inputRef={ref} {...restProps} />
  }
)
PhoneNumberTextField.defaultProps = {
  label: 'Phone Number',
  placeholder: '+1(123)456-7890',
  size: 'small'
}

export default PhoneVerificationForm
