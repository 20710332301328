import React, { useCallback } from 'react'

import { Typography } from '../atoms'
import palette from '../atoms/Colors'
import {
  Divider,
  Tab,
  TabProps,
  Tabs,
  TabsProps,
  Theme,
  createStyles,
  makeStyles
} from '@material-ui/core'

import GenericTabPanel from './GenericTabPanel'

const useStyles = () =>
  makeStyles((theme: Theme) => {
    return createStyles({
      root: {
        minHeight: theme.spacing(4.5)
      },
      tabRoot: {
        minHeight: theme.spacing(4.5),
        overflow: 'hidden',
        // NOTE: In the future, we may have a long content for the tab. By that time,
        // we should change this component to have a scroll button.
        minWidth: 0,
        fontWeight: 600,
        fontSize: theme.spacing(2)
      },
      indicator: {
        backgroundColor: palette.primary[700]
      }
    })
  })

export interface TabType {
  /**
   * Unique identifier, used as tabId and key
   */
  id: string
  /**
   * Name of the tab
   */
  name: React.ReactNode | string
  /**
   * Body content to be displayed in the TabPanel
   */
  content: React.ReactNode
}

interface GenericTabsProps {
  /**
   * Tabs to be rendered
   */
  tabs: TabType[]
  /**
   * ID of currently active tab
   */
  currentTabId: string
  /**
   * Function called upon tab change
   */
  onChange?: (tabId: string) => void
  /**
   * Background color of the tab
   */
  backgroundColor?: string
  /**
   * Whether the tab has a border
   */
  hasBorder?: boolean
  /**
   * Whether the tab has a shadow
   */
  disableShadow?: boolean
  /**
   * Props to be passed to the Tabs component
   */
  tabsProps?: TabsProps
  /**
   * Props to be passed to the Tab component
   */
  tabProps?: TabProps
  /**
   * Props to be passed to the GenericTabPanel component
   */
  tabContentProps?: React.HTMLProps<HTMLDivElement>
  /**
   * Whether the tab should be full width
   */
  isFullWidth?: boolean
  /**
   * Width of the tab
   */
  width?: string
  /**
   * Whether to show divider between the tab and tabpanels
   */
  showDivider?: boolean
}

const GenericTabs = React.memo((props: GenericTabsProps) => {
  const {
    tabs,
    onChange,
    currentTabId,
    backgroundColor,
    hasBorder = false,
    disableShadow = false,
    tabsProps,
    tabProps,
    tabContentProps,
    isFullWidth = true,
    width,
    showDivider = false
  } = props
  const classes = useStyles()()

  const handleChange = useCallback(
    (_event: React.ChangeEvent<{}>, tabName: string) => {
      onChange?.(tabName)
    },
    [onChange]
  )

  return (
    <>
      <Tabs
        value={currentTabId}
        onChange={handleChange}
        classes={{ root: classes.root, indicator: classes.indicator }}
        aria-label="generic-tabs"
        {...tabsProps}
        variant={isFullWidth ? 'fullWidth' : undefined}
      >
        {tabs.map((tab: TabType) => (
          <Tab
            key={tab.id}
            classes={{
              root: classes.tabRoot
            }}
            style={{
              color:
                currentTabId === tab.id
                  ? palette.primary[700]
                  : palette.gray[600],
              width: width
            }}
            value={tab.id}
            label={<Typography variant="paragraph1Bold">{tab.name}</Typography>}
            id={`tab-${tab.id}`}
            aria-controls={`tab-${tab.id}`}
            {...tabProps}
          />
        ))}
      </Tabs>
      {showDivider && <Divider />}
      {tabs.map((tab: TabType) => (
        <GenericTabPanel
          key={tab.id}
          tabId={tab.id}
          currentTabId={currentTabId}
          backgroundColor={backgroundColor}
          hasBorder={hasBorder}
          disableShadow={disableShadow}
          children={tab.content}
          {...tabContentProps}
        />
      ))}
    </>
  )
})

export default GenericTabs
