import React, { useCallback, useEffect, useMemo } from 'react'

import { useHistory } from 'react-router-dom'
import useSWR from 'swr'

import { CircularProgress } from '@material-ui/core'

import {
  apiGetWebflowConfig,
  apiGetWebflowIntegrations
} from '@hypotenuse/common/src/components/integrations/webflow/api'
import { WebflowIntegration } from '@hypotenuse/common/src/components/integrations/webflow/interfaces'

export default function WebflowAuthorizationPage() {
  const {
    data: webflowIntegrations,
    isLoading: isWebflowIntegrationsLoading
  } = useSWR<WebflowIntegration[]>('/webflow/integrations', () =>
    apiGetWebflowIntegrations()
  )
  const history = useHistory()

  const handleWebflowConnect = useCallback(async () => {
    const webflowConfig = await apiGetWebflowConfig()
    window.location.href = `https://webflow.com/oauth/authorize?response_type=code&client_id=${webflowConfig.client_id}&scope=${webflowConfig.scope}`
  }, [])

  const fullPageLoader = useMemo(
    () => (
      <div
        style={{
          height: '100%',
          top: '50%',
          left: '50%',
          position: 'fixed'
        }}
      >
        <CircularProgress size="3rem" />
      </div>
    ),
    []
  )

  useEffect(() => {
    if (!isWebflowIntegrationsLoading && !webflowIntegrations?.length) {
      handleWebflowConnect()
    } else if (!isWebflowIntegrationsLoading && webflowIntegrations?.length) {
      history.push('/settings/integrations')
    }
  }, [
    webflowIntegrations,
    isWebflowIntegrationsLoading,
    history,
    handleWebflowConnect
  ])

  return <>{fullPageLoader}</>
}
