import React, { useMemo } from 'react'

import StatsWidget, { StatsWidgetProps } from '../widgets/StatsWidget'
import WidgetArray from '../widgets/WidgetArray'

interface DigitalShelfAnalyticsWidgetsArrayProps {
  firstPageSearchResult: number | null | undefined
  topThreeSearchResult: number | null | undefined
  topTenSearchResult: number | null | undefined
  isLoading: boolean
}

const DigitalShelfAnalyticsWidgetsArray = React.memo(
  (props: DigitalShelfAnalyticsWidgetsArrayProps) => {
    const {
      firstPageSearchResult,
      topThreeSearchResult,
      topTenSearchResult,
      isLoading
    } = props
    const widgets: StatsWidgetProps[] = useMemo(() => {
      return [
        {
          title: 'First page',
          formattedStat:
            firstPageSearchResult !== undefined &&
            firstPageSearchResult !== null
              ? (Math.round(firstPageSearchResult * 1000) / 10).toString() + '%'
              : firstPageSearchResult,
          isLoading: isLoading
        },
        {
          title: 'Top 3',
          formattedStat:
            topThreeSearchResult !== undefined && topThreeSearchResult !== null
              ? (Math.round(topThreeSearchResult * 1000) / 10).toString() + '%'
              : topThreeSearchResult,
          isLoading: isLoading
        },
        {
          title: 'Top 10',
          formattedStat:
            topTenSearchResult !== undefined && topTenSearchResult !== null
              ? (Math.round(topTenSearchResult * 1000) / 10).toString() + '%'
              : topTenSearchResult,
          isLoading: isLoading
        }
      ]
    }, [
      firstPageSearchResult,
      topThreeSearchResult,
      topTenSearchResult,
      isLoading
    ])

    return (
      <WidgetArray>
        {widgets.map((widgetProps, idx) => (
          <StatsWidget key={idx} {...widgetProps} />
        ))}
      </WidgetArray>
    )
  }
)

export default DigitalShelfAnalyticsWidgetsArray
