import React from 'react'

import palette from '../atoms/Colors'
import { Chip } from '@material-ui/core'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import Close from '@material-ui/icons/Close'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      height: 'auto',
      borderRadius: 6,
      borderColor: palette.gray[300],
      paddingTop: 2,
      paddingRight: 4,
      paddingBottom: 2,
      paddingLeft: 4,
      backgroundColor: palette.gray[50],
      '& span': {
        whiteSpace: 'normal'
      },
      fontWeight: 500,
      fontSize: theme.spacing(1.75),
      color: palette.gray[700]
    }
  })
)

interface TagListProps {
  tags: string[]
  onDelete?: (tag: string) => void
  color?: 'default' | 'primary' | 'secondary'
  disabled?: boolean
  hideDeleteButton?: boolean
}

export const TagList = (props: TagListProps) => {
  const { tags, hideDeleteButton } = props
  const classes = useStyles()

  return (
    <>
      {tags.map((tag: string) => {
        return (
          <Chip
            className={classes.chip}
            key={tag}
            label={tag}
            variant="outlined"
            size="small"
            deleteIcon={hideDeleteButton ? <></> : <Close />}
            onDelete={
              props.onDelete
                ? () => {
                    props.onDelete && props.onDelete(tag)
                  }
                : undefined
            }
            color={props.color}
            disabled={props.disabled}
          />
        )
      })}
    </>
  )
}

export default TagList
