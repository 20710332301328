import React from 'react'
import { useCallback } from 'react'

import { useHistory } from 'react-router-dom'

import { handleUserClick } from '../../../api/Analytics'

import productDescriptionIcon from '../assets/v2/product_description.svg'

import { BaseToolCard, ToolCardWithMetadata } from './BaseToolCard'

const TITLE = 'Product Descriptions (Bulk)'
const SUBTITLE =
  'Create thousands of SEO optimized product descriptions in bulk.'

const ProductDescriptionCard = () => {
  const history = useHistory()
  const onClick = useCallback(async () => {
    history.push('/product-descriptions')
    handleUserClick(
      'marketing-dashboard-product-description-card',
      window.location.pathname
    )
  }, [history])
  return (
    <BaseToolCard
      title={TITLE}
      subtitle={SUBTITLE}
      onClick={onClick}
      logoSrc={productDescriptionIcon}
    />
  )
}

const metadata = {
  id: 'product_description',
  searchTerms: [TITLE, SUBTITLE]
}

export const ProductDescriptionCardWithMetadata: ToolCardWithMetadata = {
  card: <ProductDescriptionCard />,
  metadata
}
