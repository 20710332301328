import React from 'react'

import palette from '@hypotenuse/common/src/atoms/Colors'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  makeStyles
} from '@material-ui/core'
import { Theme, createStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

interface OnboardFormTemplateProps {
  title: string
  subTitle?: string
  loading: boolean
  onSubmit?: () => void
  disableNext?: boolean
  hideNextButton?: boolean
  onBack?: () => void
}

const useStyles = makeStyles((theme: Theme) => {
  const smSize = theme.breakpoints.down('sm')
  const mdSize = theme.breakpoints.down('md')
  return createStyles({
    title: {
      marginBottom: '0.75rem',
      fontWeight: 600
    },
    helperSubtitle: {
      marginBottom: '2.5rem',
      color: palette.gray[500],
      fontWeight: 400
    },
    container: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      position: 'relative',
      overflowY: 'auto',
      overflowX: 'hidden'
    },
    form: {
      alignSelf: 'center',
      width: 'auto',
      marginTop: theme.spacing(13),
      padding: theme.spacing(0, 14),
      [smSize]: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(0, 5)
      },
      [mdSize]: {
        padding: theme.spacing(0, 6)
      }
    },
    backButton: {
      marginTop: '3rem',
      marginLeft: '3rem',
      textAlign: 'left',
      cursor: 'pointer'
    },
    nextButton: {
      fontSize: '1rem',
      width: '100%',
      maxWidth: '30rem',
      marginTop: '0.6rem'
    }
  })
})

/**
 * The OnboardFormTemplate component is a template for the onboarding forms.
 * This acts as the View in the MVC pattern for the onboarding forms.
 * Therefore, no logic should be included in this component, it should be handled by the controller in the
 * OnboardingContext or OnboardForm component.
 */
const OnboardFormTemplate: React.FC<OnboardFormTemplateProps> = (props) => {
  const classes = useStyles()
  const {
    children,
    title,
    subTitle,
    loading,
    onSubmit,
    disableNext,
    hideNextButton,
    onBack
  } = props

  const nextButton = !hideNextButton && (
    <Button
      type={'submit'}
      variant={'contained'}
      color={'primary'}
      disabled={disableNext || loading}
      className={classes.nextButton}
    >
      {loading ? <CircularProgress color="inherit" size="2rem" /> : 'Continue'}
    </Button>
  )

  return (
    <Paper className={classes.container} id="onboardingform-container">
      {onBack && (
        <div className={classes.backButton} onClick={onBack}>
          <ArrowBackIosIcon />
        </div>
      )}
      <Stack
        direction="column"
        textAlign="center"
        justifyContent="center"
        className={classes.form}
        id="form-box"
      >
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
        {subTitle && (
          <Typography variant="h6" className={classes.helperSubtitle}>
            {subTitle}
          </Typography>
        )}
        <form onSubmit={onSubmit}>
          <Box py={2}>
            <Stack
              spacing={2}
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              {children}
              {nextButton}
            </Stack>
          </Box>
        </form>
      </Stack>
    </Paper>
  )
}

export default OnboardFormTemplate
