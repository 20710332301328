import React, { useMemo } from 'react'

import { Icon } from '..'

import {
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
  Theme,
  createStyles,
  makeStyles
} from '@material-ui/core'
import clsx from 'clsx'

import {
  ButtonColor,
  ButtonSize,
  Color,
  ICON_SIZE_MAP,
  Size
} from './interfaces'

import palette from '../Colors'
import { Props as IconProps } from '../Icon'

enum IconButtonVariant {
  outlined = 'outlined',
  plain = 'plain'
}
type Variant = keyof typeof IconButtonVariant

/**
 * Map of the the custom variant to any MUIButtonProps
 */
const VARIANT_MAP: Record<IconButtonVariant, Partial<MUIButtonProps>> = {
  outlined: {
    variant: 'outlined'
  },
  plain: {
    variant: 'text'
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /**
     * Styling for all icon buttons
     */
    iconButton: {
      borderRadius: theme.spacing(0.5),
      lineHeight: 0
    },
    /**
     * Button styles for size
     */
    [ButtonSize.xxs]: {
      padding: '4px',
      minWidth: '8px', // override default MUI button minWidth
      borderRadius: theme.spacing(0.5)
    },
    [ButtonSize.xs]: {
      padding: '8px',
      minWidth: '16px', // override default MUI button minWidth
      borderRadius: theme.spacing(0.75)
    },
    [ButtonSize.sm]: {
      padding: '8px',
      minWidth: '16px', // override default MUI button minWidth
      borderRadius: theme.spacing(1)
    },
    [ButtonSize.md]: {
      padding: '10px',
      minWidth: '20px', // override default MUI button minWidth
      borderRadius: theme.spacing(1)
    },
    [ButtonSize.lg]: {
      padding: '12px',
      minWidth: '24px', // override default MUI button minWidth
      borderRadius: theme.spacing(1)
    },
    [IconButtonVariant.outlined]: {
      '&:disabled': {
        backgroundColor: palette.white,
        border: `1px solid ${palette.gray[300]}`
      }
    },
    /**
     * Button styles for type + color combination
     */
    [IconButtonVariant.outlined + ButtonColor.primary]: {
      borderColor: palette.primary[300],
      backgroundColor: palette.primary[25],
      '&:hover': {
        backgroundColor: palette.primary[50]
      }
    },
    [IconButtonVariant.outlined + ButtonColor.red]: {
      borderColor: palette.red[300],
      backgroundColor: palette.white,
      '&:hover': {
        backgroundColor: palette.red[50]
      }
    },
    [IconButtonVariant.outlined + ButtonColor.gray]: {
      borderColor: palette.gray[300],
      backgroundColor: palette.white,
      '&:hover': {
        backgroundColor: palette.gray[100]
      }
    },
    /**
     * Label styles for variant + color combination
     */
    [ButtonColor.primary]: {
      color: palette.primary[700]
    },
    [ButtonColor.red]: {
      color: palette.red[700]
    },
    [ButtonColor.gray]: {
      color: palette.gray[700]
    },
    /**
     * Disabled styles for color
     */
    disabledInternalStyling: {
      '&:disabled': {
        color: palette.gray[400]
      }
    },
    /**
     * Ensure label inherits the color defined in root.
     * This is because disabled styling is handled in root, and affects
     * the color of text and icons. We need to ensure the disabled color
     * styling is inherited by the label
     */
    inheritLabelStyling: {
      color: 'inherit'
    }
  })
)

interface Props
  extends Omit<
    MUIButtonProps,
    'variant' | 'size' | 'color' | 'startIcon' | 'endIcon'
  > {
  icon: React.ComponentType
  iconProps?: Omit<IconProps, 'icon'>
  variant: Variant
  size: Size
  color: Color
}

/**
 * IconButton component with customizable variants, sizes, and colors.
 * Based off MUI Button component (not IconButton)
 *
 * @example
 * // Basic usage
 * import { IconButton } from './IconButton';
 * import { ReactComponent as MyIcon } from './my-icon.svg';
 *
 * <IconButton
 *   icon={MyIcon}
 *   variant="outlined"
 *   size="md"
 *   color="primary"
 * />
 *
 * @example
 * // IconButton with custom icon props
 * import { IconButton } from './IconButton';
 * import { ReactComponent as MyIcon } from './my-icon.svg';
 *
 * <IconButton
 *   icon={MyIcon}
 *   iconProps={{ 'aria-label': 'My Icon' }}
 *   variant="plain"
 *   size="sm"
 *   color="gray"
 * />
 *
 * @param {React.ComponentType} icon - The component type for the icon.
 * @param {Omit<IconProps, 'icon'>} [iconProps] - Additional props for the icon.
 * @param {Variant} variant - The variant of the button.
 * @param {Size} size - The size of the button.
 * @param {Color} color - The color of the button.
 */
const IconButton: React.FC<Props> = React.memo(
  React.forwardRef<HTMLButtonElement, Props>((props: Props, ref) => {
    const {
      icon,
      iconProps,
      variant,
      size,
      color,
      classes: classesOverrides,
      ...buttonProps
    } = props
    const classes = useStyles()

    const classRootNames = useMemo(
      () =>
        [color, variant + color, size, variant].map((name) => classes[name]),
      [classes, color, size, variant]
    )

    const { root: classesRoot, label: classesLabel, ...classesRest } =
      classesOverrides ?? {}

    return (
      <MUIButton
        ref={ref}
        classes={{
          root: clsx(
            classes.iconButton,
            ...classRootNames,
            classes.disabledInternalStyling,
            classesRoot
          ),
          label: clsx(classes.inheritLabelStyling, classesLabel),
          ...classesRest
        }}
        {...VARIANT_MAP[variant]}
        {...buttonProps}
      >
        <Icon icon={icon} size={ICON_SIZE_MAP[size]} {...iconProps} />
      </MUIButton>
    )
  })
)

export default IconButton
