import React from 'react'

import { Typography, palette } from '@hypotenuse/common/src/atoms'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import { Box } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import { handleUserClick } from '@hypotenuse/common/src/api/Analytics'
import amazonOrangeIcon from '@hypotenuse/common/src/assets/amazon-orange-icon.svg'
import targetIcon from '@hypotenuse/common/src/assets/target-icon.svg'
import walmartIcon from '@hypotenuse/common/src/assets/walmart-icon.svg'

import { EcommerceContentData } from './EcommerceCardCTAModal'

////////////////////////// Content Workflows //////////////////////////
export const BulkSEOEcommContentCard = (
  setIsShowingEcommerceCardCTAModal: (value: boolean) => void,
  setEcommerceContentData: (value: EcommerceContentData) => void
) => {
  const title = 'Bulk SEO ecommerce content'
  const subtitle =
    'Create thousands of SEO-optimized content in bulk with just one click, including product descriptions, category pages, and meta tags.'
  const iconTextSrc = '✍️'
  const customFeatureBullets = [
    'Generate SEO-optimized content for your website: product descriptions, category pages, and meta tags.',
    'Scale up content creation with bulk generation capabilities, up to 10,000 at one time.',
    'For distributors: enrich missing product attributes by pulling from the web.',
    'Enrich product attributes from image.'
  ]
  const customElementIdCTA = 'fakedoor-bulk'

  const onClick = () => {
    setIsShowingEcommerceCardCTAModal(true)
    setEcommerceContentData({
      title: title,
      featureBullets: customFeatureBullets,
      elementIdCTA: customElementIdCTA
    })
    handleUserClick(customElementIdCTA, window.location.pathname)
  }

  return (
    <EcommerceTemplateCard
      title={title}
      subtitle={subtitle}
      iconTextSrc={iconTextSrc}
      onClick={onClick}
    />
  )
}
export const RetailersCompliantCard = (
  setIsShowingEcommerceCardCTAModal: (value: boolean) => void,
  setEcommerceContentData: (value: EcommerceContentData) => void
) => {
  const title = 'Retailers compliant'
  const subtitle =
    'Generate product copy that meets content requirements for ecommerce retailers like Amazon, Target, Walmart and more.'
  const customFeatureBullets = [
    'Streamline content for platforms like Amazon, Target, Walmart, Etsy, and other custom retailers.',
    'Ensure product copy adheres to various ecommerce retailer standards.',
    'AI evaluator to check if content meets requirements.'
  ]
  const customElementIdCTA = 'fakedoor-retailers'

  const onClick = () => {
    setIsShowingEcommerceCardCTAModal(true)
    setEcommerceContentData({
      title: title,
      featureBullets: customFeatureBullets,
      elementIdCTA: customElementIdCTA
    })
    handleUserClick(customElementIdCTA, window.location.pathname)
  }
  return (
    <EcommerceTemplateCard
      title={title}
      subtitle={subtitle}
      iconImgList={[amazonOrangeIcon, targetIcon, walmartIcon]}
      onClick={onClick}
    />
  )
}
export const KeywordFinderCard = (
  setIsShowingEcommerceCardCTAModal: (value: boolean) => void,
  setEcommerceContentData: (value: EcommerceContentData) => void
) => {
  const title = 'Keyword finder'
  const subtitle =
    'Recommend SEO keywords for each product and create descriptions that uses comp analysis. Available for Google and ecommerce retailers.'
  const iconTextSrc = '⚡'
  const customFeatureBullets = [
    'Suggests primary SEO keywords for each product detail page.',
    'Find related keywords from competitor pages that ranks on Google SERP.',
    'Supports SEO for all your sales channels like Amazon, Target, Walmart, Etsy, and other custom retailers.'
  ]
  const customElementIdCTA = 'fakedoor-keywordfinder'

  const onClick = () => {
    setIsShowingEcommerceCardCTAModal(true)
    setEcommerceContentData({
      title: title,
      featureBullets: customFeatureBullets,
      elementIdCTA: customElementIdCTA
    })
    handleUserClick(customElementIdCTA, window.location.pathname)
  }

  return (
    <EcommerceTemplateCard
      title={title}
      subtitle={subtitle}
      iconTextSrc={iconTextSrc}
      onClick={onClick}
    />
  )
}
export const BespokeWritersCard = (
  setIsShowingEcommerceCardCTAModal: (value: boolean) => void,
  setEcommerceContentData: (value: EcommerceContentData) => void
) => {
  const title = 'Bespoke writers'
  const subtitle =
    'Bespoke brand voice tailored to your brand guidelines. Custom formatting, length, vocabulary etc.'
  const iconTextSrc = '😃'
  const customFeatureBullets = [
    'Custom trained AI model catered to your unique brand voice. Used by fortune 500 brands.',
    'AI learns through in-depth training using past content and a collaborative feedback loop.',
    'Precise style guide such as banned words, punctuation, and other stylistic elements.',
    'Custom formatting such as bullet points, word length.',
    'Understand your team and company’s knowledge.'
  ]
  const customElementIdCTA = 'fakedoor-bespokewriters'

  const onClick = () => {
    setIsShowingEcommerceCardCTAModal(true)
    setEcommerceContentData({
      title: title,
      featureBullets: customFeatureBullets,
      elementIdCTA: customElementIdCTA
    })
    handleUserClick(customElementIdCTA, window.location.pathname)
  }

  return (
    <EcommerceTemplateCard
      title={title}
      subtitle={subtitle}
      iconTextSrc={iconTextSrc}
      onClick={onClick}
    />
  )
}
export const CustomReviewWorkflowCard = (
  setIsShowingEcommerceCardCTAModal: (value: boolean) => void,
  setEcommerceContentData: (value: EcommerceContentData) => void
) => {
  const title = 'Custom review workflows'
  const subtitle =
    'Modular editing and approval workflows to fit your organization’s needs.'
  const iconTextSrc = '✅'
  const customFeatureBullets = [
    'Implement modular editing and approval workflows to suit your business structure.',
    'Streamline collaborative content creation and revision processes.',
    'Optimize team efficiency with structured content review stages.'
  ]
  const customElementIdCTA = 'fakedoor-reviewworkflows'

  const onClick = () => {
    setIsShowingEcommerceCardCTAModal(true)
    setEcommerceContentData({
      title: title,
      featureBullets: customFeatureBullets,
      elementIdCTA: customElementIdCTA
    })
    handleUserClick(customElementIdCTA, window.location.pathname)
  }

  return (
    <EcommerceTemplateCard
      title={title}
      subtitle={subtitle}
      iconTextSrc={iconTextSrc}
      onClick={onClick}
    />
  )
}
////////////////////////// Asset Workflows //////////////////////////
export const DigitalAssetManagementCard = (
  setIsShowingEcommerceCardCTAModal: (value: boolean) => void,
  setEcommerceContentData: (value: EcommerceContentData) => void
) => {
  const title = 'Digital asset management (DAM)'
  const subtitle =
    'Cloud storage and tagging system to manage your product assets.'
  const iconTextSrc = '🖼️'
  const customFeatureBullets = [
    'Securely store and organize product assets with cloud-based tagging systems.',
    'Simplify asset retrieval with an intuitive management platform.',
    'Automatically tag images with AI.'
  ]
  const customElementIdCTA = 'fakedoor-dam'

  const onClick = () => {
    setIsShowingEcommerceCardCTAModal(true)
    setEcommerceContentData({
      title: title,
      featureBullets: customFeatureBullets,
      elementIdCTA: customElementIdCTA
    })
    handleUserClick(customElementIdCTA, window.location.pathname)
  }

  return (
    <EcommerceTemplateCard
      title={title}
      subtitle={subtitle}
      iconTextSrc={iconTextSrc}
      onClick={onClick}
    />
  )
}
export const BulkAIImageEditorCard = (
  setIsShowingEcommerceCardCTAModal: (value: boolean) => void,
  setEcommerceContentData: (value: EcommerceContentData) => void
) => {
  const title = 'Bulk AI image editor'
  const subtitle =
    'Automatic removal or changing of background, resize image, add text to image.'
  const iconTextSrc = '🧙‍♀️'
  const customFeatureBullets = [
    'Image background generator.',
    'Add texts to images.',
    'Batch resizing of images.'
  ]
  const customElementIdCTA = 'fakedoor-aieditor'

  const onClick = () => {
    setIsShowingEcommerceCardCTAModal(true)
    setEcommerceContentData({
      title: title,
      featureBullets: customFeatureBullets,
      elementIdCTA: customElementIdCTA
    })
    handleUserClick(customElementIdCTA, window.location.pathname)
  }

  return (
    <EcommerceTemplateCard
      title={title}
      subtitle={subtitle}
      iconTextSrc={iconTextSrc}
      onClick={onClick}
    />
  )
}
export const ImageSEOCard = (
  setIsShowingEcommerceCardCTAModal: (value: boolean) => void,
  setEcommerceContentData: (value: EcommerceContentData) => void
) => {
  const title = 'Image SEO'
  const subtitle =
    'Bulk edit image alt text and file name following SEO best practices.'
  const iconTextSrc = '🪄'
  const customFeatureBullets = [
    'Manage image alt text and filenames in bulk, following SEO best practices.',
    'Improve image search rankings and web visibility.',
    'Streamline the optimization of digital assets for better search engine performance.'
  ]
  const customElementIdCTA = 'fakedoor-imageseo'

  const onClick = () => {
    setIsShowingEcommerceCardCTAModal(true)
    setEcommerceContentData({
      title: title,
      featureBullets: customFeatureBullets,
      elementIdCTA: customElementIdCTA
    })
    handleUserClick(customElementIdCTA, window.location.pathname)
  }

  return (
    <EcommerceTemplateCard
      title={title}
      subtitle={subtitle}
      iconTextSrc={iconTextSrc}
      onClick={onClick}
    />
  )
}

////////////////////////// Tracking features //////////////////////////
export const PerformanceMonitoringCard = (
  setIsShowingEcommerceCardCTAModal: (value: boolean) => void,
  setEcommerceContentData: (value: EcommerceContentData) => void
) => {
  const title = 'Performance monitoring'
  const subtitle =
    'Track how your products are performing on Google SERP and digital shelf visbility.'
  const iconTextSrc = '📊'
  const customFeatureBullets = [
    'Monitor product performance on Google SERP and digital shelves like Amazon, Target, Walmart, Etsy, and other custom retailers.',
    'Track ranking of your product listing and share of search.',
    'Content gap analysis to identify which products to optimize for.'
  ]
  const customElementIdCTA = 'fakedoor-performancemonitoring'

  const onClick = () => {
    setIsShowingEcommerceCardCTAModal(true)
    setEcommerceContentData({
      title: title,
      featureBullets: customFeatureBullets,
      elementIdCTA: customElementIdCTA
    })
    handleUserClick(customElementIdCTA, window.location.pathname)
  }

  return (
    <EcommerceTemplateCard
      title={title}
      subtitle={subtitle}
      iconTextSrc={iconTextSrc}
      onClick={onClick}
    />
  )
}
export const ProductivityReportingCard = (
  setIsShowingEcommerceCardCTAModal: (value: boolean) => void,
  setEcommerceContentData: (value: EcommerceContentData) => void
) => {
  const title = 'Productivity reporting'
  const subtitle =
    'Track how long it takes from content and assets to move from one stage to another.'
  const iconTextSrc = '🏋️‍♀️'
  const customFeatureBullets = [
    'Track the progress and duration of content and asset stages.',
    'Analyze team productivity and streamline content lifecycle management.',
    'Make data-driven decisions to improve operational workflows.'
  ]
  const customElementIdCTA = 'fakedoor-productivitytracking'

  const onClick = () => {
    setIsShowingEcommerceCardCTAModal(true)
    setEcommerceContentData({
      title: title,
      featureBullets: customFeatureBullets,
      elementIdCTA: customElementIdCTA
    })
    handleUserClick(customElementIdCTA, window.location.pathname)
  }

  return (
    <EcommerceTemplateCard
      title={title}
      subtitle={subtitle}
      iconTextSrc={iconTextSrc}
      onClick={onClick}
    />
  )
}
export const EnterpriseGradeSecurityCard = (
  setIsShowingEcommerceCardCTAModal: (value: boolean) => void,
  setEcommerceContentData: (value: EcommerceContentData) => void
) => {
  const title = 'Enterprise grade security'
  const subtitle =
    'You own and control your business data, our general models don’t learn from your usage.'
  const iconTextSrc = '🔐'
  const customFeatureBullets = [
    'Maintain complete ownership and control over your business data.',
    'Rely on secure systems that protect your proprietary content.',
    'Ensure that your usage does not contribute to general model learning.'
  ]
  const customElementIdCTA = 'fakedoor-security'

  const onClick = () => {
    setIsShowingEcommerceCardCTAModal(true)
    setEcommerceContentData({
      title: title,
      featureBullets: customFeatureBullets,
      elementIdCTA: customElementIdCTA
    })
    handleUserClick(customElementIdCTA, window.location.pathname)
  }

  return (
    <EcommerceTemplateCard
      title={title}
      subtitle={subtitle}
      iconTextSrc={iconTextSrc}
      onClick={onClick}
    />
  )
}

/////////////////////////////////////////////////////////////////////

const useStyles = makeStyles((theme) =>
  createStyles({
    mainEcommCard: {
      transition: 'all 0.2s ease-out',
      '&:hover': {
        boxShadow: '0px 4px 8px rgba(145 158 171 / 16%)'
      },
      boxShadow: theme.shadows[4],
      borderRadius: theme.shape.borderRadius,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: palette.gray[300],
      padding: theme.spacing(2, 2.4),
      backgroundColor: palette.white,
      width: '100%',
      height: '100%',
      cursor: 'pointer'
    }
  })
)

interface Props {
  title: string
  subtitle: string
  iconImgSrc?: string
  iconTextSrc?: string
  iconImgList?: string[]
  onClick: () => void
}

export const EcommerceTemplateCard = (props: Props) => {
  const {
    title,
    subtitle,
    iconImgSrc,
    iconTextSrc,
    iconImgList,
    onClick
  } = props

  const classes = useStyles()

  return (
    <Stack className={classes.mainEcommCard} onClick={onClick}>
      <Box display={'flex'} justifyContent={'left'}>
        {iconImgList ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              position: 'relative'
            }}
          >
            {iconImgList.map((src, index) => (
              <span
                key={index}
                style={{
                  position: 'relative',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '54px',
                  height: '54px',
                  borderRadius: '50%',
                  backgroundColor: palette.gray[100],
                  overflow: 'hidden',
                  marginLeft: index === 0 ? '0' : '-20px',
                  zIndex: iconImgList.length - index
                }}
              >
                <img
                  src={src}
                  alt="tool-icon"
                  style={{
                    width: 32,
                    height: 32,
                    objectFit: 'cover'
                  }}
                />
              </span>
            ))}
          </div>
        ) : iconImgSrc ? (
          <span
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '54px',
              height: '54px',
              borderRadius: '50%',
              backgroundColor: palette.gray[100]
            }}
          >
            <img
              src={iconImgSrc}
              alt="tool-icon"
              style={{
                width: 32,
                height: 32,
                objectFit: 'cover'
              }}
            />
          </span>
        ) : (
          <span
            style={{
              fontSize: 36,
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '54px',
              height: '54px',
              borderRadius: '50%',
              backgroundColor: palette.gray[100]
            }}
          >
            {iconTextSrc ? iconTextSrc : '?'}
          </span>
        )}
      </Box>
      <Stack style={{ position: 'relative' }}>
        <Stack textAlign="left" mt={1.5}>
          <Typography variant="paragraph1Bold">{title}</Typography>
          <Typography
            variant="paragraph2Reg"
            style={{
              color: palette.gray['500']
            }}
          >
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
