import React, { useEffect, useRef } from 'react'

import { useAnalytics } from '@hypotenuse/common/src/analytics/Analytics'
import UnexpectedError from '@hypotenuse/common/src/components/error/UnexpectedError'

/**
 * Component that's rendered as a fallback at error boundaries
 */
export const ErrorFallback: React.FC = () => {
  const { trackEvent } = useAnalytics()

  const hasSentEventRef = useRef<boolean>(false) // Ref to prevent sending analytics event multiple times
  useEffect(() => {
    if (!hasSentEventRef.current) {
      trackEvent('user', 'Error Encountered')
      hasSentEventRef.current = true
    }
  }, [trackEvent])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '70vh'
      }}
    >
      <UnexpectedError />
    </div>
  )
}

export default ErrorFallback
