import React from 'react'

import palette from '../atoms/Colors'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import { Box } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

interface LimitIndicatorProps {
  charMaxLimit: number
  valueLength: number
}

export default function LimitIndicator(props: LimitIndicatorProps) {
  const { charMaxLimit, valueLength } = props
  const theme = useTheme()
  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      style={{ fontSize: theme.spacing(1.5) }}
    >
      <Typography
        variant="caption"
        style={{
          color:
            valueLength > charMaxLimit ? palette.error[500] : palette.gray[500]
        }}
      >
        {valueLength}/{charMaxLimit}
      </Typography>
    </Box>
  )
}
