export interface TrackedProduct {
  id: string
  product_title: string
  external_product_id: string
}

export interface TrackedProductWithStats extends TrackedProduct {
  rank?: number
  content_quality?: number
}

export interface ProductTrackingKeyword {
  id: string
  keyword: string
}

export interface ProductSet {
  id: string
  name: string
  selected_keyword_id?: string
  platform: string
  user_id: string
  organization_id: string
}

export interface ProductSetInResponse extends ProductSet {
  products: TrackedProduct[]
  keywords: ProductTrackingKeyword[]
}

export interface ProductStats {
  rank?: number
  content_quality?: number
}

export interface ProductStatsInResponse extends ProductStats {
  product_id: string
}

export interface KeywordStats {
  first_page_share_of_search?: number
  top_three_share_of_search?: number
  top_ten_share_of_search?: number
}

export interface ProductSetStatsInResponse extends KeywordStats {
  product_stats: ProductStatsInResponse[]
  keyword: string
  keyword_id: string
}

export interface ProductSetsStats extends KeywordStats {
  /*
   * The key is the product id
   * The value is the stats for that product
   */
  productStats: Record<string, ProductStats>
}

export enum ECommercePlatforms {
  WALMART = 'walmart',
  TARGET = 'target',
  AMAZON = 'amazon'
}

export enum PlatformsToScrape {
  GOOGLE = 'google',
  WALMART = 'walmart',
  TARGET = 'target',
  AMAZON = 'amazon'
}
export enum Retailers {
  WALMART = 'walmart',
  US_FOODS = 'us_foods',
  KROGER = 'kroger'
}
