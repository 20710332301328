import React, { useCallback, useRef } from 'react'

import * as Sentry from '@sentry/react'
import { CheckmarkCircle } from '@styled-icons/fluentui-system-filled/CheckmarkCircle'
import { shuffle } from 'lodash-es'
import useSWR from 'swr'

import palette from '@hypotenuse/common/src/atoms/Colors'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Theme
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { ClassNameMap } from '@material-ui/styles/withStyles'
import clsx from 'clsx'

import plan_cancellation_icon from '@hypotenuse/common/src/assets/plan-cancellation-icon.svg'
import EnhancedButton from '@hypotenuse/common/src/components/EnhancedButton'
import { LightTooltipWithPadding } from '@hypotenuse/common/src/components/StyledTooltips'
import {
  CURRENCY_SYMBOL_MAPPING,
  CancellationReason,
  PlanBillingPeriod,
  PlanType
} from '@hypotenuse/common/src/utils/Interfaces'

import { fetchUserBillingInfo } from '../../../../../api/Billing'

import {
  CancellationReasonLabel,
  PlanChangeType,
  PremiumPlanTier,
  StripeBillingInfo,
  StripeSubscriptionStatus
} from '../../../../utils/Interfaces'

import genericPlanIcon from '../../../../../assets/icons/generic-plan-icon.svg'
import planChangeIcon from '../../../../../assets/icons/plan-change-icon.svg'
import { BaseDialogContentProps } from '../PricingPage'

interface ChangePlanDialogProps extends BaseDialogContentProps {
  openDialog: boolean
  orgPlanTierList: PremiumPlanTier[]
  activePlanTier: PremiumPlanTier
  userCountryCode: string
  /**
   * Ensure that setOpenPauseDialog is defined if cancel plan dialog is to be shown.
   */
  setOpenPauseDialog?: (open: boolean) => void
  /**
   * Ensure that onOpenDiscountDialog is defined if discount plan dialog is to be shown.
   */
  onOpenDiscountDialog?: () => void
  isPromotionCountryCode: boolean
  theme: Theme
  selectedReason: CancellationReason | undefined
  setSelectedReason: (reason: CancellationReason) => void
  cancellationDescription: string
  setCancellationDescription: (desc: string) => void
  classes: ClassNameMap<string>
  nextBillingDate?: Date
}

const CANCELLATION_REASONS: CancellationReason[] = [
  CancellationReason.difficult_to_use,
  CancellationReason.i_cant_afford,
  CancellationReason.missing_features_i_need,
  CancellationReason.dont_need_it_anymore,
  CancellationReason.bad_content_quality,
  CancellationReason.will_be_back
]
// Pre-shuffle cancellation reason options.
// The shuffling is done outside a component function so that the order of
// the options remains constant across a session.
const SHUFFLED_CANCELLATION_REASON_OPTIONS: CancellationReason[] = shuffle(
  CANCELLATION_REASONS
)
// The 'others' option is always the last one.
SHUFFLED_CANCELLATION_REASON_OPTIONS.push(CancellationReason.others)

// TODO: split up this component into separate dialogs in the future
// Reason: this component is getting too large, complex and difficult to understand
// At the same time, we may want to relook updateUserPlan and split that up as well
const ChangePlanDialog = React.memo((props: ChangePlanDialogProps) => {
  const {
    openDialog,
    setOpenDialog,
    loadingUpdate,
    selectedPlanTierId,
    isWordsFeatureEnabled,
    orgPlanTierList,
    activePlanTier,
    userCountryCode,
    setOpenPauseDialog = () => {},
    onOpenDiscountDialog,
    updateUserPlan,
    isPromotionCountryCode,
    theme,
    selectedReason,
    setSelectedReason,
    setCancellationDescription,
    cancellationDescription,
    classes,
    nextBillingDate
  } = props

  const textFieldRef = useRef<HTMLDivElement | null>(null)
  const { data: billingInfo } = useSWR(
    'stripeBillingInfo',
    fetchUserBillingInfo
  )

  return (
    <Dialog open={openDialog} maxWidth="sm">
      <>
        {openDialog &&
          orgPlanTierList &&
          activePlanTier &&
          renderDialogInformation({
            orgPlanTierList,
            selectedPlanTierId,
            activePlanTier,
            isWordsFeatureEnabled,
            userCountryCode,
            isPromotionCountryCode,
            loadingUpdate,
            setOpenDialog,
            setOpenPauseDialog,
            onOpenDiscountDialog,
            updateUserPlan,
            theme,
            selectedReason,
            setSelectedReason,
            setCancellationDescription,
            textFieldRef,
            cancellationDescription,
            classes,
            nextBillingDate,
            billingInfo
          })}
      </>
    </Dialog>
  )
})

interface DialogInformationProps extends BaseDialogContentProps {
  orgPlanTierList: PremiumPlanTier[]
  activePlanTier: PremiumPlanTier
  userCountryCode: string
  setOpenPauseDialog: (open: boolean) => void
  onOpenDiscountDialog?: () => void
  isPromotionCountryCode: boolean
  theme: Theme
  selectedReason: CancellationReason | undefined
  setSelectedReason: (reason: CancellationReason) => void
  setCancellationDescription: (desc: string) => void
  textFieldRef: React.MutableRefObject<HTMLDivElement | null>
  cancellationDescription: string
  classes: ClassNameMap<string>
  nextBillingDate?: Date
  billingInfo?: StripeBillingInfo
}

const renderDialogInformation = (
  dialogInformationProps: DialogInformationProps
) => {
  const {
    orgPlanTierList,
    selectedPlanTierId,
    activePlanTier,
    isWordsFeatureEnabled,
    userCountryCode,
    setOpenPauseDialog,
    onOpenDiscountDialog,
    isPromotionCountryCode,
    loadingUpdate,
    setOpenDialog,
    updateUserPlan,
    theme,
    selectedReason,
    setSelectedReason,
    setCancellationDescription,
    textFieldRef,
    cancellationDescription,
    classes,
    nextBillingDate,
    billingInfo
  } = dialogInformationProps

  // The new plan tier that the user is changing to
  // If the user is resubscribing to their original plan, we want to set the new plan tier to be the active plan tier
  // This is because their original plan may not exist in orgPlanTierList
  // If the user is changing to a new plan, they should only be able to select from the plans in orgPlanTierList
  const newPlanTier =
    selectedPlanTierId === activePlanTier.plan_id
      ? activePlanTier
      : orgPlanTierList?.find(
          (planTier) => planTier.plan_id === selectedPlanTierId
        )

  // Some checks for Typescript purposes
  if (!activePlanTier || !newPlanTier) {
    console.error(
      'ChangePlanDialog: activePlanTier or newPlanTier is undefined '
    )
    Sentry.captureMessage(
      'ChangePlanDialog: activePlanTier or newPlanTier is undefined',
      'error'
    )
    return
  }

  const nextBillingDateString = nextBillingDate?.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  })

  const changeSelectedReason = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reason: CancellationReason = event.target.value as CancellationReason
    setSelectedReason(reason)
    // Regardless of the cancellation reason, we want the user to provide a description
    // to better understand their reasons for unsubscribing/cancelling
    textFieldRef.current?.focus()
  }

  const newPlanMonthlyCurrencies = isWordsFeatureEnabled
    ? newPlanTier?.plan_monthly_chip_amount ?? 0
    : newPlanTier.plan_monthly_credit_amount
  const newPlanMonthlyBilledAmount =
    newPlanTier.plan_price_mapping?.[userCountryCode] && isPromotionCountryCode
      ? newPlanTier.plan_price_mapping[userCountryCode]?.price
      : newPlanTier.plan_price

  // Computing states required for rendering dialog information
  const currentPlanChange = computePlanChange(
    newPlanTier,
    newPlanMonthlyCurrencies,
    activePlanTier,
    isWordsFeatureEnabled
  )

  if (
    currentPlanChange !== PlanChangeType.cancellation &&
    billingInfo?.subscription_info.status ===
      StripeSubscriptionStatus.trialing &&
    currentPlanChange
  ) {
    return (
      <ResetBillingCycleDialogContent
        newPlanTier={newPlanTier}
        newPlanMonthlyCurrencies={newPlanMonthlyCurrencies}
        newPlanMonthlyBilledAmount={newPlanMonthlyBilledAmount}
        planChangeType={currentPlanChange}
        userCountryCode={userCountryCode}
        isPromotionCountryCode={isPromotionCountryCode}
        theme={theme}
        loadingUpdate={loadingUpdate}
        setOpenDialog={setOpenDialog}
        isWordsFeatureEnabled={isWordsFeatureEnabled}
        selectedPlanTierId={selectedPlanTierId}
        updateUserPlan={updateUserPlan}
      />
    )
  }

  switch (currentPlanChange) {
    case PlanChangeType.resume_subscription: {
      return (
        <ResumeBillingCycleDialogContent
          newPlanTier={newPlanTier}
          newPlanMonthlyCurrencies={newPlanMonthlyCurrencies}
          newPlanMonthlyBilledAmount={newPlanMonthlyBilledAmount}
          planChangeType={currentPlanChange}
          userCountryCode={userCountryCode}
          isPromotionCountryCode={isPromotionCountryCode}
          theme={theme}
          loadingUpdate={loadingUpdate}
          setOpenDialog={setOpenDialog}
          isWordsFeatureEnabled={isWordsFeatureEnabled}
          selectedPlanTierId={selectedPlanTierId}
          updateUserPlan={updateUserPlan}
          activePlanTier={activePlanTier}
          nextBillingDateString={nextBillingDateString}
        />
      )
    }
    case PlanChangeType.cancellation: {
      return (
        <CancelPlanDialogContent
          loadingUpdate={loadingUpdate}
          isWordsFeatureEnabled={isWordsFeatureEnabled}
          setOpenDialog={setOpenDialog}
          setOpenPauseDialog={setOpenPauseDialog}
          onOpenDiscountDialog={onOpenDiscountDialog}
          selectedReason={selectedReason}
          changeSelectedReason={changeSelectedReason}
          setCancellationDescription={setCancellationDescription}
          textFieldRef={textFieldRef}
          cancellationDescription={cancellationDescription}
          selectedPlanTierId={selectedPlanTierId}
          updateUserPlan={updateUserPlan}
          activePlanTier={activePlanTier}
          classes={classes}
          nextBillingDateString={nextBillingDateString}
        />
      )
    }
    case PlanChangeType.new_subscription:
    case PlanChangeType.monthlyToMonthlyUpgrade:
    case PlanChangeType.monthlyToAnnualUpgrade:
    case PlanChangeType.monthlyToAnnualDowngrade: {
      return (
        <ResetBillingCycleDialogContent
          newPlanTier={newPlanTier}
          newPlanMonthlyCurrencies={newPlanMonthlyCurrencies}
          newPlanMonthlyBilledAmount={newPlanMonthlyBilledAmount}
          planChangeType={currentPlanChange}
          userCountryCode={userCountryCode}
          isPromotionCountryCode={isPromotionCountryCode}
          theme={theme}
          loadingUpdate={loadingUpdate}
          setOpenDialog={setOpenDialog}
          isWordsFeatureEnabled={isWordsFeatureEnabled}
          selectedPlanTierId={selectedPlanTierId}
          updateUserPlan={updateUserPlan}
        />
      )
    }
    case PlanChangeType.annualToAnnualUpgrade: {
      return (
        <KeepBillingCycleDialogContent
          newPlanTier={newPlanTier}
          newPlanMonthlyCurrencies={newPlanMonthlyCurrencies}
          newPlanMonthlyBilledAmount={newPlanMonthlyBilledAmount}
          planChangeType={currentPlanChange}
          userCountryCode={userCountryCode}
          isPromotionCountryCode={isPromotionCountryCode}
          theme={theme}
          loadingUpdate={loadingUpdate}
          setOpenDialog={setOpenDialog}
          isWordsFeatureEnabled={isWordsFeatureEnabled}
          selectedPlanTierId={selectedPlanTierId}
          updateUserPlan={updateUserPlan}
          nextBillingDateString={nextBillingDateString}
        />
      )
    }
    case PlanChangeType.annualToAnnualDowngrade:
    case PlanChangeType.annualToMonthlyUpgrade:
    case PlanChangeType.annualToMonthlyDowngrade:
    case PlanChangeType.monthlyToMonthlyDowngrade: {
      return (
        <SchedulePlanChangeDialogContent
          newPlanTier={newPlanTier}
          newPlanMonthlyBilledAmount={newPlanMonthlyBilledAmount}
          planChangeType={currentPlanChange}
          nextBillingDate={nextBillingDate}
          userCountryCode={userCountryCode}
          isPromotionCountryCode={isPromotionCountryCode}
          loadingUpdate={loadingUpdate}
          setOpenDialog={setOpenDialog}
          isWordsFeatureEnabled={isWordsFeatureEnabled}
          selectedPlanTierId={selectedPlanTierId}
          updateUserPlan={updateUserPlan}
          theme={theme}
          nextBillingDateString={nextBillingDateString}
          newPlanMonthlyCurrencies={newPlanMonthlyCurrencies}
        />
      )
    }
    default: {
      return (
        <KeepBillingCycleDialogContent
          newPlanTier={newPlanTier}
          newPlanMonthlyCurrencies={newPlanMonthlyCurrencies}
          newPlanMonthlyBilledAmount={newPlanMonthlyBilledAmount}
          planChangeType={
            currentPlanChange ?? PlanChangeType.monthlyToMonthlyDowngrade
          }
          userCountryCode={userCountryCode}
          isPromotionCountryCode={isPromotionCountryCode}
          theme={theme}
          loadingUpdate={loadingUpdate}
          setOpenDialog={setOpenDialog}
          isWordsFeatureEnabled={isWordsFeatureEnabled}
          selectedPlanTierId={selectedPlanTierId}
          updateUserPlan={updateUserPlan}
          nextBillingDateString={nextBillingDateString}
        />
      )
    }
  }
}

/**
 * Returns the billed amount and currency symbol used to display the information
 * Priority is on the plan_price_mapping attribute, if it does not exist, we use the old attribute
 */
const getMonthlyAmountAndCurrencySymbol = (
  newPlanTier: PremiumPlanTier,
  userCountryCode: string,
  newPlanMonthlyBilledAmount: number,
  isNewPlanYearly: boolean,
  isPromotionCountryCode: boolean
) => {
  const billedAmountMonthly =
    newPlanTier.plan_price_mapping?.[userCountryCode] && isPromotionCountryCode
      ? newPlanTier.plan_price_mapping[userCountryCode]?.price
      : newPlanMonthlyBilledAmount

  const planCurrency =
    newPlanTier.plan_price_mapping?.[userCountryCode] && isPromotionCountryCode
      ? newPlanTier.plan_price_mapping[userCountryCode]?.currency.toLowerCase()
      : newPlanTier.currency.toLowerCase()

  const currencySymbol = CURRENCY_SYMBOL_MAPPING[planCurrency] || 'USD$'

  const billedAmountToday = isNewPlanYearly
    ? billedAmountMonthly * 12
    : billedAmountMonthly

  return { billedAmountToday, currencySymbol }
}

const planBannerInDialog = (
  newPlanTier: PremiumPlanTier,
  newPlanMonthlyBilledAmount: number,
  currencySymbol: string,
  theme: Theme
) => {
  const isNewPlanYearly =
    newPlanTier.plan_billing_period === PlanBillingPeriod.yearly

  return (
    <Stack
      direction="row"
      bgcolor={palette.primary[50]}
      borderColor={palette.primary[500]}
      color={theme.palette.primary.dark}
      border={1}
      borderRadius={10}
      p={2}
      spacing={1}
      width="100%"
      alignContent="center"
      mb={2}
    >
      <img src={genericPlanIcon} alt="generic plan" />
      <Stack justifyContent="center">
        <Typography variant="body1">
          <b style={{ fontWeight: 600 }}>
            {newPlanTier.plan_display_name} plan
          </b>{' '}
          {currencySymbol}
          {newPlanMonthlyBilledAmount}/month
        </Typography>
        <Typography variant="body1">
          {isNewPlanYearly ? 'Billed annually' : 'Billed monthly'}
        </Typography>
      </Stack>
      <CheckmarkCircle width={'1.5rem'} style={{ marginLeft: 'auto' }} />
    </Stack>
  )
}

const computePlanChange = (
  newPlanTier: PremiumPlanTier,
  newMonthlyCurrencies: number,
  activePlanTier: PremiumPlanTier,
  isWordsFeatureEnabled: boolean
) => {
  const isPaused = activePlanTier.is_paused
  const isFreePlan = activePlanTier.plan_type === PlanType.free
  const isMonthlyToMonthly =
    newPlanTier.plan_billing_period === PlanBillingPeriod.monthly &&
    activePlanTier.plan_billing_period === PlanBillingPeriod.monthly

  const isMonthlyToAnnual =
    newPlanTier.plan_billing_period === PlanBillingPeriod.yearly &&
    activePlanTier.plan_billing_period === PlanBillingPeriod.monthly

  const isAnnualToMonthly =
    newPlanTier.plan_billing_period === PlanBillingPeriod.monthly &&
    activePlanTier.plan_billing_period === PlanBillingPeriod.yearly

  const isAnnualToAnnual =
    newPlanTier.plan_billing_period === PlanBillingPeriod.yearly &&
    activePlanTier.plan_billing_period === PlanBillingPeriod.yearly

  const isMoreOrEqualCurrencies =
    newPlanTier.plan_type === PlanType.unlimited ||
    (isWordsFeatureEnabled
      ? newPlanTier &&
        newMonthlyCurrencies &&
        activePlanTier?.plan_monthly_chip_amount &&
        newMonthlyCurrencies >= activePlanTier.plan_monthly_chip_amount
      : newPlanTier &&
        newMonthlyCurrencies &&
        newMonthlyCurrencies >= activePlanTier.plan_monthly_credit_amount)

  if (isPaused) {
    return PlanChangeType.resume_subscription
  }
  if (newPlanTier.plan_type === PlanType.free) {
    return PlanChangeType.cancellation
  }
  if (isFreePlan) {
    return PlanChangeType.new_subscription
  }

  if (isMoreOrEqualCurrencies) {
    if (isMonthlyToMonthly) {
      return PlanChangeType.monthlyToMonthlyUpgrade
    }
    if (isMonthlyToAnnual) {
      return PlanChangeType.monthlyToAnnualUpgrade
    }
    if (isAnnualToMonthly) {
      return PlanChangeType.annualToMonthlyUpgrade
    }
    if (isAnnualToAnnual) {
      return PlanChangeType.annualToAnnualUpgrade
    }
  } else {
    if (isMonthlyToMonthly) {
      return PlanChangeType.monthlyToMonthlyDowngrade
    }
    if (isMonthlyToAnnual) {
      return PlanChangeType.monthlyToAnnualDowngrade
    }
    if (isAnnualToMonthly) {
      return PlanChangeType.annualToMonthlyDowngrade
    }
    if (isAnnualToAnnual) {
      return PlanChangeType.annualToAnnualDowngrade
    }
  }

  Sentry.captureMessage(
    'ComputePlanChange: No plan change type found for the given parameters',
    'error'
  )
}

interface ResumeBillingCycleDialogContentProps extends BaseDialogContentProps {
  newPlanTier: PremiumPlanTier
  newPlanMonthlyCurrencies: number
  newPlanMonthlyBilledAmount: number
  planChangeType: PlanChangeType
  userCountryCode: string
  isPromotionCountryCode: boolean
  theme: Theme
  activePlanTier: PremiumPlanTier
  nextBillingDateString?: string
}

const ResumeBillingCycleDialogContent = React.memo(
  (
    resumeBillingCycleDialogContentProps: ResumeBillingCycleDialogContentProps
  ) => {
    const {
      newPlanTier,
      newPlanMonthlyCurrencies,
      newPlanMonthlyBilledAmount,
      planChangeType,
      userCountryCode,
      isPromotionCountryCode,
      theme,
      loadingUpdate,
      setOpenDialog,
      isWordsFeatureEnabled,
      selectedPlanTierId,
      updateUserPlan,
      activePlanTier,
      nextBillingDateString
    } = resumeBillingCycleDialogContentProps
    const isNewPlanYearly =
      newPlanTier.plan_billing_period === PlanBillingPeriod.yearly

    const {
      billedAmountToday,
      currencySymbol
    } = getMonthlyAmountAndCurrencySymbol(
      newPlanTier,
      userCountryCode,
      newPlanMonthlyBilledAmount,
      isNewPlanYearly,
      isPromotionCountryCode
    )

    // If this model is rendered, it means that the plan is paused
    // and the user is resuming the plan
    // We want to know two things now:
    // 1. If the user is resuming the same plan or a different plan
    // 2. If the current plan has been paused
    const isSamePlan = activePlanTier.paused_plan_id === newPlanTier.plan_id
    const planHasPaused = activePlanTier.plan_type === PlanType.free

    return (
      <>
        <DialogContent>
          <Stack spacing={2} pb={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box>
                <img
                  src={planChangeIcon}
                  alt="plan change"
                  style={{ margin: 'auto' }}
                />
              </Box>
              <IconButton
                onClick={() => {
                  if (!loadingUpdate) {
                    setOpenDialog(false)
                  }
                }}
                style={{ height: '10%', padding: '5px' }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography variant="h6">
              Resume
              {!isSamePlan && ' & change'} plan
            </Typography>
            {isSamePlan && planHasPaused ? (
              <>
                <Typography
                  variant="body1"
                  style={{ color: palette.gray[600] }}
                >
                  {
                    <span>
                      Your subscription will resume immediately. You'll receive{' '}
                      <b>
                        {isWordsFeatureEnabled &&
                        newPlanTier.plan_type === PlanType.unlimited
                          ? 'unlimited'
                          : isWordsFeatureEnabled
                          ? (newPlanMonthlyCurrencies * 12).toLocaleString(
                              'en-US'
                            )
                          : newPlanMonthlyCurrencies.toLocaleString('en-US')}
                      </b>{' '}
                      {isWordsFeatureEnabled ? 'words' : 'credits'} and be
                      charged{' '}
                      <b>
                        {currencySymbol}
                        {billedAmountToday}
                      </b>{' '}
                      today. Your billing cycle will be reset to <b>today</b>.
                    </span>
                  }
                </Typography>
              </>
            ) : isSamePlan ? (
              <Typography variant="body1" style={{ color: palette.gray[600] }}>
                Your subscription will not be paused{' '}
                {nextBillingDateString ? `on ${nextBillingDateString}.` : '.'}
              </Typography>
            ) : (
              <Typography variant="body1" style={{ color: palette.gray[600] }}>
                Your new subscription will be activated immediately. You'll
                receive{' '}
                <b>
                  {isWordsFeatureEnabled &&
                  newPlanTier.plan_type === PlanType.unlimited
                    ? 'unlimited'
                    : isWordsFeatureEnabled
                    ? (newPlanMonthlyCurrencies * 12).toLocaleString('en-US')
                    : newPlanMonthlyCurrencies.toLocaleString('en-US')}
                </b>{' '}
                {isWordsFeatureEnabled ? 'words' : 'credits'} and be charged{' '}
                <b>
                  {currencySymbol}
                  {billedAmountToday}
                </b>{' '}
                today. Your billing cycle will be reset to <b>today</b>.
              </Typography>
            )}
            <Typography variant="body1" style={{ color: palette.gray[600] }}>
              Your {!isSamePlan ? 'new plan:' : 'plan:'}
            </Typography>
            {planBannerInDialog(
              newPlanTier,
              newPlanMonthlyBilledAmount,
              currencySymbol,
              theme
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updateUserPlan(selectedPlanTierId, planChangeType)
              }}
              disabled={loadingUpdate}
              endIcon={
                loadingUpdate && <CircularProgress color="inherit" size={14} />
              }
            >
              {!isSamePlan ? 'Resume & change plan' : 'Resume plan'}
            </Button>
          </Stack>
        </DialogContent>
      </>
    )
  }
)

interface CancelPlanDialogContentProps extends BaseDialogContentProps {
  selectedReason: CancellationReason | undefined
  changeSelectedReason: (event: React.ChangeEvent<HTMLInputElement>) => void
  setOpenPauseDialog: (open: boolean) => void
  onOpenDiscountDialog?: () => void
  setCancellationDescription: (desc: string) => void
  textFieldRef: React.RefObject<HTMLDivElement> | null
  cancellationDescription: string
  activePlanTier: PremiumPlanTier
  classes: ClassNameMap<string>
  nextBillingDateString?: string
}

const CancelPlanDialogContent = (
  cancelPlanDialogContentProps: CancelPlanDialogContentProps
) => {
  const {
    loadingUpdate,
    setOpenDialog,
    selectedReason,
    changeSelectedReason,
    setOpenPauseDialog,
    onOpenDiscountDialog,
    setCancellationDescription,
    textFieldRef,
    cancellationDescription,
    selectedPlanTierId,
    updateUserPlan,
    activePlanTier,
    classes,
    nextBillingDateString
  } = cancelPlanDialogContentProps

  const { data: billingInfo } = useSWR(
    'stripeBillingInfo',
    fetchUserBillingInfo
  )

  return (
    <Box
      m={2}
      width={350}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Box display="flex" flexDirection="row" justifyContent="center">
        <img
          src={plan_cancellation_icon}
          alt="plan_cancellation_icon"
          style={{ margin: 'auto' }}
        />
        <IconButton
          onClick={() => setOpenDialog(false)}
          style={{ height: '10%', padding: '5px' }}
          disabled={loadingUpdate}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Stack spacing={1}>
        <Typography variant="h6">
          We're sad to see you go... are you sure?
        </Typography>
        <Typography>
          Starting from <b>{nextBillingDateString}</b>, you will no longer have
          access to advanced features
        </Typography>
        <Typography>
          Before you cancel, please let us know the reason you're leaving. Your
          feedback matters!
        </Typography>
      </Stack>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="cancellation_reason"
          name="cancellation_reason"
          value={selectedReason}
          onChange={changeSelectedReason}
        >
          {SHUFFLED_CANCELLATION_REASON_OPTIONS.map((key) => (
            <FormControlLabel
              key={key}
              value={key}
              control={<Radio color={'primary'} />}
              label={CancellationReasonLabel[key]}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <TextField
        id="description"
        placeholder="Tell us more..."
        variant="outlined"
        fullWidth
        onChange={(e) => setCancellationDescription(e.target.value)}
        className={clsx(classes.textMargins)}
        inputRef={textFieldRef}
      />
      <Stack
        direction="row"
        spacing={1}
        width="100%"
        justifyContent="space-between"
      >
        <Box width={1 / 2}>
          <EnhancedButton
            onClick={() => {
              if (!loadingUpdate) {
                setOpenDialog(false)
              }
            }}
            variant={'outlined'}
            color={'primary'}
            className={clsx(classes.button)}
            style={{ width: '100%' }}
            disabled={loadingUpdate}
          >
            Back
          </EnhancedButton>
        </Box>
        <Box width={1 / 2}>
          <LightTooltipWithPadding
            title={
              selectedReason === undefined
                ? 'Please select a reason'
                : cancellationDescription === ''
                ? 'Please provide a reason'
                : ''
            }
            arrow
          >
            <span>
              <EnhancedButton
                color="error"
                variant={'contained'}
                disabled={
                  selectedReason === undefined ||
                  cancellationDescription === '' ||
                  loadingUpdate
                } // or compulsory field not filled
                onClick={
                  billingInfo?.subscription_info.status !==
                  StripeSubscriptionStatus.trialing
                    ? (selectedReason ===
                        CancellationReason.only_needed_it_once ||
                        selectedReason ===
                          CancellationReason.didnt_have_time_to_use ||
                        selectedReason ===
                          CancellationReason.dont_need_it_anymore) &&
                      activePlanTier.plan_billing_period ===
                        PlanBillingPeriod.monthly
                      ? () => {
                          setOpenDialog(false)
                          setOpenPauseDialog(true)
                        }
                      : selectedReason === CancellationReason.i_cant_afford &&
                        onOpenDiscountDialog &&
                        activePlanTier.plan_billing_period ===
                          PlanBillingPeriod.monthly &&
                        !activePlanTier.has_churn_discount_applied
                      ? () => {
                          setOpenDialog(false)
                          onOpenDiscountDialog()
                        }
                      : () => {
                          updateUserPlan(
                            selectedPlanTierId,
                            PlanChangeType.cancellation
                          )
                        }
                    : () => {
                        updateUserPlan(
                          selectedPlanTierId,
                          PlanChangeType.cancellation
                        )
                      }
                }
                className={clsx(classes.button)}
                style={{ width: '100%' }}
                endIcon={
                  loadingUpdate && (
                    <CircularProgress color="inherit" size={14} />
                  )
                }
              >
                Unsubscribe
              </EnhancedButton>
            </span>
          </LightTooltipWithPadding>
        </Box>
      </Stack>
    </Box>
  )
}

interface ResetBillingCycleDialogContentProps extends BaseDialogContentProps {
  newPlanTier: PremiumPlanTier
  newPlanMonthlyCurrencies: number
  newPlanMonthlyBilledAmount: number
  planChangeType: PlanChangeType
  userCountryCode: string
  isPromotionCountryCode: boolean
  theme: Theme
}

const ResetBillingCycleDialogContent = React.memo(
  (
    resetBillingCycleDialogContentProps: ResetBillingCycleDialogContentProps
  ) => {
    const {
      newPlanTier,
      newPlanMonthlyCurrencies,
      newPlanMonthlyBilledAmount,
      planChangeType,
      userCountryCode,
      isPromotionCountryCode,
      theme,
      loadingUpdate,
      setOpenDialog,
      isWordsFeatureEnabled,
      selectedPlanTierId,
      updateUserPlan
    } = resetBillingCycleDialogContentProps
    const newBillingDateNumber = new Date().getDate()
    const newBillingDateNumberLastPlace = newBillingDateNumber % 10
    const newBillingDateNumberFirstPlace = Math.floor(newBillingDateNumber / 10)
    const newBillingDateNumberWithSuffix = newBillingDateNumber
      .toString()
      .concat(
        newBillingDateNumberFirstPlace !== 1 &&
          newBillingDateNumberLastPlace === 1
          ? 'st'
          : newBillingDateNumberFirstPlace !== 1 &&
            newBillingDateNumberLastPlace === 2
          ? 'nd'
          : newBillingDateNumberFirstPlace !== 1 &&
            newBillingDateNumberLastPlace === 3
          ? 'rd'
          : 'th'
      )

    const isNewPlanYearly =
      newPlanTier.plan_billing_period === PlanBillingPeriod.yearly

    const {
      billedAmountToday,
      currencySymbol
    } = getMonthlyAmountAndCurrencySymbol(
      newPlanTier,
      userCountryCode,
      newPlanMonthlyBilledAmount,
      isNewPlanYearly,
      isPromotionCountryCode
    )

    return (
      <>
        <DialogContent>
          <Stack spacing={2} pb={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box>
                <img
                  src={planChangeIcon}
                  alt="plan change"
                  style={{ margin: 'auto' }}
                />
              </Box>
              <IconButton
                onClick={() => {
                  if (!loadingUpdate) {
                    setOpenDialog(false)
                  }
                }}
                style={{ height: '10%', padding: '5px' }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography variant="h6">Change your plan</Typography>
            <Typography variant="body1" style={{ color: palette.gray[600] }}>
              {isNewPlanYearly ? (
                <span>
                  Your new subscription will be activated immediately. You'll
                  receive{' '}
                  <b>
                    {isWordsFeatureEnabled &&
                    newPlanTier.plan_type === PlanType.unlimited
                      ? 'unlimited'
                      : isWordsFeatureEnabled
                      ? (newPlanMonthlyCurrencies * 12).toLocaleString('en-US')
                      : newPlanMonthlyCurrencies.toLocaleString('en-US')}
                  </b>{' '}
                  {isWordsFeatureEnabled ? 'words' : 'credits'}
                  {isWordsFeatureEnabled
                    ? ' and be charged '
                    : ` and on subsequent <b>${newBillingDateNumberWithSuffix}</b> of the month for the next 12 months. You will be charged `}
                  <b>
                    {currencySymbol}
                    {billedAmountToday}
                  </b>{' '}
                  today. Your billing cycle will be reset to <b>today</b>.
                </span>
              ) : (
                <span>
                  Your new subscription will be activated immediately. You'll
                  receive{' '}
                  <b>
                    {isWordsFeatureEnabled &&
                    newPlanTier.plan_type === PlanType.unlimited
                      ? 'unlimited'
                      : newPlanMonthlyCurrencies.toLocaleString('en-US')}
                  </b>{' '}
                  {isWordsFeatureEnabled ? 'words' : 'credits'} and be charged{' '}
                  <b>
                    {currencySymbol}
                    {billedAmountToday}
                  </b>{' '}
                  today. Your billing cycle will be reset to <b>today</b>.
                </span>
              )}
            </Typography>
            <Typography variant="body1" style={{ color: palette.gray[600] }}>
              Your new plan:
            </Typography>
            {planBannerInDialog(
              newPlanTier,
              newPlanMonthlyBilledAmount,
              currencySymbol,
              theme
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={() => updateUserPlan(selectedPlanTierId, planChangeType)}
              disabled={loadingUpdate}
              endIcon={
                loadingUpdate && <CircularProgress color="inherit" size={14} />
              }
            >
              Change plan
            </Button>
          </Stack>
        </DialogContent>
      </>
    )
  }
)

interface keepBillingCycleDialogContentProps extends BaseDialogContentProps {
  newPlanTier: PremiumPlanTier
  newPlanMonthlyCurrencies: number
  newPlanMonthlyBilledAmount: number
  planChangeType: PlanChangeType
  userCountryCode: string
  isPromotionCountryCode: boolean
  theme: Theme
  nextBillingDateString?: string
}

const KeepBillingCycleDialogContent = React.memo(
  (keepBillingCycleDialogContentProps: keepBillingCycleDialogContentProps) => {
    const {
      newPlanTier,
      newPlanMonthlyCurrencies,
      newPlanMonthlyBilledAmount,
      planChangeType,
      userCountryCode,
      isPromotionCountryCode,
      theme,
      loadingUpdate,
      setOpenDialog,
      isWordsFeatureEnabled,
      selectedPlanTierId,
      updateUserPlan,
      nextBillingDateString
    } = keepBillingCycleDialogContentProps
    const isNewPlanYearly =
      newPlanTier.plan_billing_period === PlanBillingPeriod.yearly

    const newBillingDateNumber = new Date().getDate()
    const newBillingDateNumberLastPlace = newBillingDateNumber % 10
    const newBillingDateNumberFirstPlace = Math.floor(newBillingDateNumber / 10)
    const newBillingDateNumberWithSuffix = newBillingDateNumber
      .toString()
      .concat(
        newBillingDateNumberFirstPlace !== 1 &&
          newBillingDateNumberLastPlace === 1
          ? 'st'
          : newBillingDateNumberFirstPlace !== 1 &&
            newBillingDateNumberLastPlace === 2
          ? 'nd'
          : newBillingDateNumberFirstPlace !== 1 &&
            newBillingDateNumberLastPlace === 3
          ? 'rd'
          : 'th'
      )

    const {
      billedAmountToday: displayedPrice,
      currencySymbol
    } = getMonthlyAmountAndCurrencySymbol(
      newPlanTier,
      userCountryCode,
      newPlanMonthlyBilledAmount,
      isNewPlanYearly,
      isPromotionCountryCode
    )

    return (
      <>
        <DialogContent>
          <Stack spacing={2} pb={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box>
                <img
                  src={planChangeIcon}
                  alt="plan-change"
                  style={{ margin: 'auto' }}
                />
              </Box>
              <IconButton
                onClick={() => {
                  if (!loadingUpdate) {
                    setOpenDialog(false)
                  }
                }}
                style={{ height: '10%', padding: '5px' }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography variant="h6">Change your plan</Typography>
            <Typography variant="body1" style={{ color: palette.gray[600] }}>
              <span>
                Your new subscription will be activated immediately. You'll
                receive{' '}
                <b>
                  {isWordsFeatureEnabled &&
                  newPlanTier.plan_type === PlanType.unlimited
                    ? 'unlimited'
                    : isWordsFeatureEnabled
                    ? (newPlanMonthlyCurrencies * 12).toLocaleString('en-US')
                    : newPlanMonthlyCurrencies.toLocaleString('en-US')}
                </b>{' '}
                {isWordsFeatureEnabled ? 'words' : 'credits'}
                {isWordsFeatureEnabled ? (
                  ' and your current bill will be prorated accordingly. '
                ) : (
                  <span>
                    {' '}
                    and on subsequent <b>
                      ${newBillingDateNumberWithSuffix}
                    </b>{' '}
                    of the month for the next 12 months. Your current bill will
                    be prorated accordingly.{' '}
                  </span>
                )}
              </span>
              From <b>{nextBillingDateString}</b>, you will be charged{' '}
              <b>
                {currencySymbol}
                {displayedPrice}
              </b>
              {' yearly.'}
            </Typography>
            <Typography variant="body1" style={{ color: palette.gray[600] }}>
              Your new plan:
            </Typography>
            {planBannerInDialog(
              newPlanTier,
              newPlanMonthlyBilledAmount,
              currencySymbol,
              theme
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={() => updateUserPlan(selectedPlanTierId, planChangeType)}
              disabled={loadingUpdate}
              endIcon={
                loadingUpdate && <CircularProgress color="inherit" size={14} />
              }
            >
              Change plan
            </Button>
          </Stack>
        </DialogContent>
      </>
    )
  }
)

interface SchedulePlanChangeDialogContentProps extends BaseDialogContentProps {
  newPlanTier: PremiumPlanTier
  newPlanMonthlyBilledAmount: number
  planChangeType: PlanChangeType
  nextBillingDate: Date | undefined
  userCountryCode: string
  isPromotionCountryCode: boolean
  theme: Theme
  nextBillingDateString?: string
  newPlanMonthlyCurrencies: number
}

const SchedulePlanChangeDialogContent = React.memo(
  (planChangeDialogContentProps: SchedulePlanChangeDialogContentProps) => {
    const {
      newPlanTier,
      newPlanMonthlyBilledAmount,
      planChangeType,
      nextBillingDate,
      userCountryCode,
      isPromotionCountryCode,
      loadingUpdate,
      setOpenDialog,
      isWordsFeatureEnabled,
      selectedPlanTierId,
      updateUserPlan,
      theme,
      nextBillingDateString,
      newPlanMonthlyCurrencies
    } = planChangeDialogContentProps
    const nextBillingDateNumber = nextBillingDate?.getDate()
    const nextBillingDateNumberLastPlace =
      nextBillingDateNumber && nextBillingDateNumber % 10
    const nextBillingDateNumberFirstPlace =
      nextBillingDateNumber && Math.floor(nextBillingDateNumber / 10)
    const nextBillingDateNumberWithSuffix =
      nextBillingDateNumber &&
      nextBillingDateNumber
        .toString()
        .concat(
          nextBillingDateNumberFirstPlace !== 1 &&
            nextBillingDateNumberLastPlace === 1
            ? 'st'
            : nextBillingDateNumberFirstPlace !== 1 &&
              nextBillingDateNumberLastPlace === 2
            ? 'nd'
            : nextBillingDateNumberFirstPlace !== 1 &&
              nextBillingDateNumberLastPlace === 3
            ? 'rd'
            : 'th'
        )

    const isNewPlanYearly =
      newPlanTier.plan_billing_period === PlanBillingPeriod.yearly

    const {
      billedAmountToday: displayedPrice,
      currencySymbol
    } = getMonthlyAmountAndCurrencySymbol(
      newPlanTier,
      userCountryCode,
      newPlanMonthlyBilledAmount,
      isNewPlanYearly,
      isPromotionCountryCode
    )

    const currencyCopy = useCallback(() => {
      if (isWordsFeatureEnabled) {
        if (newPlanTier.plan_type === PlanType.unlimited) {
          return (
            <span>
              <b>unlimited</b> words
            </span>
          )
        } else if (isNewPlanYearly) {
          return (
            <span>
              <b>{(newPlanMonthlyCurrencies * 12).toLocaleString('en-US')}</b>{' '}
              words
            </span>
          )
        } else {
          return (
            <span>
              <b>{newPlanMonthlyCurrencies.toLocaleString('en-US')}</b> words
            </span>
          )
        }
      } else {
        if (isNewPlanYearly) {
          return (
            <span>
              <b>{newPlanMonthlyCurrencies.toLocaleString('en-US')}</b> credits
              and on subsequent <b>${nextBillingDateNumberWithSuffix}</b> of the
              month for the next 12 months.
            </span>
          )
        } else {
          return (
            <span>
              <b>{newPlanMonthlyCurrencies.toLocaleString('en-US')}</b> credits
            </span>
          )
        }
      }
    }, [
      isWordsFeatureEnabled,
      isNewPlanYearly,
      nextBillingDateNumberWithSuffix,
      newPlanMonthlyCurrencies,
      newPlanTier.plan_type
    ])

    return (
      <>
        <DialogContent>
          <Stack spacing={2} pb={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box>
                <img
                  src={planChangeIcon}
                  alt="plan-change"
                  style={{ margin: 'auto' }}
                />
              </Box>
              <IconButton
                onClick={() => {
                  if (!loadingUpdate) {
                    setOpenDialog(false)
                  }
                }}
                style={{ height: '10%', padding: '5px' }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography variant="h6">Change plan</Typography>
            <Typography variant="body1" style={{ color: palette.gray[600] }}>
              There will be no change to your{' '}
              {isWordsFeatureEnabled ? 'words' : 'credits'} now. Your new plan
              will take effect after your current billing cycle ends on{' '}
              <b>{nextBillingDateString}</b>. You'll receive {currencyCopy()}{' '}
              and be charged{' '}
              <b>
                {currencySymbol}
                {displayedPrice}
              </b>
              {' then.'}
            </Typography>
            <Typography variant="body1" style={{ color: palette.gray[600] }}>
              Your new plan:
            </Typography>
            {planBannerInDialog(
              newPlanTier,
              newPlanMonthlyBilledAmount,
              currencySymbol,
              theme
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updateUserPlan(selectedPlanTierId, planChangeType)
              }}
              disabled={loadingUpdate}
              endIcon={
                loadingUpdate && <CircularProgress color="inherit" size={14} />
              }
            >
              Change plan
            </Button>
          </Stack>
        </DialogContent>
      </>
    )
  }
)

export default ChangePlanDialog
