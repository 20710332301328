import React from 'react'

import { Popup, PopupProps } from 'semantic-ui-react'

import palette from '../atoms/Colors'
import Stack from './atoms/Stack'
import Typography from '@hypotenuse/common/src/atoms/Typography'
import { Box, useTheme } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'

import {
  SEMANTIC_POPUP_OVERFLOW_PREVENTION_PROPS,
  SIZES
} from '../utils/Constants'

import { HelpIcon } from '../icons/StyledIcons'

interface LabelWithToolTipProps {
  labelText: string | React.ReactNode
  tooltipBody?: React.ReactNode
  subLabelText?: string
  popupProps?: PopupProps
  required?: boolean
  size?: SIZES
  color?: string
  fullWidth?: boolean
}

/**
 * A reusable component whenever you want to display a label text with tooltip beside to render a helper text
 */
export const LabelWithToolTip = (props: LabelWithToolTipProps) => {
  const {
    labelText,
    subLabelText,
    tooltipBody,
    popupProps,
    required,
    size,
    color,
    fullWidth = false
  } = props
  const theme = useTheme()

  const labelVariant: { [key in SIZES]: Variant } = {
    [SIZES.large]: 'h5',
    [SIZES.medium]: 'body2',
    [SIZES.small]: 'body2'
  }

  return (
    <label style={fullWidth ? { width: '100%' } : {}}>
      <Box
        display="flex"
        style={{
          alignItems: 'center',
          justifyContent: 'flex-start',
          ...(fullWidth
            ? { width: '100%', justifyContent: 'space-between' }
            : {})
        }}
      >
        <Stack direction="row">
          <Typography
            variant={labelVariant[size ?? SIZES.small]}
            style={{
              fontWeight: 600,
              color: color ?? palette.gray[900],
              fontSize: theme.spacing(1.75)
            }}
          >
            {labelText}
          </Typography>
          {required && <span style={{ color: palette.red[500] }}>&nbsp;*</span>}
        </Stack>

        {tooltipBody && (
          // TODO: switch to MUI Tooltip
          <Popup
            popperModifiers={SEMANTIC_POPUP_OVERFLOW_PREVENTION_PROPS}
            wide
            basic
            position="top center"
            offset="-75%"
            hoverable
            trigger={
              <HelpIcon
                style={{ marginLeft: '6px', color: palette.gray[400] }}
              />
            }
            {...popupProps}
            style={{
              backgroundColor: palette.gray[900],
              borderRadius: theme.spacing(1)
            }}
          >
            <Typography
              variant="body2"
              style={{
                fontSize: theme.spacing(1.75),
                color: 'white'
              }}
            >
              {tooltipBody}
            </Typography>
          </Popup>
        )}
      </Box>
      <Box>
        {subLabelText && (
          <Typography
            variant="subtitle2"
            style={{ color: palette.gray[500], fontWeight: 400 }}
          >
            {subLabelText}
          </Typography>
        )}
      </Box>
    </label>
  )
}
