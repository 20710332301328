import React from 'react'

import useSWR from 'swr'

import { Typography } from '@hypotenuse/common/src/atoms'
import palette from '@hypotenuse/common/src/atoms/Colors'
import Stack from '@hypotenuse/common/src/components/atoms/Stack'
import { Box, useTheme } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'

import { handleContactUs } from '@hypotenuse/common/src/analytics/utils'
import { apiFetchPlanTierListObject } from '@hypotenuse/common/src/api/PlanTier'
import { PrimaryButton } from '@hypotenuse/common/src/components/BaseModal'
import { useUserContext } from '@hypotenuse/common/src/utils/context/UserContext'

import { EcommerceContentData } from './EcommerceCardCTAModal'
import {
  BespokeWritersCard,
  BulkAIImageEditorCard,
  BulkSEOEcommContentCard,
  CustomReviewWorkflowCard,
  DigitalAssetManagementCard,
  EnterpriseGradeSecurityCard,
  ImageSEOCard,
  KeywordFinderCard,
  PerformanceMonitoringCard,
  ProductivityReportingCard,
  RetailersCompliantCard
} from './EcommerceTemplateCard'

//////////////////////////////// Feature Groups to Generate ////////////////////////////////
interface FeatureGroupProps {
  setIsShowingEcommerceCardCTAModal: (value: boolean) => void
  setEcommerceContentData: (value: EcommerceContentData) => void
}

export const ContentWorkflowsFeatureGroup: React.FC<FeatureGroupProps> = ({
  setIsShowingEcommerceCardCTAModal,
  setEcommerceContentData
}) => {
  const cards = [
    BulkSEOEcommContentCard(
      setIsShowingEcommerceCardCTAModal,
      setEcommerceContentData
    ),
    RetailersCompliantCard(
      setIsShowingEcommerceCardCTAModal,
      setEcommerceContentData
    ),
    KeywordFinderCard(
      setIsShowingEcommerceCardCTAModal,
      setEcommerceContentData
    ),
    BespokeWritersCard(
      setIsShowingEcommerceCardCTAModal,
      setEcommerceContentData
    ),
    CustomReviewWorkflowCard(
      setIsShowingEcommerceCardCTAModal,
      setEcommerceContentData
    )
  ]
  return (
    <EcommerceFeaturesGroup
      featureGroupName="Content Workflows"
      setIsShowingEcommerceCardCTAModal={setIsShowingEcommerceCardCTAModal}
      setEcommerceContentData={setEcommerceContentData}
      cards={cards}
    />
  )
}

export const AssetWorkflowsFeatureGroup: React.FC<FeatureGroupProps> = ({
  setIsShowingEcommerceCardCTAModal,
  setEcommerceContentData
}) => {
  const cards = [
    DigitalAssetManagementCard(
      setIsShowingEcommerceCardCTAModal,
      setEcommerceContentData
    ),
    BulkAIImageEditorCard(
      setIsShowingEcommerceCardCTAModal,
      setEcommerceContentData
    ),
    ImageSEOCard(setIsShowingEcommerceCardCTAModal, setEcommerceContentData)
  ]
  return (
    <EcommerceFeaturesGroup
      featureGroupName="Asset Workflows"
      setIsShowingEcommerceCardCTAModal={setIsShowingEcommerceCardCTAModal}
      setEcommerceContentData={setEcommerceContentData}
      cards={cards}
    />
  )
}

export const TrackingFeatureGroup: React.FC<FeatureGroupProps> = ({
  setIsShowingEcommerceCardCTAModal,
  setEcommerceContentData
}) => {
  const cards = [
    PerformanceMonitoringCard(
      setIsShowingEcommerceCardCTAModal,
      setEcommerceContentData
    ),
    ProductivityReportingCard(
      setIsShowingEcommerceCardCTAModal,
      setEcommerceContentData
    ),
    EnterpriseGradeSecurityCard(
      setIsShowingEcommerceCardCTAModal,
      setEcommerceContentData
    )
  ]
  return (
    <EcommerceFeaturesGroup
      featureGroupName="Tracking"
      setIsShowingEcommerceCardCTAModal={setIsShowingEcommerceCardCTAModal}
      setEcommerceContentData={setEcommerceContentData}
      cards={cards}
    />
  )
}

//////////////////////////////// Feature Groups to Generate ////////////////////////////////

interface EcommerceFeaturesGroupProps {
  featureGroupName?: string
  setIsShowingEcommerceCardCTAModal: (value: boolean) => void
  cards: JSX.Element[]
  setEcommerceContentData: (value: EcommerceContentData) => void
}

const EcommerceFeaturesGroup: React.FC<EcommerceFeaturesGroupProps> = React.memo(
  (props) => {
    const { featureGroupName, cards } = props
    const theme = useTheme()
    const { user } = useUserContext()
    const planTierListObject = useSWR(
      'planTierListObject',
      apiFetchPlanTierListObject
    ).data

    return (
      <Stack
        alignItems="center"
        justifyContent="flex"
        flexWrap="wrap"
        padding={3}
        spacing={2}
        margin={theme.spacing(0, 3)}
        style={{
          backgroundColor: 'white',
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: theme.shape.borderRadius,
          borderColor: palette.gray[300]
        }}
      >
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Typography variant="paragraph1Bold">{featureGroupName}</Typography>
          <PrimaryButton
            endIcon={<ArrowForward />}
            onClick={() => {
              handleContactUs({
                pathname: window.location.pathname,
                elementId: 'ecomlandingpage',
                userId: user.username,
                planListId: planTierListObject?.plan_list_id || ''
              })
            }}
          >
            Book a demo
          </PrimaryButton>
        </Stack>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridGap: 12,
            height: '100%',
            alignItems: 'stretch'
          }}
        >
          {cards.map((card, i) => (
            <Box key={i} sx={{ display: 'flex', flexDirection: 'column' }}>
              {card}
            </Box>
          ))}
        </Box>
      </Stack>
    )
  }
)

export default EcommerceFeaturesGroup
