import React, { useEffect, useState } from 'react'

import Lottie from 'lottie-react'
import { Dimmer, Loader } from 'semantic-ui-react'

import Typography from '@hypotenuse/common/src/atoms/Typography'
import {
  Backdrop,
  Box,
  CircularProgress,
  Fade,
  LinearProgress,
  Slide
} from '@material-ui/core'
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme
} from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'

import failureAnimation from '@hypotenuse/common/src/lottie/animated-loading-step-failure.json'
import successAnimation from '@hypotenuse/common/src/lottie/animated-loading-step-success.json'

import { useInterval } from '../utils/Functions'
import { canShowCancelButton, canShowRetryingMessage } from '../utils/Products'

import Button from './EnhancedButton'

export const SpinnerOnDimmer: React.FC = () => {
  return (
    <Dimmer active inverted>
      <Loader size="large" />
    </Dimmer>
  )
}

export interface ResponsiveLoaderProps {
  height?: number | string
  width?: number | string
}

export const ResponsiveLoader: React.FC<ResponsiveLoaderProps> = (props) => {
  const { height, width } = props
  return (
    <Fade in timeout={2000}>
      <Box height={height} width={width}>
        <Skeleton variant="rect" animation="wave" height="100%" width="100%" />
      </Box>
    </Fade>
  )
}

export interface BackgroundLoaderProps {
  isOpen: boolean
  loadingText?: string
  height?: number | string
  width?: number | string
  zIndex?: number
}

const useBlurredBackgroundLoaderStyles = makeStyles((_theme: Theme) =>
  createStyles({
    backdropRoot: {
      backdropFilter: 'blur(5px)',
      backgroundColor: 'transparent'
    },
    loadingText: {
      paddingTop: '10px'
    }
  })
)

export const BlurredBackgroundLoader: React.FC<BackgroundLoaderProps> = (
  props
) => {
  const { isOpen, loadingText, height, width, zIndex } = props
  const theme = useTheme()
  const loaderHeight = height ?? 'inherit'
  const loaderWidth = width ?? 'inherit'
  const loaderZIndex = zIndex ?? theme.zIndex.appBar - 1
  const classes = useBlurredBackgroundLoaderStyles()
  return (
    <Backdrop
      style={{
        height: loaderHeight,
        width: loaderWidth,
        zIndex: loaderZIndex
      }}
      classes={{
        root: classes.backdropRoot
      }}
      open={isOpen}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        data-testid="blurredBackgroundLoader"
      >
        <CircularProgress color="primary" />
        {loadingText && (
          <Typography className={classes.loadingText}>{loadingText}</Typography>
        )}
      </Box>
    </Backdrop>
  )
}

export const DimmedBackgroundLoader: React.FC<BackgroundLoaderProps> = (
  props
) => {
  const { isOpen, loadingText } = props

  return (
    <Dimmer
      active={isOpen}
      inverted
      style={{ position: 'fixed' }}
      data-testid="dimmedBackgroundLoader"
    >
      <Loader size="medium">{loadingText}</Loader>
    </Dimmer>
  )
}

interface AnimatedLoadingStepProps {
  text: string
  onCompleteLoading: () => void
  neverShowCompleted: boolean
  timeToCompleteStep: number
  showCompletedAsFailure?: boolean
}

/**
 * An animated loading step that is used as a part of the AnimatedLoader. It renders a step that shows a success
 * indicator when completed. However, if we want to show this loading step as failed, we can too.
 * @param text: Text to show while loading
 * @param onCompleteLoading: Handles what to do after the loading step has completed loading
 * @param timeToCompleteStep: Time taking to complete this particular step
 * @param showCompletedAsFailure: Optional boolean on whether to complete this loading step as failure
 */
export const AnimatedLoadingStep: React.FC<AnimatedLoadingStepProps> = (
  props: AnimatedLoadingStepProps
) => {
  const {
    text,
    onCompleteLoading,
    timeToCompleteStep,
    neverShowCompleted,
    showCompletedAsFailure
  } = props
  const [showCompleted, setShowCompleted] = useState<boolean>(false)
  useEffect(() => {
    if (!neverShowCompleted) {
      const showCompletedTimer = setTimeout(() => {
        setShowCompleted(true)
      }, timeToCompleteStep)

      return () => {
        clearTimeout(showCompletedTimer)
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [/* effect dep*/ text, timeToCompleteStep])
  return (
    <>
      <Slide in direction="up" mountOnEnter unmountOnExit>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: '30px',
            width: '100%'
          }}
        >
          <Typography variant="h6" style={{ margin: 0 }}>
            {props.text}
          </Typography>
          {showCompleted && (
            <Lottie
              animationData={
                showCompletedAsFailure ? failureAnimation : successAnimation
              }
              style={{ height: '100%' }}
              onLoopComplete={() => {
                setShowCompleted(false)
                onCompleteLoading()
              }}
            />
          )}
        </div>
      </Slide>
    </>
  )
}

interface RetryOrCancelGenerationConfig {
  handleCancelGeneration: () => void
  generationLoadingAt?: Date
  generationExpiredAt?: Date
}

interface AnimatedGenerationLoaderProps {
  isLoading: boolean
  mainLoadingHeader: string
  loadingTexts: string[]
  lottieAnimation: object
  retryOrCancelGenerationConfig?: RetryOrCancelGenerationConfig
}

/**
 * An animated loader that renders a nice UI loading screen while waiting for generation to happen.
 * It features "fake" loading steps so the users knows that we are actively taking steps to generate for them.
 * If the last loading step is reached, but loading isnt completed yet, we just never show that loading step as success.
 * Whenever loading is completed, we quickly expedite the final loading step provided as success, before exiting.
 * @param isLoading: Whether the product is still loading/generating
 * @param mainLoadingHeader: The header for what this is loading for
 * @param loadingTexts: The array of loading steps that we "fake" that we are going through.
 * @param lottieAnimation: The Lottie Animation (in JSON) used for this animated loader
 * @param retryOrCancelGenerationConfig: Optional config for performing retry or cancel logic for loading generation
 */
export const AnimatedGenerationLoader = React.memo(
  (props: AnimatedGenerationLoaderProps) => {
    const {
      loadingTexts,
      isLoading,
      lottieAnimation,
      mainLoadingHeader,
      retryOrCancelGenerationConfig
    } = props
    const theme = useTheme()
    const [loadingStepIndex, setLoadingStepIndex] = useState<number>(-1)
    const [isDimmerOpen, setIsDimmerOpen] = useState<boolean>(false)

    /**
     * This segment is related to logic for handling retry or cancel generation
     */
    const [isPendingCancel, setIsPendingCancel] = useState<boolean>(false)
    const [cancelledGeneration, setCancelledGeneration] = useState<boolean>(
      false
    )
    const [currDateTime, setCurrDateTime] = React.useState<Date>(new Date())
    // Required to rerender this component and check for time
    useInterval(() => {
      if (isDimmerOpen) setCurrDateTime(new Date())
    }, 5000)
    // end segment

    const handleLoadingStepCompleted = () => {
      const nextStep = loadingStepIndex + 1
      // Try to proceed to the next loading step
      if (nextStep < loadingTexts.length) {
        setLoadingStepIndex(nextStep)
      }
      // Fallback to check if the loading has already ended. If so, do cleanup
      if (!isLoading) {
        setLoadingStepIndex(-1)
        setIsDimmerOpen(false)
      }
    }

    // Initializer to open the dimmer when the loader is started
    useEffect(() => {
      if (loadingTexts.length > 0 && isLoading) {
        setLoadingStepIndex(0)
        setIsDimmerOpen(true)
      }
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [isLoading])

    return (
      <Dimmer
        active={isDimmerOpen}
        inverted
        style={{ position: 'fixed', zIndex: theme.zIndex.modal }}
        data-testid="animatedBackgroundLoader"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            color: 'black',
            alignItems: 'center'
          }}
        >
          <Lottie animationData={lottieAnimation} style={{ width: '40%' }} />
          <Typography variant="h6">{mainLoadingHeader}</Typography>
          <div style={{ width: '40%', marginTop: '8px' }}>
            <LinearProgress color="primary" />
          </div>
          <div style={{ marginTop: '8px' }}>
            {isLoading ? (
              // Loading is going on, we pretend like the steps are going on
              // If we reach the last loading step, we never show the success for that step
              <AnimatedLoadingStep
                text={loadingTexts[loadingStepIndex]}
                onCompleteLoading={handleLoadingStepCompleted}
                neverShowCompleted={
                  isLoading && loadingStepIndex === loadingTexts.length - 1
                }
                timeToCompleteStep={5000}
              />
            ) : (
              isDimmerOpen && (
                // Product loading has completed, we are ready to serve, so we quickly expedite a done loading step
                <AnimatedLoadingStep
                  text={loadingTexts[loadingTexts.length - 1]}
                  onCompleteLoading={handleLoadingStepCompleted}
                  neverShowCompleted={false}
                  timeToCompleteStep={100}
                  showCompletedAsFailure={cancelledGeneration}
                />
              )
            )}
          </div>
          <div>
            {retryOrCancelGenerationConfig
              ? canShowRetryingMessage(
                  currDateTime,
                  retryOrCancelGenerationConfig.generationExpiredAt,
                  retryOrCancelGenerationConfig.generationLoadingAt
                ) && (
                  <Typography style={{ color: 'grey' }}>
                    This is taking longer than usual. Retrying...
                  </Typography>
                )
              : null}
            {retryOrCancelGenerationConfig
              ? canShowCancelButton(
                  currDateTime,
                  retryOrCancelGenerationConfig.generationExpiredAt,
                  retryOrCancelGenerationConfig.generationLoadingAt
                ) && (
                  <Button
                    style={{ marginTop: '10px' }}
                    color="error"
                    variant="outlined"
                    loading={isPendingCancel}
                    onClick={async () => {
                      setIsPendingCancel(true)
                      await retryOrCancelGenerationConfig.handleCancelGeneration()
                      setCancelledGeneration(true)
                      setIsPendingCancel(false)
                    }}
                  >
                    Cancel Generation
                  </Button>
                )
              : null}
          </div>
        </div>
      </Dimmer>
    )
  }
)
