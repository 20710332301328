import React, { useMemo } from 'react'

import { Popup } from 'semantic-ui-react'

import { Typography, palette } from '../../atoms'
import Stack from '../atoms/Stack'
import { useTheme } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import { SEMANTIC_POPUP_OVERFLOW_PREVENTION_PROPS } from '../../utils/Constants'

import { HelpIcon } from '../../icons/StyledIcons'

export interface StatsWidgetProps {
  /**
   * The icon used for the image
   *
   * For now, since we use emojis, it's just a string
   */
  icon?: string
  /**
   * The title of the widget
   */
  title: string
  /**
   * The formatted stat (value) of the widget
   *
   * This can be undefined if the widget is loading or failed to fetch data
   */
  formattedStat?: string | null
  /**
   * The tooltip label for the widget
   *
   * This is optional, and if not provided, the tooltip will not be shown
   */
  tooltipLabel?: string
  /**
   * Whether the widget is loading
   */
  isLoading?: boolean
  /**
   * Whether the widget failed to fetch data
   */
  failedToFetch?: boolean
}

const StatsWidget: React.FC<StatsWidgetProps> = React.memo((props) => {
  const theme = useTheme()

  const {
    icon,
    title,
    formattedStat: _formattedStat,
    tooltipLabel,
    isLoading,
    failedToFetch
  } = props

  const formattedStat = useMemo(() => {
    if (failedToFetch || !_formattedStat) {
      return 'NA'
    }

    return _formattedStat
  }, [_formattedStat, failedToFetch])

  return (
    <Stack
      style={{
        flexGrow: 1,
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
      }}
    >
      <Typography variant="heading3">
        {isLoading ? (
          <Skeleton variant="text" width={50} />
        ) : (
          <>
            {icon && icon} {formattedStat}
          </>
        )}
      </Typography>
      <Stack direction="row" alignItems="center">
        <Typography
          variant="paragraph2Reg"
          style={{
            color: palette.gray[500]
          }}
        >
          {title}
        </Typography>
        {tooltipLabel && (
          <Popup
            popperModifiers={SEMANTIC_POPUP_OVERFLOW_PREVENTION_PROPS}
            wide
            basic
            position="top center"
            offset="-75%"
            hoverable
            trigger={
              <HelpIcon
                style={{
                  marginLeft: '6px',
                  color: palette.gray[400]
                }}
              />
            }
            style={{
              backgroundColor: palette.gray[900],
              borderRadius: theme.spacing(1)
            }}
          >
            <Typography
              variant="body2"
              style={{
                fontSize: theme.spacing(1.75),
                color: 'white'
              }}
            >
              {tooltipLabel}
            </Typography>
          </Popup>
        )}
      </Stack>
    </Stack>
  )
})

export default StatsWidget
