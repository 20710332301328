import React from 'react'

import { Link, LinkProps } from '@material-ui/core'

export interface TextButtonProps extends LinkProps<'button'> {}

/**
 * A button that looks like a link, with some sensible default props
 */
const TextButton: React.FC<TextButtonProps> = (props) => {
  const { style, ...rest } = props
  return (
    <Link
      component="button"
      type="button"
      style={{ verticalAlign: 'inherit', ...style }}
      {...rest}
    />
  )
}

export default TextButton
