import {
  ECommercePlatforms,
  ProductSet,
  ProductSetInResponse,
  ProductSetStatsInResponse,
  ProductTrackingKeyword,
  TrackedProduct
} from '../components/digitalShelfAnalytics/interfaces'

import { apiClient } from '../utils/ApiClient'

import {
  DAMProduct,
  DAMProductCreationItem,
  DAMProductSearchResult,
  ProductTable,
  ShopifyDAMProductWithAllFields
} from '../components/digitalAssetManagement/interface'

export const apiUpsertProductTrackingKeyword = async (
  keywords: string[],
  keywordsRanking?: { [key: string]: (number | null)[] }
) => {
  const response = await apiClient.post<string[]>(
    `/ecommerce/product-tracking/keywords`,
    { keywords, keywords_ranking: keywordsRanking }
  )
  return response.data
}

export const apiGetTrackedProducts = async () => {
  const response = await apiClient.get<TrackedProduct[]>(
    `/ecommerce/product-tracking/tracked-products`
  )
  return response.data
}

export const apiUpsertTrackedProducts = async (
  productTitles: string[],
  productIds: string[]
) => {
  const response = await apiClient.post<TrackedProduct[]>(
    `/ecommerce/product-tracking/tracked-products`,
    { product_titles: productTitles, external_product_ids: productIds }
  )
  return response.data
}

export const apiGetProductSetsByOrg = async () => {
  const response = await apiClient.get<ProductSet[]>(
    `/ecommerce/product-tracking/product-set`
  )
  return response.data
}

export const apiGetProductSetById = async (productSetId: string) => {
  const response = await apiClient.get<ProductSetInResponse>(
    `/ecommerce/product-tracking/product-set/${productSetId}`
  )
  return response.data
}

export const apiCreateProductSet = async ({
  name,
  platform,
  uploadedFileId
}: {
  name: string
  platform: ECommercePlatforms
  uploadedFileId?: string
}) => {
  const response = await apiClient.post<ProductSet>(
    `/ecommerce/product-tracking/product-set`,
    {
      name,
      platform,
      uploaded_file_id: uploadedFileId
    }
  )
  return response.data
}

export const apiAddKeywordsToProductSet = async (
  productSetId: string,
  keywords: string[],
  platform: string
) => {
  const response = await apiClient.post<ProductTrackingKeyword[]>(
    `/ecommerce/product-tracking/product-set/${productSetId}/keywords`,
    {
      keywords,
      platform
    }
  )
  return response.data
}

export const apiUpdateProductSetById = async ({
  productSetId,
  selectedKeywordId,
  name,
  platform,
  uploadedFileId
}: {
  productSetId: string
  selectedKeywordId?: string
  name?: string
  platform?: string
  uploadedFileId?: string
}) => {
  const response = await apiClient.put<ProductSet>(
    `/ecommerce/product-tracking/product-set/${productSetId}`,
    {
      selected_keyword_id: selectedKeywordId,
      name: name,
      platform: platform,
      uploaded_file_id: uploadedFileId
    }
  )
  return response.data
}

export const apiAddProductsToProductSet = async (
  productSetId: string,
  productTitles: string[],
  externalProductIds: string[]
) => {
  const response = await apiClient.post<TrackedProduct>(
    `/ecommerce/product-tracking/product-set/${productSetId}/tracked-products`,
    { product_titles: productTitles, external_product_ids: externalProductIds }
  )
  return response.data
}

export const apiGetProductResearchResultByProductSetId = async (
  productSetId: string,
  keywordId: string,
  platform: string
) => {
  const response = await apiClient.post<ProductSetStatsInResponse>(
    `/ecommerce/product-tracking/product-set/${productSetId}/product-research-result`,
    { keyword_id: keywordId, platform }
  )
  return response.data
}

export const apiDeleteProductSet = async (productSetId: string) => {
  const response = await apiClient.delete(
    `/ecommerce/product-tracking/product-set/${productSetId}`
  )
  return response.data
}

export const apiGetDAMTable = async () => {
  const response = await apiClient.get<ProductTable[]>(
    `/ecommerce/digital-assets/product-tables`
  )
  return response.data
}

export const apiGetShopifyDAMTable = async () => {
  const response = await apiClient.get<ProductTable[]>(
    `/ecommerce/digital-assets/shopify-product-tables`
  )
  return response.data
}

export const apiGetDAMProducts = async (
  prodTableId: string,
  searchTerm?: string,
  filterFields?: Record<string, string | string[] | null>,
  sortField?: string,
  paginationParams?: { page: number; page_size: number }
) => {
  const sortFields = !sortField
    ? sortField
    : sortField?.startsWith('-')
    ? [sortField?.slice(1), 'desc']
    : [sortField, 'asc']
  const response = await apiClient.post<DAMProductSearchResult<DAMProduct>>(
    `/ecommerce/digital-assets/products/search`,
    {
      prod_table_id: prodTableId,
      search_filter_fields: {
        product_title: searchTerm
      },
      filter_fields: filterFields,
      sort_fields: sortFields,
      pagination_params: paginationParams
    }
  )
  return response.data
}

interface ShopifyDAMProductsParams {
  searchTerm?: string
  filterFields?: Record<string, string | string[] | null>
  sortField?: string
  paginationParams?: { page: number; page_size: number }
}

export const apiGetShopifyDAMProducts = async ({
  searchTerm,
  filterFields,
  sortField,
  paginationParams
}: ShopifyDAMProductsParams) => {
  const sortFields = !sortField
    ? sortField
    : sortField.startsWith('-')
    ? [sortField.slice(1), 'desc']
    : [sortField, 'asc']
  const response = await apiClient.post<
    DAMProductSearchResult<ShopifyDAMProductWithAllFields>
  >(`/ecommerce/digital-assets/products/shopify-search`, {
    search_filter_fields: {
      product_title: searchTerm
    },
    filter_fields: filterFields,
    sort_fields: sortFields,
    pagination_params: paginationParams
  })
  return response.data
}

export const apiGetAllShopifyProductIds = async () => {
  const response = await apiClient.post<string[]>(
    `/ecommerce/digital-assets/products/shopify-product-ids`
  )
  return response.data
}

export const apiCreateDAMProducts = async (
  prodTableId: string,
  items: DAMProductCreationItem[]
) => {
  const response = await apiClient.post<DAMProduct[]>(
    `/ecommerce/digital-assets/products`,
    {
      prod_table_id: prodTableId,
      items
    }
  )
  return response.data
}

export const apiDeleteProductTable = async (prodTableId: string) => {
  const response = await apiClient.delete(
    `/ecommerce/digital-assets/product-tables/${prodTableId}`
  )
  return response.data
}
