import React from 'react'

import '@fontsource/inter/variable.css'
import * as Sentry from '@sentry/react'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'
import 'semantic-ui-css/semantic.min.css'

import '@hypotenuse/common/src/index.css'

// import Analytics from './analytics/Analytics'
import {
  INTERCOM_APP_ID,
  IS_DEPLOYED,
  SENTRY_DSN,
  SENTRY_TRACE_SAMPLE_RATE,
  STAGE
} from './utils/Constants'

import AppContainer from './AppContainer'
import './index.css'
import * as serviceWorker from './serviceWorker'

// Sentry logging for error debugging
if (IS_DEPLOYED) {
  Sentry.init({
    dsn: SENTRY_DSN,
    autoSessionTracking: true,
    environment: STAGE,
    tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,
    integrations: [
      // Disabled because console capture seems to be causing a spike in Sentry errors
      // new CaptureConsole({
      //   levels: ['error']
      // }),
      new TracingIntegrations.BrowserTracing()
    ]
  })
}

// TODO: Enable automatic tracking of navigation
// const history = createBrowserHistory()
// history.listen((_location) => {
//   void Analytics.trackPageView(
//     window.location.pathname + window.location.search
//   )
// })
//

ReactDOM.render(
  // Don't add any context providers here - keep it as slim as possible
  <React.StrictMode>
    <BrowserRouter>
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <AppContainer />
      </IntercomProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
