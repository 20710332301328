import { handlePremiumCustomerEnterprisePlanContactUs } from '../api/Analytics'

import { ProductImportSource } from '../interfaces/Products'

import { CONTACT_US_LINK } from '../utils/Constants'

interface ContactUsProps {
  pathname: string
  elementId: string
  userId: string
  planListId: string
  source?: ProductImportSource
  contactUsLink?: string
  /**
   * The plan ID on which the card is based if the trigger is from a pricing plan card
   */
  planIdOnCard?: string
}

export const handleContactUs = ({
  pathname,
  elementId,
  userId,
  planListId,
  source,
  contactUsLink = CONTACT_US_LINK,
  planIdOnCard
}: ContactUsProps) => {
  handlePremiumCustomerEnterprisePlanContactUs(
    pathname,
    elementId,
    source,
    planIdOnCard
  )

  const parsedElementId = source ? `${elementId}-${source}` : elementId

  const link = `${contactUsLink}?planlistid=${encodeURIComponent(
    planListId
  )}&elementid=${encodeURIComponent(
    parsedElementId
  )}&pathname=${encodeURIComponent(pathname)}&userid=${encodeURIComponent(
    userId
  )}`
  window.open(link, '_blank')
}
