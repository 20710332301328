import React, { useCallback } from 'react'

import { X } from '@styled-icons/feather/X'
import { FileRejection } from 'react-dropzone'

import { Typography, palette } from '../../atoms'
import Stack from '../atoms/Stack'
import {
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  useTheme
} from '@material-ui/core'

import { apiUploadFile } from '../../api/Files'

import { UserUploadedFileSource } from '../../interfaces/Files'
import { ECommercePlatforms } from './interfaces'

import snackbar from '../../utils/Snackbar'

import { ReactComponent as DeleteIcon } from '../../assets/trash-03.svg'
import BaseModal, {
  ModalActions,
  ModalContent,
  ModalTitle,
  PrimaryButton
} from '../BaseModal'

import ImportDropZone from './ImportDropZone'
import { useProductSetModalStore } from './store'

interface Props {
  onDeleteProductSet: (productSetId: string) => void
  onAddProductSet: (
    name: string,
    platform: ECommercePlatforms,
    uploadedFileId?: string
  ) => void
  onEditProductSet: (
    name?: string,
    platform?: ECommercePlatforms,
    uploadedFileId?: string
  ) => void
}

const ProductSetAddEditModal: React.FC<Props> = React.memo((props) => {
  const { onDeleteProductSet, onAddProductSet, onEditProductSet } = props

  const theme = useTheme()

  const productSetId = useProductSetModalStore((store) => store.id)
  const productSetName = useProductSetModalStore((store) => store.name)
  const setProductSetName = useProductSetModalStore((store) => store.setName)
  const productSetPlatform = useProductSetModalStore((store) => store.platform)
  const setProductSetPlatform = useProductSetModalStore(
    (store) => store.setPlatform
  )
  const open = useProductSetModalStore((store) => store.open)
  const onClose = useProductSetModalStore((store) => store.close)
  const isEditMode = useProductSetModalStore((store) => store.editMode)
  const files = useProductSetModalStore((store) => store.files)
  const setFiles = useProductSetModalStore((store) => store.setFiles)
  const uploadedFileIds = useProductSetModalStore(
    (store) => store.uploadedFileIds
  )
  const setUploadedFileIds = useProductSetModalStore(
    (store) => store.setUploadedFileIds
  )

  const handleDropAccepted = useCallback(
    async (files: File[]) => {
      const _uploadedFileIds = []
      for (const f of files) {
        try {
          const uploaded_file = await apiUploadFile({
            file: f,
            source: UserUploadedFileSource.product_monitoring
          })
          _uploadedFileIds.push(uploaded_file.id)
        } catch (e) {
          snackbar.show(
            'Failed to upload file. Please try again or contact customer support.',
            { variant: 'error' }
          )
        }
      }
      setUploadedFileIds(_uploadedFileIds)
      setFiles(files)
    },
    [setFiles, setUploadedFileIds]
  )

  const handleDropRejected = useCallback((rejections: FileRejection[]) => {
    snackbar.show(rejections[0].errors[0].message, { variant: 'error' })
  }, [])

  const handleRemoveFile = useCallback(() => {
    setFiles([])
    setUploadedFileIds([])
  }, [setFiles, setUploadedFileIds])

  const onPrimaryButtonClick = useCallback(() => {
    if (!productSetName || !productSetPlatform) return
    if (isEditMode)
      onEditProductSet(
        productSetName,
        productSetPlatform,
        uploadedFileIds && uploadedFileIds.length > 0
          ? uploadedFileIds[0]
          : undefined
      )
    else
      onAddProductSet(
        productSetName,
        productSetPlatform,
        uploadedFileIds && uploadedFileIds.length > 0
          ? uploadedFileIds[0]
          : undefined
      )
    onClose()
  }, [
    isEditMode,
    onAddProductSet,
    onClose,
    onEditProductSet,
    productSetName,
    productSetPlatform,
    uploadedFileIds
  ])

  return (
    <BaseModal open={open} onClose={onClose}>
      <ModalTitle>
        <Stack justifyContent="space-between" flexDirection="row">
          <Typography variant="paragraph1Bold">
            {isEditMode ? 'Edit' : 'Add'} product set
          </Typography>
          <IconButton size="small" onClick={onClose}>
            <X size={20} strokeWidth={2} />
          </IconButton>
        </Stack>
      </ModalTitle>
      {/* TODO: divider */}
      <ModalContent>
        {/* TODO: use useForm instead (?) */}
        <Stack spacing={1}>
          <Typography variant="paragraph2Bold">Name of product set</Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Vacuum cleaner set"
            value={productSetName}
            onChange={(e) => {
              setProductSetName(e.target.value)
            }}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography variant="paragraph2Bold">Select retailer</Typography>
          <Select
            variant="outlined"
            autoWidth
            value={productSetPlatform}
            margin="dense"
            onChange={(e) => {
              setProductSetPlatform(e.target.value as ECommercePlatforms)
            }}
          >
            <MenuItem value={ECommercePlatforms.WALMART}>Walmart</MenuItem>
            <MenuItem value={ECommercePlatforms.AMAZON}>Amazon</MenuItem>
          </Select>
        </Stack>
        <Typography variant="paragraph2Bold">Upload product set</Typography>
        <ImportDropZone
          files={files}
          onDropAccepted={handleDropAccepted}
          onDropRejected={handleDropRejected}
          onRemoveFile={handleRemoveFile}
        />
        {isEditMode && (
          <Button
            variant="outlined"
            style={{
              width: 'fit-content'
            }}
            onClick={() => {
              productSetId && onDeleteProductSet(productSetId)
              onClose()
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <DeleteIcon
                style={{
                  stroke: palette.gray[700],
                  strokeWidth: '1.3px',
                  width: theme.spacing(2.5),
                  height: theme.spacing(2.5)
                }}
              />
              Delete product set
            </Stack>
          </Button>
        )}
      </ModalContent>
      <ModalActions>
        <PrimaryButton
          onClick={onPrimaryButtonClick}
          variant="contained"
          color="primary"
        >
          {isEditMode ? 'Update' : 'Create'}
        </PrimaryButton>
      </ModalActions>
    </BaseModal>
  )
})

export default ProductSetAddEditModal
